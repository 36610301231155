import { useMemo, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { Box, Typography } from '@mui/material';
import { GetItemKeywordReport } from 'store/thunk/userThunk';

const ItemKeywordReport = () => {
  const dispatch = useAppDispatch();
  const { commonAdvertiser, commonDateRange, ItemKeywordReport } = useAppSelector((state) => state?.userDetailsSlice);
  const previousAdvertiser = useRef(commonAdvertiser);
  const previousDateRange = useRef(commonDateRange);
  const isFirstRender = useRef(true);
  useMemo(() => {
    if (
      commonAdvertiser?.length > 0 &&
      commonDateRange?.startDate &&
      commonDateRange?.endDate &&
      (isFirstRender?.current ||
        JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) ||
        JSON.stringify(previousDateRange.current) !== JSON.stringify(commonDateRange))
    ) {
      const params = {
        client: commonAdvertiser,
        date_filter: `${commonDateRange?.startDate}~${commonDateRange?.endDate}`
      };
      dispatch(GetItemKeywordReport({ _request: params }));
      previousAdvertiser.current = commonAdvertiser;
      previousDateRange.current = commonDateRange;
      isFirstRender.current = false;
    }
  }, [commonAdvertiser, commonDateRange]);

  return (
    <Box className="BI-dashboard-card">
      <Typography variant="h2" className="Overview-title">
        Item-Keyword Report
      </Typography>
      {commonAdvertiser?.length <= 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15%' }}>
          <Typography className="no-advertiser">Please select atleast one advertiser.</Typography>
        </Box>
      ) : (
        ItemKeywordReport && (
          <iframe
            title="Item-Keyword Report"
            src={ItemKeywordReport}
            style={{ border: 'none', height: 'calc(100vh - 200px)', width: '100%' }}
          ></iframe>
        )
      )}
    </Box>
  );
};

export default ItemKeywordReport;
