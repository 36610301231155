import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  FormHelperText,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Autocomplete,
  Tooltip,
  Checkbox,
  Menu,
  MenuItem
} from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import CheckIcon from '@mui/icons-material/Check';
import SetBid from 'ui-component/bids/modal/setBid';
import { Box } from '@mui/system';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
import SearchTable from './searchTable';
import SelectFromTable from './selectFromTable';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EnterKeywordTable from './enterItemTable';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import {
  getAdvertiserById,
  // getAlldvertiserList,
  getSelectFromItemsFromId,
  postCreateAdgroup,
  postCreateAditems,
  updateAdgroup
} from 'store/thunk/userThunk';
import AdgroupContext from 'context/adgroup';
import { debounce } from 'lodash';
import { CAMPAIGN } from 'constant/campaignConstant';
import { PageValue } from 'store/constant';
import { MIN_ITEM_BID_LIMIT, MAX_ITEM_BID_LIMIT } from 'store/constant';
import { useRef } from 'react';

const ProductAdGroupSetting = () => {
  const {
    campaignData,
    setCampaignData,
    searchableData,
    selectedData,
    setSelectedData,
    handleForwordButtonClick,
    setStepArray,
    setCampaign,
    campaign
  } = useContext(AdgroupContext);
  const navigate = useNavigate();

  const { selectdItemData, existingAdgroupId, allUserDetails, campaignByIdData } = useAppSelector(
    (state) => state?.userDetailsSlice
  );
  const [tabValue, setTabValue] = useState('2');
  const [error, setError] = useState(false);
  const [keyword, setkeyword] = useState('');
  const [list, setList] = useState({});
  const [serchValue, setSearchValue] = useState('');
  const [campaignValue, setCampaignValue] = useState(null);

  const location = useLocation();
  const [loading, setLoading] = useState({ adgroup: '', item: '' });

  const [dialogOpen, setDialogOpen] = useState(false);

  const [isModalSetBid, setIsModalSetBid] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectMode, setSelectMode] = useState('all');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isFirstRender = useRef(true);

  const dispatch = useAppDispatch();

  //When selecting Select All, Select Current mode
  useEffect(() => {
    // Skip first render because to prevent automatically select all selected option at first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleSelectAll('switchMode'); //For perform checkbox selection
  }, [selectMode]);

  //When selecting single Checkbox
  const handleRowSelection = (index) => {
    if (selectedArray.includes(index)) {
      setSelectedArray(selectedArray.filter((i) => i !== index));
    } else {
      setSelectedArray([...selectedArray, index]);
    }
  };

  //When click on Select All, Select Current or Select All checkbox
  const handleSelectAll = (mode = '') => {
    if (selectMode === 'all') {
      if (isAllSelected() && mode === 'globalCheck') {
        setSelectedArray([]); // Unselect all
      } else {
        setSelectedArray(selectedData.map((_, index) => index)); // Select all rows
      }
    } else {
      if (isAllSelected() && mode === '') {
        setSelectedArray([]); // Unselect all
      } else {
        const startIndex = currentPage * rowsPerPage;
        // setSelectedArray([...Array(rowsPerPage).keys()].map((i) => i + startIndex)); // Select only current page rows

        // Ensure selected indexes do not exceed selectedData.length
        const endIndex = Math.min(startIndex + rowsPerPage, selectedData.length);
        setSelectedArray([...Array(endIndex - startIndex).keys()].map((i) => i + startIndex));
      }
    }
  };

  // Function to check if all rows (or current page rows) are selected
  const isAllSelected = () => {
    if (selectMode === 'all') {
      return selectedArray.length === selectedData.length;
    } else {
      const startIndex = currentPage * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, selectedData.length);
      // Restrict indexes to prevent out-of-bounds selection
      const currentPageIndexes = [...Array(endIndex - startIndex).keys()]?.map((i) => i + startIndex);
      return currentPageIndexes?.every((index) => selectedArray?.includes(index));
    }
  };

  //On click of Arrow Click
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //To close open Menu Items
  const handleClose = () => {
    setAnchorEl(null);
  };

  //While selecting any menu items options
  const handleSelect = (mode) => {
    setSelectMode(mode);
    handleClose(); // Close the menu after selection
  };

  //Toggle Set Bid Modal
  const handleToggleSetBid = () => {
    setIsModalSetBid(!isModalSetBid);
  };

  //Apply Bid
  const handleApplyBid = (bidValue) => {
    const updatedKeywordData = selectedData.map((item, index) =>
      selectedArray.includes(index) ? { ...item, bid_amount: bidValue } : item
    );
    setSelectedData(updatedKeywordData);
    setSelectedArray([]);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const selectedProfiles = localStorage.getItem('selectedProfiles');

  //While creating adgroup to selected campaign, it auto selected campaign
  useEffect(() => {
    if (location?.state?.id && campaignByIdData?.length > 0) {
      const find = campaignByIdData?.find((item) => item.id == location.state.id);
      setValue('campaign', find);
      setCampaignValue(find);
    }
  }, [location, campaignByIdData]);

  //Auto select First Profile while page load and we have allUserDetails
  useEffect(() => {
    if (allUserDetails?.length && !location?.state) {
      if (selectedProfiles) {
        const selectedProfileFirstId = selectedProfiles?.split(',')?.pop();
        setValue('profile', selectedProfileFirstId, { shouldValidate: true });
      } else {
        setValue('profile', null);
      }
    }
  }, [allUserDetails, selectedProfiles]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // const data = null;
    // dispatch(getAlldvertiserList({ data }));
  }, []);

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const formSchema = Yup.object().shape({
    adgroup_name: Yup.string().required('Adgroup name is required.'),
    profile: Yup.string().required('Profile selection is required.'),
    campaign: Yup.object().required('Campaign selection is required.').nullable()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
    defaultValues: {
      adgroup_name: '',
      profile: location?.state?.advertise ? location?.state?.advertise : '' //it select Profile when adding adgroup for a campaign
      // campaign: null
    }
  });

  const profileName = watch('profile');
  const selectedCampaign = watch('campaign');
  const onChange = (search) => {
    setSearchValue(search);
    const id = profileName;
    const campaign_name = search ? search : '';
    dispatch(getAdvertiserById({ id, campaign_name }));
  };

  const changeCampaignName = debounce(onChange, 1000);

  useEffect(() => {
    if (profileName) {
      const id = profileName;
      const advertiserId = profileName;
      const campaign_name = '';
      dispatch(getAdvertiserById({ id, campaign_name }));
      dispatch(getSelectFromItemsFromId({ advertiserId }));
      setCampaignData({ ...campaignData, campaign: { advertiser: advertiserId } });
    }
  }, [profileName]);

  useEffect(() => {
    if (selectedCampaign) {
      setCampaign({ ...campaign, targeting_type: selectedCampaign?.targeting_type });
      if (selectedCampaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL) {
        setStepArray(['Adgroup Setting', 'Target Setting', 'Complete']);
      } else {
        setStepArray(['Adgroup Setting', 'Complete']);
      }
    }
  }, [selectedCampaign]);

  const onButtonClick = (event) => {
    setList({});
    if (selectedData?.length > 0) {
      setError(false);
      const temp = selectedData?.map((item) => {
        return { item_id: item?.item_id, bid_amount: item.bid_amount ? item.bid_amount : MIN_ITEM_BID_LIMIT };
      });

      setCampaignData({ ...campaignData, adgroup: { name: event?.adgroup_name } });

      setDialogOpen(true);
      setLoading({ ...loading, adgroup: 'process' });
      const _adgroupRequest = {
        name: event?.adgroup_name,
        status: 'enabled',
        campaign: event?.campaign?.id
      };
      dispatch(
        existingAdgroupId ? updateAdgroup({ _adgroupRequest, existingAdgroupId }) : postCreateAdgroup({ _adgroupRequest })
      ).then((res) => {
        if (res?.payload?.status == 201 || res?.payload?.status == 200) {
          setLoading({ ...loading, adgroup: 'done', item: 'process' });
          const _aditemRequest = {
            campaign: res?.payload?.data?.data?.campaign,
            adgroup: res?.payload?.data?.data?.id,
            add_items: temp
          };
          dispatch(postCreateAditems({ _aditemRequest })).then((res) => {
            if (res?.payload?.status == 201) {
              setList(res?.payload?.data);
              setLoading({ ...loading, adgroup: 'done', item: 'done' });
            } else {
              setLoading({ ...loading, adgroup: 'done', item: 'error' });
            }
          });
        } else {
          setLoading({ ...loading, adgroup: 'error' });
        }
      });
    } else {
      setError(true);
    }
  };

  const onAddAllClick = () => {
    var tempArray = [...selectedData];
    if (searchableData?.length >= 1) {
      searchableData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: MIN_ITEM_BID_LIMIT
          });
        }
      });
    } else {
      selectdItemData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: MIN_ITEM_BID_LIMIT
          });
        }
      });
    }
    setSelectedData(tempArray);
  };

  const selectedColumns = [
    ...(campaignData?.campaign?.targeting_type !== CAMPAIGN.TARGETING_TYPE.MANUAL
      ? [
          {
            name: 'select',
            label: (
              <Checkbox
                checked={isAllSelected()} // Fix: Properly checks/unchecks
                onChange={handleSelectAll}
                indeterminate={selectedArray.length > 0 && !isAllSelected()} // Shows partial selection state
              />
            ),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta) => {
                const rowIndex = tableMeta.rowIndex;
                return <Checkbox checked={selectedArray.includes(rowIndex)} onChange={() => handleRowSelection(rowIndex)} />;
              },
              customHeadRender: () => (
                <th style={{ borderBottom: '1px solid #e0e0e0', borderRight: '1px solid #E8E8E8' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', ml: '17px' }}>
                    <Checkbox
                      checked={selectedArray.length > 0}
                      // onChange={handleSelectAll}
                      onChange={() => handleSelectAll(selectMode === 'current' ? '' : 'globalCheck')}
                    />
                    <Tooltip title="Select Mode">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        // sx={{ ml: 2 }}
                        aria-controls={open ? 'select-mode-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Tooltip>

                    <Menu
                      anchorEl={anchorEl}
                      id="select-mode-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem onClick={() => handleSelect('all')}>
                        {selectMode === 'all' && <CheckIcon fontSize="small" sx={{ mr: 1 }} />}
                        <Typography>Select All</Typography>
                      </MenuItem>
                      <MenuItem onClick={() => handleSelect('current')}>
                        {selectMode === 'current' && <CheckIcon fontSize="small" sx={{ mr: 1 }} />}
                        <Typography>Select Current</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </th>
              )
            }
          }
        ]
      : []),
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedData[tableMeta?.rowIndex];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {data?.images ? (
                <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
              ) : (
                <InsertPhotoIcon fontSize="large" sx={{ margin: '4px', color: colors.grey500 }} />
              )}
              <Box>
                <Typography>{data?.product_name ? data?.product_name : '------'}</Typography>
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                >
                  <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                </Link>
              </Box>
            </Box>
          );
        }
      }
    },
    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bid_amount',
      label: 'bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let data = selectedData[tableMeta?.rowIndex];
          return (
            <TextField
              size="small"
              value={data?.bid_amount}
              type="number"
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent form submission
                }
              }}
              onBlur={(e) => {
                const value = Number(e.target.value);
                const newPrice =
                  value < Number(MIN_ITEM_BID_LIMIT)
                    ? MIN_ITEM_BID_LIMIT
                    : value > Number(MAX_ITEM_BID_LIMIT)
                    ? MAX_ITEM_BID_LIMIT
                    : value;

                if (newPrice !== value) {
                  const updatedData = [...selectedData];
                  updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                  setSelectedData(updatedData);
                }
              }}
              onChange={(e) => {
                const newPrice = Number(e.target.value == 0) ? null : Number(e.target.value);
                const updatedData = [...selectedData];
                updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                setSelectedData(updatedData);
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              inputProps={{
                step: '0.01'
              }}
            />
          );
        }
      }
    },
    {
      name: 'item_id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <IconButton
              onClick={() => {
                var tempData = [...selectedData];
                tempData = tempData?.filter((item) => item?.item_id != value);
                setSelectedData(tempData);
                //Adjust selected array index
                const removedIndex = tableMeta?.rowIndex;
                if (selectedArray.includes(removedIndex)) {
                  const updatedArray = selectedArray
                    .filter((item) => item !== removedIndex)
                    .map((item) => (item > removedIndex ? item - 1 : item));
                  setSelectedArray(updatedArray);
                } else {
                  const updatedArray = selectedArray.map((item) => (item > removedIndex ? item - 1 : item));
                  setSelectedArray(updatedArray);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={!selectedData?.length}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                setSelectedData([]);
                setSelectedArray([]);
              }}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    viewColumns: false,
    serverSide: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    toolbar: false,
    search: false,
    onChangePage: (newPage) => {
      setCurrentPage(newPage);
    },
    onChangeRowsPerPage: (row) => {
      setRowsPerPage(row);
      setSelectedArray([]); // Unselect all
    }
  };

  const handleOkButtonClick = () => {
    if (loading.adgroup == 'done' && loading.item == 'done' && !list?.errorMessages?.length) {
      handleForwordButtonClick();
    } else {
      const array = selectedData.filter((item) => !list?.successlist?.find((list) => list?.item_id == item?.item_id));
      setSelectedData(array);
    }
    setDialogOpen(false);
    setLoading({ campaign: '', adgroup: '', item: '' });
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onButtonClick)}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item sm={6}>
              {/* {renderLabel('Profile', true)} */}
              <Controller
                name={'profile'}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...register('profile')}
                    {...field}
                    value={allUserDetails.find((obj) => obj.id == field.value) || null}
                    onChange={(e, value) => (value ? field.onChange(value?.id) : field.onChange(''))}
                    options={allUserDetails || []}
                    getOptionLabel={(option) => {
                      return option?.name || '';
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Profile"
                        error={Boolean(errors.profile)}
                        helperText={errors.profile?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              {/* {renderLabel('Campaign', true)} */}
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                onChange={(e, value) => {
                  setValue('campaign', value);
                  setCampaignValue(value);
                }}
                getOptionLabel={(option) => option.name}
                value={campaignValue}
                options={campaignByIdData?.length > 0 ? campaignByIdData : []}
                clearOnBlur={false}
                // {...register("campaign")}
                // {...field}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    value={serchValue}
                    error={errors.campaign}
                    fullWidth
                    label="Campaign"
                    {...params}
                    onChange={(e) => changeCampaignName(e.target.value)}
                  />
                )}
              />
              {errors.campaign && (
                <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                  {errors?.campaign?.message}
                </FormHelperText>
              )}
              {/* </>
                                )}
                            />  */}
            </Grid>
            <Grid item sm={12}>
              {/* {renderLabel('Adgroup Name', true)} */}
              <Controller
                name={'adgroup_name'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...register('adgroup_name')}
                    {...field}
                    name={'adgroup_name'}
                    fullWidth
                    type="text"
                    label="Adgroup Name"
                    variant="outlined"
                    error={errors?.adgroup_name}
                    helperText={errors?.adgroup_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Item', true)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValue}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Search" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Select from" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="3" />
                        <Tab label="Enter Items" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SearchTable />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <SelectFromTable onAddAllClick={onAddAllClick} />
                    </TabPanel>
                    <TabPanel value="3"></TabPanel>
                    <TabPanel value="4" sx={{ padding: 0 }}>
                      <EnterKeywordTable keyword={keyword} setkeyword={setkeyword} advertiserId={profileName} />
                    </TabPanel>
                  </Box>
                </TabContext>

                {/* {error && (<FormHelperText error sx={{ margin: "3px 14px 0px 14px" }}>{"Please select atlease one item."}</FormHelperText>)} */}
                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      padding: '20px 30px 20px 30px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box display={'flex'} alignItems="center" gap={3}>
                      {campaignValue?.targeting_type !== CAMPAIGN.TARGETING_TYPE.MANUAL && (
                        <Button
                          variant="outlined"
                          disabled={selectedArray?.length > 0 ? false : true}
                          onClick={handleToggleSetBid}
                        >
                          Set Bid
                        </Button>
                      )}

                      <Typography variant="h5">{selectedData?.length} Item(s)Added</Typography>
                      {selectedArray?.length > 0 && (
                        <Typography variant="h5">{selectedArray?.length} Item(s) Selected</Typography>
                      )}
                    </Box>
                    <Typography variant="h6" sx={{ color: colors.grey500 }}>
                      Item bid is only valid for Auto Campaigns
                    </Typography>
                  </Box>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      sx={{ padding: 0 }}
                      data={selectedData}
                      columns={
                        campaignValue?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL
                          ? selectedColumns?.filter(
                              (item) => item?.name != 'bid_amount' && item?.name != 'suggested_bid' && item?.name != 'select'
                            )
                          : selectedColumns
                      }
                      options={selectedOptions}
                      className="pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>
              {error && (
                <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                  {'Please select atlease one keyword.'}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={() => navigate(-1)}>
                CANCEL
              </Button>
              {/* <Button variant="outlined" color="secondary" sx={{ ml: 1 }} onClick={handlePreviousButton} >PREVIOUS</Button> */}
              <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit">
                {/* {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />} */}
                NEXT
              </Button>
            </>
          </Box>
        </Box>
      </form>
      {isModalSetBid && <SetBid isOpen={isModalSetBid} onClose={handleToggleSetBid} onSave={handleApplyBid} bidType={'ITEM'} />}
      <Dialog open={dialogOpen} aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant="div"> Message</Typography>{' '}
        </DialogTitle>
        <DialogContent dividers sx={{ minWidth: '600px', minHeight: '200px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.adgroup == 'process' && <CircularProgress size={20} />}
              {loading.adgroup == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.adgroup == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`Adgroup (${campaignData?.adgroup?.name}) ${existingAdgroupId ? 'updated' : 'created'} successfully`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.item == 'process' && <CircularProgress size={20} />}
              {loading.item == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.item == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`${list?.data?.successcount ? list?.data?.successcount : 0} items added successfully`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              {list?.errorMessages?.map((item, index) => (
                <Box key={index} sx={{ display: 'flex' }}>
                  {' '}
                  <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                    {list?.errorMessages?.length && <HighlightOffIcon color="error" />}
                  </Box>
                  <Typography sx={{ padding: 1.5 }}>{item}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ margin: 1 }} onClick={handleOkButtonClick} color="secondary" variant="contained" autoFocus size="small">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductAdGroupSetting;
