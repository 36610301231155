import { useMemo, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { Box, Typography } from '@mui/material';
import { GetShareofVoiceDashboard } from 'store/thunk/userThunk';

const ShareofVoice = () => {
  const dispatch = useAppDispatch();
  const prevSelectedDates = useRef();
  const { commonDateRange, ShareofVoiceDashboard } = useAppSelector((state) => state?.userDetailsSlice);

  useMemo(() => {
    if (
      JSON.stringify(prevSelectedDates.current) !== JSON.stringify(commonDateRange) &&
      commonDateRange?.startDate &&
      commonDateRange?.endDate
    ) {
      const params = {
        time_frame: `${commonDateRange?.startDate}~${commonDateRange?.endDate}`
      };
      dispatch(GetShareofVoiceDashboard({ _request: params }));
      // Update the previous value
      prevSelectedDates.current = commonDateRange;
    }
  }, [commonDateRange]);

  return (
    <Box className="BI-dashboard-card">
      <Typography variant="h2" className="Overview-title">
        Share of Voice Dashboard
      </Typography>
      {ShareofVoiceDashboard && (
        <iframe
          title="Share of Voice"
          src={ShareofVoiceDashboard}
          style={{ border: 'none', height: 'calc(100vh - 200px)', width: '100%' }}
        ></iframe>
      )}
    </Box>
  );
};

export default ShareofVoice;
