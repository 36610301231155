import React from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';

import { useGridApiContext } from '@mui/x-data-grid';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';

const BidAmountEditInputCell = (props) => {
  const { id, value, field, hasFocus, restrictAmt, restrictMaxAmt = '' } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  const handleChange = (event) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  const handleOnBlur = (event) => {
    if (Number(event.target.value) < Number(restrictAmt)) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: Number(restrictAmt)
      });
    }
    if (Number(event.target.value) > Number(restrictMaxAmt)) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: Number(restrictMaxAmt)
      });
    }
  };

  useEnhancedEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector(`input[value="${value}"]`);
      input?.focus();
    }
  }, [hasFocus, value]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
      <TextField
        id="outlined-basic"
        ref={ref}
        label=""
        variant="outlined"
        value={value}
        onChange={handleChange}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
        type="number"
        onBlur={handleOnBlur}
        inputProps={{
          step: '0.01'
        }}
      />
    </Box>
  );
};

export default BidAmountEditInputCell;
