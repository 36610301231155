import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, IconButton, DialogActions, Button, FormHelperText } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useState } from 'react';
import {
  MIN_ITEM_BID_LIMIT,
  MAX_ITEM_BID_LIMIT,
  MIN_KEYWORD_BID_LIMIT_CAMP_SP,
  MAX_KEYWORD_BID_LIMIT_CAMP_SP,
  MIN_KEYWORD_BID_LIMIT_CAMP_SB,
  MAX_KEYWORD_BID_LIMIT_CAMP_SB,
  MIN_KEYWORD_BID_LIMIT_CAMP_SV,
  MAX_KEYWORD_BID_LIMIT_CAMP_SV
} from 'store/constant';

const SetBid = (props) => {
  const { isOpen, onClose, onSave, bidType = 'ITEM', campType = '' } = props;
  const [amount, setAmount] = useState('');
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  // Input Validation Regex
  const isValidAmount = (value) => /^\d*\.?\d*$/.test(value) && value.trim() !== '';

  //While input change
  const handleChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      setAmount(value);
      setError(false);
    }
  };

  //Validate Error
  const validateError = () => {
    if (!isValidAmount(amount) || isNaN(amount)) {
      setError(true);
      setErrorMsg('Please enter a valid amount');
      return true;
    }
    return false;
  };

  const validateDefaultValue = () => {
    if (bidType === 'ITEM') {
      if (Number(amount) < Number(MIN_ITEM_BID_LIMIT)) {
        setError(true);
        setErrorMsg(`Minimum item bid limit is ${MIN_ITEM_BID_LIMIT}`);
        return true;
      } else if (Number(amount) > Number(MAX_ITEM_BID_LIMIT)) {
        setError(true);
        setErrorMsg(`Maximum item bid limit is ${MAX_ITEM_BID_LIMIT}`);
        return true;
      }
    } else if (bidType === 'KEYWORD' && campType === 'sponsoredProducts') {
      if (Number(amount) < Number(MIN_KEYWORD_BID_LIMIT_CAMP_SP)) {
        setError(true);
        setErrorMsg(`Minimum keyword bid limit is ${MIN_KEYWORD_BID_LIMIT_CAMP_SP}`);
        return true;
      } else if (Number(amount) > Number(MAX_KEYWORD_BID_LIMIT_CAMP_SP)) {
        setError(true);
        setErrorMsg(`Maximum keyword bid limit is ${MAX_KEYWORD_BID_LIMIT_CAMP_SP}`);
        return true;
      }
    } else if (bidType === 'KEYWORD' && campType === 'sba') {
      if (Number(amount) < Number(MIN_KEYWORD_BID_LIMIT_CAMP_SB)) {
        setError(true);
        setErrorMsg(`Minimum keyword bid limit is ${MIN_KEYWORD_BID_LIMIT_CAMP_SB}`);
        return true;
      } else if (Number(amount) > Number(MAX_KEYWORD_BID_LIMIT_CAMP_SB)) {
        setError(true);
        setErrorMsg(`Maximum keyword bid limit is ${MAX_KEYWORD_BID_LIMIT_CAMP_SB}`);
        return true;
      }
    } else if (bidType === 'KEYWORD' && campType === 'video') {
      if (Number(amount) < Number(MIN_KEYWORD_BID_LIMIT_CAMP_SV)) {
        setError(true);
        setErrorMsg(`Minimum keyword bid limit is ${MIN_KEYWORD_BID_LIMIT_CAMP_SV}`);
        return true;
      } else if (Number(amount) > Number(MAX_KEYWORD_BID_LIMIT_CAMP_SV)) {
        setError(true);
        setErrorMsg(`Maximum keyword bid limit is ${MAX_KEYWORD_BID_LIMIT_CAMP_SV}`);
        return true;
      }
    }
    return false;
  };

  //When clicking on Apply button
  const handleApply = () => {
    if (validateError() || validateDefaultValue()) return;

    onSave(amount);
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="div"> Set Bid </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl fullWidth sx={{ m: 1 }} error={error}>
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={amount}
              onChange={handleChange}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Amount"
            />
            {error && <FormHelperText>{errorMsg}</FormHelperText>}
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleApply}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetBid;
