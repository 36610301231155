import { forwardRef } from 'react';

import { Select, Box, FormControlLabel, Grid, Typography, MenuItem } from '@mui/material';
import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import { isAfter, endOfToday } from 'date-fns';
import { CustomSwitch } from 'ui-component/customSwitch';

const CalendarIcon = forwardRef((props, ref) => {
  return <img src="/calendar-icon.svg" alt="" {...props} ref={ref} />;
});

const DateCompareOptions = ({
  dates,
  comparDate,
  setDates,
  setComparDate,
  setMainDates,
  isCompare,
  setIsCompare,
  rangetype,
  setRangeType
}) => {
  const handlChange = async (selected, type, dateType) => {
    setRangeType('custom_range');

    if (type === 'selection') {
      setComparDate(comparDate);
      setDates({
        compare: comparDate,
        selection:
          dateType === 1
            ? { key: 'selection', startDate: selected, endDate: dates.endDate }
            : { key: 'selection', startDate: dates.startDate, endDate: selected }
      });
      setMainDates({
        selection:
          dateType === 1
            ? { key: 'selection', startDate: selected, endDate: dates.endDate }
            : { key: 'selection', startDate: dates.startDate, endDate: selected }
      });
    } else {
      await setMainDates({
        selection: dates
      });
      await setDates({
        selection: dates,
        compare:
          dateType === 1
            ? { key: 'compare', startDate: selected, endDate: comparDate.endDate }
            : { key: 'compare', startDate: comparDate.startDate, endDate: selected }
      });
      await setComparDate(
        dateType === 1
          ? { key: 'compare', startDate: selected, endDate: comparDate.endDate }
          : { key: 'compare', startDate: comparDate.startDate, endDate: selected }
      );
    }
  };

  const customPicker = (label, value, type, dateType) => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        className="custom-daterange-picker figma-grey-fonts"
      >
        {value && <Typography className="date-range-picker-label compare-dates-label">{label}</Typography>}
        <DatePicker
          format="MM/dd/yyyy"
          cleanable={false}
          value={value}
          onChange={(value) => handlChange(value, type, dateType)}
          appearance="default"
          placeholder={label}
          style={{ width: 160 }}
          caretAs={CalendarIcon}
          shouldDisableDate={(date) => isAfter(date, endOfToday())}
        />
      </Box>
    );
  };
  return (
    <Box>
      <Grid container spacing={1} className="current-period-dates">
        <Grid item sm={3} display="flex" alignItems="center">
          <img src="/calendar-icon.svg" alt="" />
          <Typography ml={1} className="font-weight-700 figma-grey-fonts" variant="h5">
            Current Period:
          </Typography>
        </Grid>
        <Grid item sm={3}>
          {customPicker('From', dates?.startDate, 'selection', 1)}
        </Grid>

        <Grid item sm={3}>
          {customPicker('To', dates?.endDate, 'selection', 2)}
        </Grid>
        <Grid item sm={3} display="flex" alignItems="center" justifyContent="right">
          <FormControlLabel
            label="Compare"
            name="is_item_buybox"
            className="compare-switch"
            checked={isCompare}
            onChange={(e) => setIsCompare(e.target.checked)}
            labelPlacement="start"
            control={<CustomSwitch label="Compare" />}
          />
        </Grid>
      </Grid>

      {isCompare && (
        <Grid container spacing={1} className="compare-period-dates">
          <Grid item sm={3} display="flex" alignItems="center">
            <img src="/compare.svg" alt="" />
            <Typography ml={1} variant="h5" className="font-weight-700 figma-grey-fonts">
              Comparison Period:
            </Typography>
          </Grid>
          <Grid item sm={3}>
            {customPicker('From', comparDate?.startDate, 'compare', 1)}
          </Grid>
          <Grid item sm={3}>
            {customPicker('To', comparDate?.endDate, 'compare', 2)}
          </Grid>
          <Grid item sm={3} display="flex" alignItems="center" justifyContent="right">
            <Select
              value={rangetype || 'previous_period'}
              onChange={(e) => setRangeType(e.target.value)}
              size="small"
              color="primary"
              className="date-range-selector-dropdown"
            >
              <MenuItem key="previous_period" value="previous_period">
                Previous Period
              </MenuItem>
              <MenuItem key="last_year" value="last_year">
                Last Year
              </MenuItem>
              <MenuItem key="custom_range" value="custom_range">
                Custom Range
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DateCompareOptions;
