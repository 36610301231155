export const CAMPAIGN = {
  TARGETING_TYPE: {
    MANUAL: 'manual',
    AUTO: 'auto',
    BOTH: 'both'
  },
  CAMPAIGN_TYPE: {
    SB: 'SB',
    // SP_AUTO: "SP-Auto",
    // SP_MANUAL: "SP-Manual",
    SP_AUTO: 'SP-A',
    SP_MANUAL: 'SP-M',
    SV: 'SV',
    SP_BOTH: 'Both'
  },
  NAVIGATION_TYPE: {
    CAMPAIGN: 'Campaign',
    ADGROUP: 'Adgroup'
  },
  BIDDING_STRATEGY: {
    FIXED: 'FIXED',
    DYNAMIC: 'DYNAMIC'
  }
};
