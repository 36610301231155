import { useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  DialogActions,
  Tooltip
  // TableCell
} from '@mui/material';
import { Box } from '@mui/system';
import { GridCloseIcon } from '@mui/x-data-grid';
import CustomDatePicker from 'layout/MainLayout/DateRangePicker';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import { useState } from 'react';
import DownloadIcon from 'assets/images/dashna/Arrow.svg';
import FileDownloadOffOutlinedIcon from '@mui/icons-material/FileDownloadOffOutlined';
import MainCard from 'ui-component/cards/MainCard';
import { OnDemandStatusList, PageValue, ReportTypeOnDemand } from 'store/constant';
import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';
import { useAppDispatch } from 'store';
import { CreateOnDemandData, GetOnDemandData } from 'store/thunk/userThunk';
import { useAppSelector } from 'store';
import { dateDifference, extractDate, formateValue, getValueByName } from 'helper/commonHelper';
// import { Download } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { setCommonLoader } from 'store/slices/userDetailsSlice';
import CustomTableHead from 'ui-component/customTableHead';
// import __ from 'underscore';
// import upIcon from 'assets/images/dashna/up.svg';
// import downIcon from 'assets/images/dashna/down.svg';

export default function MainDashboard() {
  const [reportType, setReportType] = useState('');
  const [attributionWindow, setAttributionWindow] = useState('');
  const [attributionOption, setAttributionOption] = useState('');
  // const defaultStartDate = moment().subtract(30, 'days').startOf('day').toDate();
  const defaultStartDate = moment().subtract(1, 'month').startOf('month').toDate();
  const defaultEndDate = moment().subtract(1, 'days').endOf('day').toDate();
  const dispatch = useAppDispatch();
  const [newData, setNewData] = useState();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [filterValue, setFilterValue] = useState({ status: [], report_type: [] });
  const [tableFilter, setTableFilter] = useState({ status: '', report_type: '' });
  const [filterForSearch, setFilterForSearch] = useState({});
  //For Select All Checkbox
  const [isSelectAll, setIsSelectAll] = useState({
    status: [],
    report_type: []
  });
  const [searchText, setSearchText] = useState({ status: null, report_type: null });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenReport, setIsDropdownOpenReport] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [selectedDates, setSelectedDates] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [Modal, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [finalDataForCreate, setFinalData] = useState({});
  // const [selectedColumns, setSelectedColumns] = useState([]);
  const { commonAdvertiser, onDemandData, allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const { userData } = useAppSelector((state) => state.authorization);

  //Prevent Un-necessary loading when no profile changed
  const previousAdvertiser = useRef(commonAdvertiser);
  const isFirstRender = useRef(true);

  const selectedProfiles = localStorage.getItem('selectedProfiles');

  const user = userData?.permission?.on_demand_report || {};
  const download_on_demand = user?.download;
  const request_report = user?.request_report;
  const handleReportTypeChange = (event) => {
    setReportType(event.target.value);
  };

  const handleAttributionWindowChange = (event) => {
    setAttributionWindow(event.target.value);
  };

  const handleAttributionOptionChange = (event) => {
    setAttributionOption(event.target.value);
  };
  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value);
    });

    return formattedData;
  };

  /* ================================ Autocomplete Filters Start ================================*/

  //Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'status' || filterKey === 'report_type') {
      paramKey = 'value';
    }

    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'status') {
      baseArray = [...OnDemandStatusList];
      tableStateFilterKey = 'status';
    } else if (filterKey === 'report_type') {
      baseArray = [...ReportTypeOnDemand];
      tableStateFilterKey = 'report_type';
    }

    let targetString = '';
    if (filterKey === 'campaign_type') {
      const targetArray = newValue?.map((item) => item?.type);
      targetString = targetArray.filter((item) => item !== undefined).join(',');
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: '' }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns
      }));
    } else {
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: targetString }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue
      }));
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'status') {
      baseArray = [...OnDemandStatusList];
      tableStateFilterKey = 'status';
    } else if (filterKey === 'report_type') {
      baseArray = [...ReportTypeOnDemand];
      tableStateFilterKey = 'report_type';
    }

    const status =
      filterValue?.[tableStateFilterKey]?.length && isSelectAll?.[filterKey]?.length
        ? !!(filterValue?.[tableStateFilterKey]?.length == baseArray?.length) ||
          !!(filterValue?.[tableStateFilterKey]?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  /* ================================ Autocomplete Filters End ================================*/

  //Auto select First Profile while page load and we have allUserDetails
  useEffect(() => {
    if (allUserDetails?.length) {
      if (selectedProfiles) {
        const selectedProfileFirstId = selectedProfiles?.split(',')?.pop();
        setSelectedUser(Number(selectedProfileFirstId));
      } else {
        setSelectedUser('');
      }
    }
  }, [allUserDetails, selectedProfiles]);

  useEffect(() => {
    const finalData = onDemandData?.results?.map((item) => {
      return formateData(Object.entries(item));
    });
    setNewData(finalData);
  }, [onDemandData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here

    dispatch(setCommonLoader(true));
    dispatch(CreateOnDemandData(finalDataForCreate)).then((res) => {
      dispatch(setCommonLoader(false));
      if (res.payload.status == 201) {
        setModal(false);
        setReportType('');
        setAttributionWindow('');
        setAttributionOption('');
        setSelectedUser('');
        const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
        getOndemandListWithPagination(0, 25, '', '', filterForSearch, advertiserFilter);
      }
    });
  };

  useEffect(() => {
    const payload = {
      advertiser: selectedUser,
      report_type: reportType,
      start_date: selectedDates?.startDate,
      end_date: selectedDates?.endDate,
      attribution_window: attributionWindow,
      group_by: attributionOption
    };
    setFinalData(payload);
  }, [selectedUser, reportType, selectedDates, attributionWindow, attributionOption]);

  const options = {
    filter: false,
    print: false,
    search: false,
    page: page,
    jumpToPage: false,
    download: false,
    pagination: true,
    count: onDemandData?.count || 0,
    selectableRows: false,
    rowsPerPage: rowPerPage,
    serverSide: true,
    rowsPerPageOptions: PageValue,
    draggableColumns: {
      enabled: true
    },
    onChangePage: (page) => {
      setPage(page);
      const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
      getOndemandListWithPagination(page, 25, '', '', filterForSearch, advertiserFilter);
    },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);

      const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
      getOndemandListWithPagination(0, row, '', '', filterForSearch, advertiserFilter);
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
      getOndemandListWithPagination(0, rowPerPage, changedColumn, direction, filterForSearch, advertiserFilter);
    },
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      console.log('New Column Order: ', newColumnOrder);
      console.log('columnIndex ', columnIndex);
      console.log('newPosition ', newPosition);
    },
    customToolbar: () =>
      request_report && (
        <Button variant="contained" onClick={() => setModal(true)}>
          Request Report
        </Button>
      ),

    textLabels: {
      body: {
        noMatch: newData?.length == 0 && 'Sorry, there is no matching data to display'
      }
    }
  };

  const columns = [
    {
      name: 'report_type',
      label: 'Report Name',
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Report Name'
          });
        },
        customBodyRender: (value) => {
          return <Typography>{getValueByName(value)}</Typography>;
        }
      }
    },
    {
      name: 'profile_name',
      label: 'Profile Name',
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Profile Name'
          });
        },
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        }
      }
    },
    {
      name: 'attribution_window',
      label: 'Attribution Window',
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Attribution Window'
          });
        },
        customBodyRender: (value) => {
          return <Typography>{getValueByName(value)}</Typography>;
        }
      }
    },
    {
      name: 'group_by',
      label: 'Group By',
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Group By'
          });
        },
        customBodyRender: (value) => {
          return <Typography>{getValueByName(value)}</Typography>;
        }
      }
    },
    {
      name: 'start_date',
      label: 'Report Start Date',
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Report Start Date'
          });
        }
      }
    },
    {
      name: 'end_date',
      label: 'Report End Date',
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Report End Date'
          });
        }
      },
      customBodyRender: (value) => {
        return <Typography>{extractDate(value)}</Typography>;
      }
    },
    {
      name: 'created_at',
      label: 'Requested On',
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Requested On'
          });
        },
        customBodyRender: (value) => {
          return <Typography>{extractDate(value)}</Typography>;
        }
      }
    },
    {
      name: 'expired_at',
      label: 'Report Expires',
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Report Expires'
          });
        },
        customBodyRender: (value) => {
          return (
            <Typography>
              {value && value !== 'null' && dateDifference(value) !== 0
                ? dateDifference(value) >= 0
                  ? `In ${dateDifference(value)} days`
                  : `Expired`
                : '-'}
            </Typography>
          );
        }
      }
    },
    {
      name: 'job_status',
      label: 'Status',
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Status'
          });
        },
        customBodyRender: (value) => {
          return <Typography>{getValueByName(value)}</Typography>;
        }
      }
    },
    {
      name: 'download_url',
      label: 'Download',
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Download'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = onDemandData?.results[rowData?.rowIndex]?.expired_at;
          const Status = onDemandData?.results[rowData?.rowIndex]?.job_status;
          const show = data?.length > 0 ? dateDifference(data) : null;

          return show !== 0 && show >= 0 && Status !== 'pending' ? (
            <Typography onClick={value} sx={{ display: 'flex', justifyContent: 'center' }}>
              <a className="link-dashna-ui" href={value} download="myFile">
                {download_on_demand && (
                  <Tooltip sx={{ fontSize: '10px', cursor: 'pointer' }} title={'Download'} arrow>
                    {' '}
                    <img src={DownloadIcon} alt="filter icon" />
                  </Tooltip>
                )}
              </a>
            </Typography>
          ) : (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
              {download_on_demand && (
                <Tooltip sx={{ fontSize: '10px' }} title={'Download'} arrow>
                  {' '}
                  <FileDownloadOffOutlinedIcon sx={{ color: 'grey', cursor: 'pointer' }} />
                </Tooltip>
              )}
            </Typography>
          );
        }
      }
    }
  ];

  useEffect(() => {
    setSelectedDates({
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : ''
    });
  }, [startDate, endDate]);

  const getOndemandListWithPagination = (
    page,
    rowPerPage,
    changedColumn = '',
    direction = '',
    tableFilter = { status: '', reportType: '' },
    advertiserFilter
  ) => {
    {
      dispatch(
        GetOnDemandData({
          page,
          rowPerPage,
          changedColumn,
          direction,
          tableFilter,
          advertiserFilter
        })
      );
    }
  };

  useEffect(() => {
    if (JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) || isFirstRender?.current) {
      if (commonAdvertiser?.length > 0) {
        const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
        getOndemandListWithPagination(0, 25, '', '', filterForSearch, advertiserFilter);
      }
      previousAdvertiser.current = commonAdvertiser;
      isFirstRender.current = false;
    }
  }, [commonAdvertiser]);

  const handleSearchFilter = () => {
    setFilterForSearch({ ...tableFilter });
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
    getOndemandListWithPagination(0, 25, '', '', tableFilter, advertiserFilter);
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  return (
    <MainCard title="On Demand Reports">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid container sx={{ p: 3, pl: 0 }} spacing={3}>
          <Grid item xs={12} md={3.5}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="Status"
              label="Status"
              className="custom-auto"
              optionLabelkey="name"
              optionIdkey="id"
              options={OnDemandStatusList || []}
              value={filterValue?.status || []}
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              inputValue={isDropdownOpen ? searchText?.status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, status: value });
                }
              }}
              onChange={(e, newValue) => {
                handleAutoCompleteOnChange(newValue, 'status');
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('status')}
              placeholderCondition={filterValue?.status?.length > 0}
            />
          </Grid>
          <Grid item xs={12} md={3.5}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="Report Type"
              label="Report Type"
              className="custom-auto"
              optionLabelkey="name"
              optionIdkey="id"
              options={ReportTypeOnDemand || []}
              value={filterValue?.report_type || []}
              onOpen={() => setIsDropdownOpenReport(true)}
              onClose={() => setIsDropdownOpenReport(false)}
              inputValue={isDropdownOpenReport ? searchText?.report_type : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, report_type: value });
                }
              }}
              onChange={(e, newValue) => {
                handleAutoCompleteOnChange(newValue, 'report_type');
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('report_type')}
              placeholderCondition={filterValue?.report_type?.length > 0}
            />
          </Grid>
        </Grid>
        <Box>
          <Button variant="contained" onClick={handleSearchFilter} color="secondary">
            <SearchIcon sx={{ mr: 0.5 }} />
            Search
          </Button>
        </Box>
      </Box>
      <MUIDataTable
        actions={[
          {
            icon: 'save'
          }
        ]}
        data={newData}
        columns={columns}
        options={options}
        className="pnl-by-item"
      />
      {Modal && (
        <Dialog
          PaperProps={{ sx: { width: '650px', maxWidth: '650px' } }}
          open={Modal}
          keepMounted
          fullWidth
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography variant="div" sx={{ mr: 1 }}>
                  Request Report
                </Typography>
              </Box>
              <Box
                sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => {
                  setStartDate(defaultStartDate);
                  setEndDate(defaultEndDate);
                  setModal(false);
                }}
              >
                <GridCloseIcon />
              </Box>
            </Box>
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <FormControl fullWidth variant="outlined" margin="normal">
                <Box sx={{ marginBottom: '2px', marginTop: '0px' }}>
                  <CustomDatePicker
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    style={{ width: '100%', fontWeight: 500, color: 'black' }}
                    startDate={startDate}
                    endDate={endDate}
                    alignItems="normal"
                    disableAfterDate={true}
                    block
                  />
                </Box>
              </FormControl>
              <FormControl className="dddddddd" fullWidth variant="outlined" margin="normal">
                <InputLabel color="secondary" id="user-label">
                  Select Profile
                </InputLabel>
                <Select
                  labelId="user-label"
                  className="dddd"
                  id="user"
                  color="secondary"
                  size="small"
                  value={selectedUser}
                  onChange={handleUserChange}
                  label="Select User"
                  sx={{ maxHeight: '200px', overflowY: 'auto' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        width: 250
                      }
                    }
                  }}
                >
                  {/* <Box sx={{ height: '200px', overflowY: 'scroll' }}> */}
                  {allUserDetails?.map((user) => (
                    <MenuItem className="menu-item-ad" key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                  {/* </Box> */}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel color="secondary" id="reportType-label">
                  Report Type
                </InputLabel>
                <Select
                  labelId="reportType-label"
                  id="reportType"
                  value={reportType}
                  size="small"
                  color="secondary"
                  onChange={handleReportTypeChange}
                  label="Report Type"
                >
                  {ReportTypeOnDemand?.map((l) => (
                    <MenuItem key={`report_type_${l?.id}`} value={l?.value}>
                      {l?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel color="secondary" id="attributionWindow-label">
                  Attribution Window
                </InputLabel>
                <Select
                  labelId="attributionWindow-label"
                  id="attributionWindow"
                  color="secondary"
                  value={attributionWindow}
                  size="small"
                  onChange={handleAttributionWindowChange}
                  label="Attribution Window"
                >
                  {/* Add your attribution window options here */}
                  <MenuItem value="days3">3 days</MenuItem>
                  <MenuItem value="days14">14 days</MenuItem>
                  <MenuItem value="days30">30 days</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel color="secondary" id="attributionOption-label">
                  Attribution Option
                </InputLabel>
                <Select
                  labelId="attributionOption-label"
                  id="attributionOption"
                  color="secondary"
                  value={attributionOption}
                  size="small"
                  onChange={handleAttributionOptionChange}
                  label="Attribution Option"
                >
                  {/* Add your attribution option options here */}
                  <MenuItem value="cumulative">Cumulative</MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={Object.values(finalDataForCreate).filter((item) => item == '').length == '0' ? false : true}
                type="submit"
                sx={{ color: 'white' }}
                variant="contained"
                color="secondary"
              >
                Report Request
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </MainCard>
  );
}
