import { Button, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import MUIDataTable from 'mui-datatables';
import React, { useEffect } from 'react';
import { useState } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import MainCard from 'ui-component/cards/MainCard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import colors from 'assets/scss/_themes-vars.module.scss';
import { getSuggestedItems } from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { useContext } from 'react';
import CampaignContext from 'context';
import { PageValue } from 'store/constant';
import { MIN_ITEM_BID_LIMIT } from 'store/constant';

const SelectFromTableAdGroupSuggested = (props) => {
  const { onAddAllClick } = props;
  const { setSearchableData, selectedData, setSelectedData, campaignData } = useContext(CampaignContext);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);

  const { selectdItemData } = useAppSelector((state) => state?.userDetailsSlice);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getAdvertiserListWithPagination(page, rowPerPage);
  }, []);

  const getAdvertiserListWithPagination = (page, rowPerPage, search = '', changedColumn, direction) => {
    const advertiserId = campaignData?.campaign?.advertiser;
    if (campaignData?.campaign?.advertiser) {
      dispatch(getSuggestedItems({ advertiserId, page, rowPerPage, search, changedColumn, direction }));
    }
  };

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      getAdvertiserListWithPagination(0, rowPerPage, data);
    }, 500),
    []
  );

  const columns = [
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectdItemData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {/* <Typography sx={{}}>{data?.product_name}</Typography> */}

                  {data?.product_name?.length > 20 ? (
                    <>
                      <Tooltip
                        placement="top"
                        sx={{ fontSize: '10px' }}
                        title={
                          <Typography variant="body1" sx={{ fontSize: 16 }}>
                            {data?.product_name}
                          </Typography>
                        }
                        arrow
                      >
                        {data?.product_name?.substring(0, 25)}...
                      </Tooltip>
                    </>
                  ) : (
                    data?.product_name
                  )}

                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                  </Link>
                </Box>
              </Box>
            </>
          );
        }
      }
    },

    {
      name: 'brand_name',
      label: 'Brand',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <Typography>{value?.length > 0 ? `$${value}` : value}</Typography>;
        }
      }
    },

    {
      name: 'item_id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const data = selectdItemData?.find((item) => item?.item_id == value);
          const disable = selectedData?.find((item) => item?.item_id == value);
          return (
            <>
              <IconButton
                sx={{ color: colors.secondaryMain }}
                disabled={disable ? true : false}
                onClick={() => {
                  setSelectedData([...selectedData, { ...data, bid_amount: MIN_ITEM_BID_LIMIT }]);
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button disabled={!selectdItemData?.length} variant="outlined" color="secondary" size="small" onClick={onAddAllClick}>
              Add All
            </Button>
          </th>
        )
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    page: page,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: false,
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    search: true,
    searchPlaceholder: 'Input item id / title',
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getAdvertiserListWithPagination(page, row, search);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
      const filteredItems = selectdItemData?.filter(
        (item) =>
          item?.brand?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          item?.product_name?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
      setSearchableData(filteredItems);
    },
    onSearchClose: () => {
      setSearch('');
      setSearchableData(selectdItemData);
      getAdvertiserListWithPagination(0, rowPerPage, '');
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '', changedColumn, direction);
    }
  };

  return (
    <>
      <Box>
        <MainCard title="Search Item" sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
          <MUIDataTable
            actions={[
              {
                icon: 'save'
              }
            ]}
            sx={{ background: 'red', padding: 0 }}
            data={selectdItemData}
            columns={columns}
            options={options}
            className="pnl-by-item"
          />
        </MainCard>
      </Box>
    </>
  );
};

export default SelectFromTableAdGroupSuggested;
