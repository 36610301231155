import { useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { Box, Typography } from '@mui/material';
import { GetListingQualityDashboard } from 'store/thunk/userThunk';
import { setCommonLoader } from 'store/slices/userDetailsSlice';

const ListingQuality = () => {
  const dispatch = useAppDispatch();
  const prevSelectedStores = useRef();
  const { selectedStores, ListingQualityDashboard } = useAppSelector((state) => state?.userDetailsSlice);

  useEffect(() => {
    dispatch(setCommonLoader(true));
  }, []);

  useEffect(() => {
    if (JSON.stringify(prevSelectedStores.current) !== JSON.stringify(selectedStores)) {
      if (selectedStores?.length > 0) {
        const params = {
          client: selectedStores
        };
        dispatch(GetListingQualityDashboard({ _request: params }));
      }
      // Update the previous value
      prevSelectedStores.current = selectedStores;
    }
  }, [selectedStores]);

  return (
    <Box className="BI-dashboard-card">
      <Typography variant="h2" className="Overview-title">
        Listing Quality Scoreboard
      </Typography>
      {ListingQualityDashboard && (
        <iframe
          title="Listing Quality Scoreboard"
          src={ListingQualityDashboard}
          style={{ border: 'none', height: 'calc(100vh - 200px)', width: '100%' }}
        ></iframe>
      )}
    </Box>
  );
};

export default ListingQuality;
