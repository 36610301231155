import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useContext } from 'react';
import CampaignContext from 'context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { useAppDispatch } from 'store';
import {
  setExistingAdgroupId,
  setExistingAutoAdgroupId,
  setExistingAutoCampaignId,
  setExistingBrandId,
  setExistingCampaignId,
  setExistingManualAdgroupId,
  setExistingManualCampaignId
} from 'store/slices/userDetailsSlice';
import { CAMPAIGN } from 'constant/campaignConstant';

const CompleteStep = () => {
  const {
    campaign,
    autoCampaign,
    manualCampaign,
    setActiveStep,
    setSearchableData,
    setSelectedData,
    setSelectedKeywordData,
    setCampaignData,
    setDateType,
    setCampaign,
    setAutoCampaign,
    setManualCampaign
  } = useContext(CampaignContext);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCreateAgain = async () => {
    setSearchableData([]);
    setSelectedData([]);
    setSelectedKeywordData([]);
    setDateType('any');
    setCampaignData({
      campaign: {},
      autoCampaign: {},
      manualCampaign: {},
      adgroup: {},
      autoAdgroup: {},
      manualAdgroup: {},
      aditem: {},
      autoAditem: {},
      manualAditem: {}
    });
    setCampaign({
      budget_type: 'daily',
      campaign_type: 'sponsoredProducts',
      targeting_type: CAMPAIGN.TARGETING_TYPE.BOTH,
      bidding_strategy: CAMPAIGN.BIDDING_STRATEGY.FIXED,
      rollover: true,
      is_search_ingrid: true,
      is_others: true,
      is_item_buybox: true,
      is_home_page: true,
      is_stock_up: true,
      is_search_carousel: false,
      is_item_carousel: false,
      placement_bid_search_ingrid: null,
      placement_bid_item_buybox: null,
      placement_bid_home_page: null,
      placement_bid_stock_up: null,
      platform_bid_desktop: null,
      platform_bid_app: null,
      platform_bid_mobile: null,
      name: '',
      advertiser: campaign?.advertiser,
      daily_budget: '',
      total_budget: '',
      start_date: dayjs(new Date()).format('YYYY-MM-DD'),
      end_date: ''
    });
    setAutoCampaign({
      budget_type: 'daily',
      campaign_type: 'sponsoredProducts',
      targeting_type: CAMPAIGN.TARGETING_TYPE.AUTO,
      bidding_strategy: CAMPAIGN.BIDDING_STRATEGY.FIXED,
      rollover: true,
      is_search_ingrid: true,
      is_others: true,
      is_item_buybox: true,
      is_home_page: true,
      is_stock_up: true,
      is_search_carousel: false,
      is_item_carousel: false,
      placement_bid_search_ingrid: null,
      placement_bid_item_buybox: null,
      placement_bid_home_page: null,
      placement_bid_stock_up: null,
      platform_bid_desktop: null,
      platform_bid_app: null,
      platform_bid_mobile: null,
      name: '',
      advertiser: autoCampaign?.advertiser,
      daily_budget: '',
      total_budget: '',
      start_date: dayjs(new Date()).format('YYYY-MM-DD'),
      end_date: ''
    });
    setManualCampaign({
      budget_type: 'daily',
      campaign_type: 'sponsoredProducts',
      targeting_type: CAMPAIGN.TARGETING_TYPE.MANUAL,
      bidding_strategy: CAMPAIGN.BIDDING_STRATEGY.FIXED,
      rollover: true,
      is_search_ingrid: true,
      is_others: true,
      is_item_buybox: true,
      is_home_page: true,
      is_stock_up: true,
      is_search_carousel: false,
      is_item_carousel: false,
      placement_bid_search_ingrid: null,
      placement_bid_item_buybox: null,
      placement_bid_home_page: null,
      placement_bid_stock_up: null,
      platform_bid_desktop: null,
      platform_bid_app: null,
      platform_bid_mobile: null,
      name: '',
      advertiser: manualCampaign?.advertiser,
      daily_budget: '',
      total_budget: '',
      start_date: dayjs(new Date()).format('YYYY-MM-DD'),
      end_date: ''
    });
    setActiveStep(1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setExistingCampaignId(null));
    dispatch(setExistingAdgroupId(null));
    dispatch(setExistingBrandId(null));
    dispatch(setExistingAutoCampaignId(null));
    dispatch(setExistingAutoAdgroupId(null));
    dispatch(setExistingManualCampaignId(null));
    dispatch(setExistingManualAdgroupId(null));
  }, []);

  return (
    <Box className="info-container">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 3 }}>
        <VerifiedIcon sx={{ color: 'green', fontSize: 50, mb: 1 }} />
        <Typography variant="h2">Success!</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: 1 }}>
        <Box className="info-row">
          <Typography variant="h5" className="label">
            Campaign Name:
          </Typography>
          <Typography variant="h5">{campaign?.name}</Typography>
        </Box>
        <Box className="info-row">
          <Typography variant="h5" className="label">
            Campaign Type:
          </Typography>
          <Typography variant="h5">{campaign?.campaign_type}</Typography>
        </Box>
        <Box className="info-row">
          <Typography variant="h5" className="label">
            Schedule:
          </Typography>
          <Typography variant="h5">
            {campaign?.start_date} and {campaign?.end_date}
          </Typography>
        </Box>
        <Box className="info-row">
          <Typography variant="h5" className="label">
            Budget Type:
          </Typography>
          <Typography variant="h5">{campaign?.budget_type}</Typography>
        </Box>
        <Box className="info-row">
          <Typography variant="h5" className="label">
            Daily Budget:
          </Typography>
          <Typography variant="h5">$ {campaign?.daily_budget}</Typography>
        </Box>
        <Box className="info-row">
          <Typography variant="h5" className="label">
            Total Budget:
          </Typography>
          <Typography variant="h5">$ {campaign?.total_budget}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
        <>
          <Button variant="outlined" color="secondary" onClick={handleCreateAgain}>
            Create Again
          </Button>
          <Button variant="outlined" sx={{ ml: 1, color: '#000', borderColor: '#000' }} onClick={() => navigate('/campaign')}>
            Return
          </Button>
        </>
      </Box>
    </Box>
  );
};

export default CompleteStep;
