import { Button, IconButton, Link, Typography, Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import MainCard from 'ui-component/cards/MainCard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import colors from 'assets/scss/_themes-vars.module.scss';
import { getSelectFromItemsFromId } from 'store/thunk/userThunk';
import { useAppDispatch, useAppSelector } from 'store';
import { debounce } from 'lodash';
import CampaignContext from 'context';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { PageValue } from 'store/constant';
import { MIN_ITEM_BID_LIMIT } from 'store/constant';

const SelectFromTable = (props) => {
  const { onAddAllClick } = props;
  const { setSearchableData, selectedData, setSelectedData, campaignData } = useContext(CampaignContext);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);

  const { selectdItemData } = useAppSelector((state) => state?.userDetailsSlice);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getAdvertiserListWithPagination(page, rowPerPage);
  }, []);

  const getAdvertiserListWithPagination = (page, rowPerPage, search = '', changedColumn, direction) => {
    const advertiserId = campaignData?.campaign?.advertiser;
    if (campaignData?.campaign?.advertiser) {
      dispatch(getSelectFromItemsFromId({ advertiserId, page, rowPerPage, search, changedColumn, direction }));
    }
  };

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      getAdvertiserListWithPagination(0, rowPerPage, data);
    }, 500),
    []
  );

  const columns = [
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectdItemData[tableMeta?.rowIndex];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {data?.images ? (
                <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
              ) : (
                <InsertPhotoIcon fontSize="large" sx={{ margin: '4px', color: colors.grey500 }} />
              )}
              <Box>
                <Typography>{data?.product_name}</Typography>
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                >
                  <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                </Link>
              </Box>
            </Box>
          );
        }
      }
    },
    {
      name: 'brand',
      label: 'Brand',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'item_id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const data = selectdItemData?.find((item) => item?.item_id == value);
          const disable = selectedData?.find((item) => item?.item_id == value);
          return (
            <IconButton
              sx={{ color: colors.secondaryMain }}
              disabled={disable ? true : false}
              onClick={() => {
                setSelectedData([...selectedData, { ...data, bid_amount: MIN_ITEM_BID_LIMIT }]);
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button disabled={!selectdItemData?.length} variant="outlined" color="secondary" size="small" onClick={onAddAllClick}>
              Add All
            </Button>
          </th>
        )
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: false,
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    search: true,
    searchPlaceholder: 'Input item id / title',
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getAdvertiserListWithPagination(page, row, search);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
      const filteredItems = selectdItemData?.filter(
        (item) =>
          item?.brand?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          item?.product_name?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
      setSearchableData(filteredItems);
    },
    onSearchClose: () => {
      setSearch('');
      setSearchableData(selectdItemData);
      getAdvertiserListWithPagination(0, rowPerPage, '');
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '', changedColumn, direction);
    }
  };

  return (
    <Box>
      <MainCard title="Search Item" sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
        <MUIDataTable
          actions={[
            {
              icon: 'save'
            }
          ]}
          sx={{ background: 'red', padding: 0 }}
          data={selectdItemData}
          columns={columns}
          options={options}
          className="pnl-by-item"
        />
      </MainCard>
    </Box>
  );
};

export default SelectFromTable;
