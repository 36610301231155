import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import { useMemo } from 'react';

function MultilineInputPopover(props) {
  const {
    label,
    // deaultValue,
    handleUpdateMultilineValue,
    isReset
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [multilineValue, setMultilineValue] = useState('');

  const handleSubmit = () => {
    // Convert multilineValue to comma-separated string
    const commaSeparated = multilineValue
      .split('\n') // Split by newlines
      .map((line) => line.trim()) // Trim each line
      .filter((line) => line) // Remove empty lines
      .join(','); // Join with commas
    const totalItemsSearch = multilineValue
      .split('\n')
      .map((line) => line.trim()) // Trim each line
      .filter((line) => line).length;
    if (totalItemsSearch > 0) {
      setInputValue(`${totalItemsSearch} items`);
    } else {
      setInputValue('');
    }
    handleUpdateMultilineValue(commaSeparated); //Trigger Function
  };

  //Reset Search Item Id filters when we change Profile
  useMemo(() => {
    if (!isReset) {
      setMultilineValue('');
      setInputValue('');
    }
  }, [isReset]);

  const handleInputClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    handleSubmit();
    setAnchorEl(null);
  };

  const handleMultilineChange = (event) => {
    setMultilineValue(event.target.value);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <TextField variant="outlined" fullWidth placeholder={label} onClick={handleInputClick} value={inputValue} />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box p={2} width="300px">
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={multilineValue}
            onChange={handleMultilineChange}
            placeholder="Enter your text here..."
          />
        </Box>
      </Popover>
    </div>
  );
}

export default MultilineInputPopover;
