import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { allValuesTrueByValue } from 'helper/commonHelper';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import colorValue from 'assets/scss/_themes-vars.module.scss';

const CompareValueWithColorForOverView = (props) => {
  const theme = useTheme();
  const { Percentage, UpDown, keys, amountChange } = props;
  const grayColor = theme.palette.grey[500];
  const errorMain = colorValue.errorMain;
  const successDark = colorValue.successDark;
  const darkfigmaFontColor = colorValue.darkfigmaFontColor;

  return (
    <Typography display="flex" className="dashboard-card-compare-data">
      {typeof Percentage !== 'undefined' && (
        <Typography display="flex" sx={{ alignItems: 'center', flexDirection: 'row', marginLeft: '-3px' }}>
          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            {Percentage == 0.0 ? (
              <ArrowCircleRightOutlinedIcon
                sx={{ width: '24px', height: '24px', fontSize: '10px' }}
                fontSize="small"
                className="info-forward dashboard-card-arrow"
              />
            ) : UpDown ? (
              <ArrowCircleUpIcon
                className="dashboard-card-arrow"
                fontSize="small"
                sx={{
                  color:
                    allValuesTrueByValue(keys, UpDown) == 'gray' || allValuesTrueByValue(keys, UpDown) == 'gray1'
                      ? grayColor
                      : allValuesTrueByValue(keys, UpDown) == 'is_up'
                      ? successDark
                      : errorMain
                }}
              />
            ) : (
              <ArrowCircleDownIcon
                className="dashboard-card-arrow"
                fontSize="large"
                sx={{
                  color:
                    allValuesTrueByValue(keys, UpDown) == 'gray' || allValuesTrueByValue(keys, UpDown) == 'gray1'
                      ? grayColor
                      : allValuesTrueByValue(keys, UpDown) == 'is_up'
                      ? successDark
                      : errorMain
                }}
              />
            )}
          </Typography>
          <Typography
            className="dashboard-card-text"
            sx={{
              color:
                Percentage == 0.0
                  ? grayColor
                  : allValuesTrueByValue(keys, UpDown) == 'gray' || allValuesTrueByValue(keys, UpDown) == 'gray1'
                  ? grayColor
                  : allValuesTrueByValue(keys, UpDown) == 'is_up'
                  ? successDark
                  : errorMain,
              marginLeft: '5px'
            }}
          >
            {Percentage?.toFixed(1)}%
            <Box className="dashboard-card-text" sx={{ color: darkfigmaFontColor }}>
              ({amountChange})
            </Box>
          </Typography>
        </Typography>
      )}
    </Typography>
  );
};

export default CompareValueWithColorForOverView;
