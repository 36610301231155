import {
  Box,
  Tab,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
  FormHelperText,
  Checkbox,
  Menu,
  MenuItem,
  Tooltip
} from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import CheckIcon from '@mui/icons-material/Check';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate } from 'react-router';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import SelectFromTable from './searchTable';
import MUIDataTable from 'mui-datatables';
import MainCard from 'ui-component/cards/MainCard';
import CampaignContext from 'context';
import CloseIcon from '@mui/icons-material/Close';
import EnterKeywordTable from './enterKeywordTable';
import { getSuggestedKeyword, postTargetSettingDetails } from 'store/thunk/userThunk';
import { PageValue } from 'store/constant';
import colors from 'assets/scss/_themes-vars.module.scss';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import SetBid from 'ui-component/bids/modal/setBid';
import { MIN_KEYWORD_BID_LIMIT_CAMP_SP, MAX_KEYWORD_BID_LIMIT_CAMP_SP } from 'store/constant';

const TargetAdGroupSetting = () => {
  const { campaign, handleBackwordButtonClick, handleForwordButtonClick, selectedKeywordData, setSelectedKeywordData } =
    useContext(CampaignContext);
  const navigate = useNavigate();

  const [value, setValue] = useState('2');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { adgoupDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const [isModalSetBid, setIsModalSetBid] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectMode, setSelectMode] = useState('all');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isFirstRender = useRef(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    const adgroupId = adgoupDetails?.id;
    if (adgroupId) {
      dispatch(getSuggestedKeyword({ adgroupId }));
    }
  }, []);

  //When selecting Select All, Select Current mode
  useEffect(() => {
    // Skip first render because to prevent automatically select all selected option at first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleSelectAll('switchMode'); //For perform checkbox selection
  }, [selectMode]);

  //When selecting tab
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  //On click on Sinal Submit button Next
  const onButtonClick = (event) => {
    event.preventDefault();
    if (selectedKeywordData?.length > 0) {
      setError(false);
      const tempArray = selectedKeywordData?.map((item) => {
        const temp = {
          keywordtext: item.keywordtext,
          matchtype: item.matchtype,
          bid_amount: item.bid_amount
        };
        return temp;
      });
      setLoading(true);
      const _request = {
        campaign: adgoupDetails?.campaign,
        adgroup: adgoupDetails?.id,
        keyword: tempArray
      };
      dispatch(postTargetSettingDetails(_request)).then((res) => {
        setLoading(false);
        if (res.payload.status) {
          handleForwordButtonClick();
        }
      });
    } else {
      setError(true);
    }
  };

  //When selecting single Checkbox
  const handleRowSelection = (index) => {
    if (selectedArray.includes(index)) {
      setSelectedArray(selectedArray.filter((i) => i !== index));
    } else {
      setSelectedArray([...selectedArray, index]);
    }
  };

  //When click on Select All, Select Current or Select All checkbox
  const handleSelectAll = (mode = '') => {
    if (selectMode === 'all') {
      if (isAllSelected() && mode === 'globalCheck') {
        setSelectedArray([]); // Unselect all
      } else {
        setSelectedArray(selectedKeywordData.map((_, index) => index)); // Select all rows
      }
    } else {
      if (isAllSelected() && mode === '') {
        setSelectedArray([]); // Unselect all
      } else {
        const startIndex = currentPage * rowsPerPage;
        // setSelectedArray([...Array(rowsPerPage).keys()].map((i) => i + startIndex)); // Select only current page rows

        // Ensure selected indexes do not exceed selectedKeywordData.length
        const endIndex = Math.min(startIndex + rowsPerPage, selectedKeywordData.length);
        setSelectedArray([...Array(endIndex - startIndex).keys()].map((i) => i + startIndex));
      }
    }
  };

  // Function to check if all rows (or current page rows) are selected
  const isAllSelected = () => {
    if (selectMode === 'all') {
      return selectedArray.length === selectedKeywordData.length;
    } else {
      const startIndex = currentPage * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, selectedKeywordData.length);
      // Restrict indexes to prevent out-of-bounds selection
      const currentPageIndexes = [...Array(endIndex - startIndex).keys()]?.map((i) => i + startIndex);
      return currentPageIndexes?.every((index) => selectedArray?.includes(index));
    }
  };

  //On click of Arrow Click
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //To close open Menu Items
  const handleClose = () => {
    setAnchorEl(null);
  };

  //While selecting any menu items options
  const handleSelect = (mode) => {
    setSelectMode(mode);
    handleClose(); // Close the menu after selection
  };

  //Toggle Set Bid Modal
  const handleToggleSetBid = () => {
    setIsModalSetBid(!isModalSetBid);
  };

  //Apply Bid
  const handleApplyBid = (bidValue) => {
    const updatedKeywordData = selectedKeywordData.map((item, index) =>
      selectedArray.includes(index) ? { ...item, bid_amount: bidValue } : item
    );
    setSelectedKeywordData(updatedKeywordData);
    setSelectedArray([]);
  };

  //Table Columns
  const selectedColumns = [
    {
      name: 'select',
      label: (
        <Checkbox
          checked={isAllSelected()} // Fix: Properly checks/unchecks
          onChange={handleSelectAll}
          indeterminate={selectedArray.length > 0 && !isAllSelected()} // Shows partial selection state
        />
      ),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          return <Checkbox checked={selectedArray.includes(rowIndex)} onChange={() => handleRowSelection(rowIndex)} />;
        },
        customHeadRender: () => (
          <th style={{ borderBottom: '1px solid #e0e0e0', borderRight: '1px solid #E8E8E8' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', ml: '17px' }}>
              <Checkbox
                checked={selectedArray.length > 0}
                // onChange={handleSelectAll}
                onChange={() => handleSelectAll(selectMode === 'current' ? '' : 'globalCheck')}
              />
              <Tooltip title="Select Mode">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  // sx={{ ml: 2 }}
                  aria-controls={open ? 'select-mode-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <UnfoldMoreIcon />
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                id="select-mode-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={() => handleSelect('all')}>
                  {selectMode === 'all' && <CheckIcon fontSize="small" sx={{ mr: 1 }} />}
                  <Typography>Select All</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleSelect('current')}>
                  {selectMode === 'current' && <CheckIcon fontSize="small" sx={{ mr: 1 }} />}
                  <Typography>Select Current</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </th>
        )
      }
    },
    {
      name: 'keywordtext',
      label: 'Keywords',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedKeywordData[tableMeta?.rowIndex];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box>
                <Typography>{value}</Typography>
                <Typography sx={{ color: colors.primaryMain }}>{data?.item_id}</Typography>
              </Box>
            </Box>
          );
        }
      }
    },

    {
      name: 'matchtype',
      label: 'Match Type',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bid_amount',
      label: 'bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let data = selectedKeywordData[tableMeta?.rowIndex];
          return (
            <TextField
              size="small"
              value={data?.bid_amount}
              type="number"
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent form submission
                }
              }}
              onBlur={(e) => {
                const value = Number(e.target.value);
                const newPrice =
                  value < Number(MIN_KEYWORD_BID_LIMIT_CAMP_SP)
                    ? MIN_KEYWORD_BID_LIMIT_CAMP_SP
                    : value > Number(MAX_KEYWORD_BID_LIMIT_CAMP_SP)
                    ? MAX_KEYWORD_BID_LIMIT_CAMP_SP
                    : value;

                if (newPrice !== value) {
                  const updatedData = [...selectedKeywordData];
                  updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                  setSelectedKeywordData(updatedData);
                }
              }}
              onChange={(e) => {
                const newPrice = Number(e.target.value == 0) ? null : Number(e.target.value);
                const updatedData = [...selectedKeywordData];
                updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                setSelectedKeywordData(updatedData);
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              inputProps={{
                step: '0.01'
              }}
            />
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <IconButton
              onClick={() => {
                const removedIndex = tableMeta?.rowIndex;

                var tempData = [...selectedKeywordData];
                tempData = tempData?.filter((item, index) => index != removedIndex);
                setSelectedKeywordData(tempData);

                //Adjust selected array index
                if (selectedArray.includes(removedIndex)) {
                  const updatedArray = selectedArray
                    .filter((item) => item !== removedIndex)
                    .map((item) => (item > removedIndex ? item - 1 : item));
                  setSelectedArray(updatedArray);
                } else {
                  const updatedArray = selectedArray.map((item) => (item > removedIndex ? item - 1 : item));
                  setSelectedArray(updatedArray);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={selectedKeywordData?.length == 0}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                setSelectedKeywordData([]);
                setSelectedArray([]);
              }}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  //Table Options
  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: PageValue,
    selectableRows: false,
    search: false,
    viewColumns: false,
    onChangePage: (newPage) => {
      setCurrentPage(newPage);
    },
    onChangeRowsPerPage: (row) => {
      setRowsPerPage(row);
      setSelectedArray([]); // Unselect all
    }
  };

  return (
    <MainCard content={false} boxShadow sx={{ p: 2, mt: 2 }}>
      <form noValidate onSubmit={onButtonClick}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item sm={12}>
              {renderLabel('Keyword', true)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChangeTab}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Enter Keywords" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <EnterKeywordTable />
                    </TabPanel>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SelectFromTable />
                    </TabPanel>
                  </Box>
                </TabContext>
                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <Box display={'flex'} alignItems="center" gap={3} sx={{ p: '15px 0px 15px 27px' }}>
                    <Button variant="outlined" disabled={selectedArray?.length > 0 ? false : true} onClick={handleToggleSetBid}>
                      Set Bid
                    </Button>
                    {selectedArray?.length > 0 && (
                      <Typography variant="h5">{selectedArray?.length} Keyword(s) Selected</Typography>
                    )}
                  </Box>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      sx={{ padding: 0 }}
                      data={selectedKeywordData}
                      columns={selectedColumns}
                      options={selectedOptions}
                      className="pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>
              {error && (
                <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                  {'Please select atlease one keyword.'}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={() => navigate('/campaign')}>
                CANCEL
              </Button>
              <Button variant="outlined" color="secondary" sx={{ ml: 1 }} onClick={handleBackwordButtonClick}>
                PREVIOUS
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit" disabled={loading}>
                {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
                NEXT
              </Button>
            </>
          </Box>
        </Box>
      </form>
      {isModalSetBid && (
        <SetBid
          isOpen={isModalSetBid}
          onClose={handleToggleSetBid}
          onSave={handleApplyBid}
          bidType={'KEYWORD'}
          campType={campaign?.campaign_type}
        />
      )}
    </MainCard>
  );
};

export default TargetAdGroupSetting;
