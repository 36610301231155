import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import MainCard from 'ui-component/cards/MainCard';
import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';
import {
  MIN_KEYWORD_BID_LIMIT_CAMP_SP,
  MIN_KEYWORD_BID_LIMIT_CAMP_SB,
  MIN_KEYWORD_BID_LIMIT_CAMP_SV,
  MAX_KEYWORD_CHAR_LIMIT,
  MAX_KEYWORD_LIMIT
} from 'store/constant';
import { useEffect } from 'react';

const EnterKeywordTable = (props) => {
  const { setSelectedKeywordData, selectedKeywordData, data } = props;

  const [count, setCount] = useState(0);
  const [exceedCount, setExceedCount] = useState(0);

  const [adGroupName, setAdGroupName] = useState('');
  // const matchType = ["exact", "phrase", "broad"];
  const [selectType, setSelectType] = useState([]);

  const matchTypeData = [
    { id: 1, name: 'exact' },
    { id: 2, name: 'phrase' },
    { id: 3, name: 'broad' }
  ];

  const [IsDropdownOpenCamSt, SetIsDropdownOpenCamSt] = useState(false);
  const [SearchText, setSearchText] = useState({ searchTextMatchType: null });

  //For track total keyword count
  useEffect(() => {
    if (adGroupName?.trim() === '') {
      setCount(0);
      setExceedCount(0);
    } else {
      const lines = adGroupName
        ?.split(/\n+/)
        ?.map((line) => line.trim())
        ?.filter((line) => line.length > 0);

      setCount(lines?.length);

      // Count how many keywords exceed 80 characters
      const longKeywords = lines?.filter((line) => line?.length > MAX_KEYWORD_CHAR_LIMIT)?.length;
      setExceedCount(longKeywords); // Update state for exceeding count
    }
  }, [adGroupName]);

  // Function to get the minimum bid based on campaign type
  const getMinBidLimit = (campaign_type) => {
    if (campaign_type === 'sponsoredProducts') return MIN_KEYWORD_BID_LIMIT_CAMP_SP;
    if (campaign_type === 'sba') return MIN_KEYWORD_BID_LIMIT_CAMP_SB;
    if (campaign_type === 'video') return MIN_KEYWORD_BID_LIMIT_CAMP_SV;
    return MIN_KEYWORD_BID_LIMIT_CAMP_SP;
  };

  const handleAddButton = () => {
    const values = [
      ...new Set(
        adGroupName
          .trim()
          .split('\n')
          .filter((item) => item.length <= MAX_KEYWORD_CHAR_LIMIT && Boolean(item))
      )
    ]; // Remove duplicate lines and remove empty string
    let array = [...selectedKeywordData];

    const newKeywords = new Set(
      values.flatMap((item) =>
        (selectType?.length > 0 ? selectType : matchTypeData).map((type) => ({
          keywordtext: item,
          matchtype: type?.name,
          bid_amount: getMinBidLimit(data?.[0]?.campaign_type),
          suggested_bid: null
        }))
      )
    );

    // Remove duplicates based on `keywordtext` and `matchtype`
    newKeywords.forEach((newItem) => {
      const isDuplicate = array.some(
        (existing) => existing.keywordtext === newItem.keywordtext && existing.matchtype === newItem.matchtype
      );
      if (!isDuplicate) {
        array.push(newItem);
      }
    });

    setSelectedKeywordData(array);
    setAdGroupName('');
  };

  const handleSetAdgroupName = (e) => {
    const inputVal = e.target.value;
    const words = inputVal.trim().split('\n');
    if (words.length <= MAX_KEYWORD_LIMIT) {
      setAdGroupName(inputVal);
    } else {
      setAdGroupName(inputVal);
    }
  };

  return (
    <Box>
      <MainCard sx={{ padding: 0 }}>
        <CustomAutocomplete
          multiple
          label="Match Type"
          id="tags-outlined"
          name="adgroup_name"
          value={selectType || []}
          options={matchTypeData || []}
          onChange={(e, value) => {
            if (value.find((option) => option.all)) {
              const allIdsCam = matchTypeData?.map((obj) => obj.id);

              if (value.length - 1 === allIdsCam.length) {
                return setSelectType([]);
              } else {
                return setSelectType(matchTypeData);
              }
            }
            setSelectType(value);
          }}
          size={'small'}
          sx={{ width: '30%', mb: 2 }}
          onOpen={() => SetIsDropdownOpenCamSt(true)}
          onClose={() => SetIsDropdownOpenCamSt(false)}
          filterSelectedOptions
          color="secondary"
          optionLabelkey="name"
          onInputChange={(event, value, reason) => {
            if (reason !== 'reset') {
              setSearchText({ ...SearchText, searchTextMatchType: value });
            }
          }}
          inputValue={IsDropdownOpenCamSt ? SearchText?.searchTextMatchType : ''}
          selectAllCheckboxCondiion={!!(selectType?.length == matchTypeData.length)}
          placeholderCondition={selectType?.length > 0}
          isOptionEqualToValue={(option, value) => option?.id == value?.id}
        />

        <TextField
          name={'adgroup_name_keywords'}
          fullWidth
          type="text"
          multiline
          color="secondary"
          placeholder="Please enter keyword, one keyword per line"
          rows={4}
          value={adGroupName}
          onChange={handleSetAdgroupName}
        />

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box className={count > MAX_KEYWORD_LIMIT ? 'error-text' : ''}>
            You can add up to {MAX_KEYWORD_LIMIT} keywords, with each keyword limited to {MAX_KEYWORD_CHAR_LIMIT} characters.
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box display={'flex'}>
              <Box className={count > MAX_KEYWORD_LIMIT ? 'error-text' : ''}>{count}</Box>
              <Box>/ {MAX_KEYWORD_LIMIT}</Box>
            </Box>
            <Button
              disabled={count > MAX_KEYWORD_LIMIT ? true : false}
              variant="outlined"
              size="small"
              color="secondary"
              onClick={handleAddButton}
            >
              Add
            </Button>
          </Box>
        </Box>
        <Box>
          {exceedCount > 0 && (
            <Box className="error-text">{`${exceedCount} of the keywords exceeds ${MAX_KEYWORD_CHAR_LIMIT} characters`}</Box>
          )}
        </Box>
      </MainCard>
    </Box>
  );
};

export default EnterKeywordTable;
