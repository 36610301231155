// react
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';

//materil ui
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TablePagination,
  Tooltip,
  Typography,
  Link,
  CircularProgress,
  Button
} from '@mui/material';
import MUIDataTable from 'mui-datatables';

///project import
import { useAppDispatch, useAppSelector } from 'store';
import { GetTopProductData } from 'store/thunk/userThunk';
import { formateValue } from 'helper/commonHelper';
import colors from 'assets/scss/_themes-vars.module.scss';
import TopProductDefaultImage from 'assets/images/icons/img_.png';
import downloadIcon from 'assets/images/dashna/download.svg';
import * as XLSX from 'xlsx-js-style';

const TopProductDashboard = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { selectedDashboardAdvertiser, dashboardDateRange, commonAdvertiser, TopProductData, commonDateRange } = useAppSelector(
    (state) => state?.userDetailsSlice
  );

  //Prevent Un-necessary loading when no profile changed
  const previousAdvertiser = useRef(commonAdvertiser);
  const previousDateRange = useRef(commonDateRange);
  const previousDashboardDateRange = useRef(dashboardDateRange);
  const previousSelectedAdvertiser = useRef(selectedDashboardAdvertiser);
  const isFirstRender = useRef(true);

  const [sortBy, setSortBy] = useState('ad_sales');
  const [newData, setNewData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoDataFound, setIsNoDataFound] = useState(false);

  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value);
    });
    return formattedData;
  };

  useEffect(() => {
    if (TopProductData?.length) {
      const finalData = TopProductData.map((item) => formateData(Object.entries(item)));
      setNewData(finalData);
      setIsLoading(false);
    } else {
      setIsLoading(false); //Stop loader if there is no Top Products
    }
  }, [TopProductData]);

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  //Download Top Products Excel
  const handleDownloadCsv = () => {
    //For Item Id Link color
    const itemNameColor = colors?.blueitemTitle.replace('#', '');

    //Prepare Header
    let headerWithKeys = [
      {
        label: 'Item',
        key: 'item_name'
      },
      {
        label: 'Item Id',
        key: 'item_id'
      },
      {
        label: 'Sales',
        key: 'ad_sales'
      },
      {
        label: 'Spend',
        key: 'ad_spend'
      },
      {
        label: 'RoAS',
        key: 'roas'
      },
      {
        label: 'ACoS',
        key: 'acos'
      },
      {
        label: 'Units Sold',
        key: 'units_sold'
      }
    ];
    let header = headerWithKeys.map((l) => l?.label);

    //Create New Excel Tab
    const wb = XLSX.utils.book_new();

    const globalArr = [];
    const row = [];

    //Prepare Header Row
    header.map((label) => {
      const obj = { v: label, t: 's', s: { font: { bold: true } } };
      row.push(obj);
    });
    globalArr.push(row);

    newData.map((d) => {
      const array = [];
      headerWithKeys.map((h) => {
        let style = {};
        let itemLink = {};
        if (h?.key === 'item_name') {
          // style = { font: { color: { rgb: itemNameColor } } };
        }
        if (h?.key === 'item_id') {
          style = { font: { color: { rgb: itemNameColor }, underline: true } };
          itemLink = { Target: `https://www.walmart.com/ip/${d?.[h?.key]}` };
        }
        const obj = { v: d?.[h?.key], t: 's', l: itemLink, s: style };
        array.push(obj);
      });
      globalArr.push(array);
    });

    const excelTab =
      sortBy === 'ad_sales'
        ? 'Sales'
        : sortBy === 'ad_spend'
        ? 'Spend'
        : sortBy === 'roas'
        ? 'RoAS'
        : sortBy === 'acos'
        ? 'ACoS'
        : 'Units Sold';

    const ws = XLSX.utils.aoa_to_sheet(globalArr);
    ws['!cols'] = header.map(() => ({ wch: 12 })); // Set each column

    let startDate = commonDateRange?.startDate ? commonDateRange?.startDate : dashboardDateRange?.startDate;
    let endDate = commonDateRange?.endDate ? commonDateRange?.endDate : dashboardDateRange?.endDate;

    XLSX.utils.book_append_sheet(wb, ws, excelTab);
    XLSX.writeFile(wb, `Top Products ${startDate} To ${endDate}.xlsx`);
  };

  const getTopProductList = () => {
    if (params?.token) {
      if (dashboardDateRange && Object.keys(dashboardDateRange).length && selectedDashboardAdvertiser.length) {
        setIsLoading(true);
        setIsNoDataFound(false);
        getTopProductListWithPagination(0, selectedDashboardAdvertiser, dashboardDateRange);
        setPage(0);
      }
    } else {
      setIsNoDataFound(false);
      const advertiserFilter = commonAdvertiser.length > 0 ? commonAdvertiser : '';
      if (advertiserFilter.length > 0) {
        setIsLoading(true);
        getTopProductListWithPagination(0, advertiserFilter, commonDateRange);
        setPage(0);
      }
    }
  };

  useEffect(() => {
    if (JSON.stringify(previousDateRange.current) !== JSON.stringify(commonDateRange)) {
      getTopProductList();
      previousDateRange.current = commonDateRange;
    }
  }, [commonDateRange]);

  useEffect(() => {
    if (JSON.stringify(previousDashboardDateRange.current) !== JSON.stringify(dashboardDateRange)) {
      getTopProductList();
      previousDashboardDateRange.current = dashboardDateRange;
    }
  }, [dashboardDateRange]);

  useEffect(() => {
    if (!isFirstRender.current) {
      getTopProductList();
    }
  }, [sortBy]);

  //For shared dashboard page to prevent unnecessary loading when no profile changed
  useEffect(() => {
    if (JSON.stringify(previousSelectedAdvertiser.current) !== JSON.stringify(selectedDashboardAdvertiser)) {
      getTopProductList();
      previousSelectedAdvertiser.current = selectedDashboardAdvertiser;
    }
  }, [selectedDashboardAdvertiser]);

  //For dashboard page to prevent unnecessary loading when no profile changed
  useEffect(() => {
    if (JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) || isFirstRender?.current) {
      getTopProductList();
      previousAdvertiser.current = commonAdvertiser;
      isFirstRender.current = false;
    }
  }, [commonAdvertiser]);

  const getTopProductListWithPagination = (page, advertiserFilter, commonDateRange = { startDate: '', endDate: '' }) => {
    let request = {
      changedColumn: sortBy?.length > 0 ? sortBy : '',
      advertiserFilter,
      commonDateRange
    };

    if (params?.token) {
      request = { ...request, secretKey: params.token };
    }

    dispatch(GetTopProductData(request));
  };

  //I commented below function because i remove Server Side Pagination.
  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage);
  //   if (params?.token) {
  //     getTopProductListWithPagination(newPage, selectedDashboardAdvertiser, dashboardDateRange);
  //   } else {
  //     const advertiserFilter = commonAdvertiser.length > 0 ? commonAdvertiser : '';
  //     getTopProductListWithPagination(newPage, advertiserFilter, commonDateRange);
  //   }
  // };

  const options = {
    filter: false,
    print: false,
    viewColumns: false,
    align: 'center',
    responsive: false,
    search: false,
    count: TopProductData?.length || 0,
    page: page,
    rowsPerPage: 9,
    download: false,
    pagination: true,
    serverSide: false,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: (
          <Box p={2} display="flex" justifyContent="center">
            Sorry, no matching records found
          </Box>
        )
      }
    },
    // onTableChange: (action, tableState) => {
    //   if (action === 'changePage') {
    //     handlePageChange(null, tableState.page);
    //   }
    // },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={(event, newPage) => changePage(newPage)}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]} // Removes the rows per page dropdown
          onRowsPerPageChange={() => {}}
          labelRowsPerPage="" // Hides the "Rows per page:" text
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`} // Custom displayed rows text
        />
      );
    }
  };

  const column = [
    {
      label: 'Item',
      name: 'item_id',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            textAlign: 'left'
          }
        }),
        customBodyRender: (value, rowData) => {
          const data = TopProductData?.[rowData?.rowIndex];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {data?.item_image ? (
                <Box className="top-product-img">
                  <img src={data?.item_image} alt="top-product-img" />
                </Box>
              ) : (
                <Box className="top-product-img">
                  <img src={TopProductDefaultImage} alt="top-product-img" />
                </Box>
              )}
              <Box>
                <Typography className="top-product-item-title">
                  {data?.item_name?.length > 40 ? (
                    <Tooltip
                      placement="top"
                      sx={{ fontSize: '10px' }}
                      title={
                        <Typography variant="body1" sx={{ fontSize: 16 }}>
                          {data?.item_name}
                        </Typography>
                      }
                      arrow
                    >
                      {data?.item_name?.substring(0, 40)}...
                    </Tooltip>
                  ) : (
                    data?.item_name
                  )}
                </Typography>

                {data?.item_id ? (
                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id?.replace(/,/g, '')}`, '_blank')}
                  >
                    <Typography className="top-product-item-title">{data?.item_id?.replace(/,/g, '')}</Typography>
                  </Link>
                ) : (
                  <Typography sx={{ fontSize: '10px' }}>-------</Typography>
                )}
              </Box>
            </Box>
          );
        }
      }
    },
    {
      label: 'Sales',
      name: 'ad_sales',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: 'table-align-right'
        })
      }
    },
    {
      label: 'Spend',
      name: 'ad_spend',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        setCellHeaderProps: () => ({
          className: 'table-align-right'
        })
      }
    },
    {
      label: 'RoAS',
      name: 'roas',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: 'table-align-right'
        })
      }
    },
    {
      label: 'ACoS',
      name: 'acos',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: 'table-align-right'
        })
      }
    },
    {
      label: 'Units Sold',
      name: 'units_sold',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: 'table-align-right'
        })
      }
    }
  ];

  return (
    <Box
      className={params?.token ? 'white-paper-card-dashboard chart-card' : 'white-paper-card-dashboard table-card'}
      sx={{
        ...((isLoading || isNoDataFound || commonAdvertiser?.length <= 0 || selectedDashboardAdvertiser?.length <= 0) && {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        })
      }}
    >
      {commonAdvertiser?.length <= 0 && selectedDashboardAdvertiser?.length <= 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography className="no-advertiser">Please select atleast one advertiser.</Typography>
        </Box>
      ) : isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={20} color="inherit" />
        </Box>
      ) : isNoDataFound ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography>No data found</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box mb={1.5}>
              <Typography className="dashboard-card-title">Top Products</Typography>
              <Typography className="dashboard-card-subtitle">&nbsp;</Typography>
            </Box>
            <Box display={'flex'}>
              {(commonAdvertiser?.length > 0 || selectedDashboardAdvertiser?.length > 0) && newData?.length > 0 && (
                <Box mr={1}>
                  <Button sx={{ p: '0px !important', minWidth: '45px' }} onClick={handleDownloadCsv}>
                    <Tooltip arrow title="Download">
                      <img src={downloadIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />
                    </Tooltip>
                  </Button>
                </Box>
              )}
              <Box>
                <FormControl className="filter-select" sx={{ minWidth: '100px !important' }}>
                  <Select
                    value={sortBy}
                    onChange={handleChange}
                    size="small"
                    className="ad-performance-select"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem className="top-product-value-dropdown" value="ad_sales">
                      Sales
                    </MenuItem>
                    <MenuItem className="top-product-value-dropdown" value="ad_spend">
                      Spend
                    </MenuItem>
                    <MenuItem className="top-product-value-dropdown" value="roas">
                      RoAS
                    </MenuItem>
                    <MenuItem className="top-product-value-dropdown" value="acos">
                      ACoS
                    </MenuItem>
                    <MenuItem className="top-product-value-dropdown" value="units_sold">
                      Units Sold
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          {(commonAdvertiser?.length > 0 || selectedDashboardAdvertiser?.length > 0) && (
            <Box mt={1}>
              <MUIDataTable className="top-product-table pnl-by-item" data={newData} columns={column} options={options} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TopProductDashboard;
