import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import { notificationFail } from 'store/slices/notificationSlice';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { postUploadMedia } from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';
import UploadCaptionFile from './uploadCaptionFile';
import { IconUpload } from '@tabler/icons';

export default function UploadMediaModal({ onClose }) {
  const { allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCaptionFile, setSelectedCaptionFile] = useState(null);
  const dispatch = useAppDispatch();

  const selectedProfiles = localStorage.getItem('selectedProfiles');

  //Auto select First Profile while page load and we have allUserDetails
  useEffect(() => {
    if (allUserDetails?.length) {
      if (selectedProfiles) {
        const selectedProfileFirstId = selectedProfiles?.split(',')?.pop();
        setValue('advertiser', selectedProfileFirstId, { shouldValidate: true });
      } else {
        setValue('advertiser', null);
      }
    }
  }, [allUserDetails, selectedProfiles]);

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const formSchema = Yup.object().shape({
    advertiser: Yup.string().required('Advertiser is required'),
    name: Yup.string().required('Name is required'),
    video: Yup.string().required('Video is required')
  });

  const {
    register,
    handleSubmit,
    formState: { errors },

    control,
    // watch,
    setValue
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
      advertiser: '',
      video: ''
    }
  });

  const { getRootProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    maxSize: 522752000,
    accept: {
      'video/*': ['.mp4', '.mov']
    },
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];

        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.preload = 'metadata';
        video.addEventListener('loadedmetadata', () => {
          // const videoCodec = Array.from(video?.webkitTracks || []).find(
          //     (track) => track.kind === 'video'
          // )?.codec;

          // const codec = video?.currentSrc?.split('.').pop(); // Extract codec from the video URL

          // Retrieve audio codec
          // const audioCodec = Array.from(video?.webkitTracks || []).find(
          //     (track) => track.kind === 'audio'
          // )?.codec;

          const durationInSeconds = video.duration;

          const { videoWidth, videoHeight } = video;
          const aspectRatio = videoWidth / videoHeight;

          if ((videoWidth === 1920 && videoHeight === 1080) || (videoWidth === 3840 && videoHeight === 2160)) {
            if (aspectRatio == 16 / 9) {
              if (durationInSeconds >= 5 && durationInSeconds <= 45) {
                setSelectedVideo(file);
                setValue('video', URL.createObjectURL(file));
              } else {
                dispatch(notificationFail(`The duration must be 5-45 seconds, current video length is ${durationInSeconds}`));
              }
            } else {
              dispatch(notificationFail('The aspect ratio should be 16:9.'));
            }
          } else {
            dispatch(notificationFail('The dimensions must be 1920×1080px - 3840×2160px.'));
          }
        });
      }
    },
    onDropRejected: () => {
      dispatch(notificationFail('Brand logo image file size should be ≤ 200kb and format can be png.'));
    }
  });

  const onButtonClick = (event) => {
    setLoading(true);
    let formData = new FormData();
    formData.append('name', event.name);
    formData.append('media_type', 'video');
    formData.append('advertiser', event.advertiser);
    formData.append('video', selectedVideo);
    if (selectedCaptionFile) {
      formData.append('caption', selectedCaptionFile);
    }

    dispatch(postUploadMedia({ formData })).then((res) => {
      setLoading(false);
      if (res?.payload?.status == 201) {
        onClose(true);
      }
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onButtonClick)}>
      <DialogContent dividers>
        <Box sx={{ mt: 2, mb: 2 }}>
          {' '}
          <Grid container spacing={2}>
            <Grid item sm={12}>
              {/* {renderLabel('Profile', true)} */}
              <Controller
                name="advertiser"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...register('advertiser')}
                    {...field}
                    value={allUserDetails.find((obj) => obj.id == field.value) || null}
                    onChange={(e, value) => (value ? field.onChange(value?.id) : field.onChange(''))}
                    options={allUserDetails || []}
                    getOptionLabel={(option) => {
                      return option?.name || '';
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Profile"
                        error={Boolean(errors.advertiser)}
                        helperText={errors.advertiser?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              {/* {renderLabel('Media Name', true)} */}
              <Controller
                name={'name'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...register('name')}
                    {...field}
                    name={'name'}
                    fullWidth
                    type="text"
                    label="Media Name"
                    variant="outlined"
                    error={errors?.name}
                    helperText={errors?.name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Video', true)}
              <Button variant="contained" {...getRootProps({ className: 'dropzone' })}>
                <IconUpload sx={{ cursor: 'pointer' }} /> Upload
              </Button>

              {selectedVideo && (
                <Box sx={{ display: 'flex', mt: 1, alignItems: 'center' }}>
                  <Typography>{selectedVideo?.name}</Typography>
                  <IconButton
                    onClick={() => {
                      setSelectedVideo(null);
                      setValue('video', null);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
              {errors.video && (
                <FormHelperText error sx={{ margin: '10px 0px' }}>
                  {errors.video.message}
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Closed Caption File', false)}
              <UploadCaptionFile setValue={setValue} setSelectedCaptionFile={setSelectedCaptionFile} />
              {/* <Button variant="contained"  {...getRootProps({ className: 'dropzone' })}>Upload</Button> */}

              {selectedCaptionFile && (
                <Box sx={{ display: 'flex', mt: 1, alignItems: 'center' }}>
                  <Typography>{selectedCaptionFile?.name}</Typography>
                  <IconButton
                    onClick={() => {
                      setSelectedCaptionFile(null);
                      setValue('caption', URL.createObjectURL(acceptedFiles[0]));
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
              {/* {errors.video && <FormHelperText error sx={{ margin: "10px 0px" }}>{errors.video.message}</FormHelperText>}  */}
            </Grid>

            <Box>
              <List
                sx={{
                  listStyleType: 'disc',
                  listStylePosition: 'inside'
                }}
              >
                <Typography variant="h4" sx={{ padding: '8px 14px' }}>
                  Video specs
                </Typography>
                <ListItem sx={{ display: 'list-item' }}>The file size must be ≤ 500mb</ListItem>
                <ListItem sx={{ display: 'list-item' }}>The file format must be .MP4 or .MOV</ListItem>
                <ListItem sx={{ display: 'list-item' }}>The aspect ratio should be 16:9</ListItem>
                <ListItem sx={{ display: 'list-item' }}>The dimensions must be 1920×1080px - 3840×2160px</ListItem>
                <ListItem sx={{ display: 'list-item' }}>The duration must be 5-45 seconds</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Video codec must be H.264 or H.265</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Audio codec must be AAC,MP3,PCM</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Caption file format should be .srt or .vtt</ListItem>
              </List>
            </Box>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" sx={{ ml: 2 }} disabled={loading}>
          {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}Ok
        </Button>
      </DialogActions>
    </form>
  );
}
