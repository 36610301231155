import { Step, StepConnector, StepLabel, Stepper } from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo, useState, useEffect } from 'react';
import StepperCustomDot from 'ui-component/StepperCustomDot';
// import MainCard from 'ui-component/cards/MainCard';
import CampaignSettingStep from './campaignSettingsStep';
import ProductAdGroupSetting from './adGroupSettingStep';
import CampaignContext from 'context';
import TargetAdGroupSetting from './targetSettingStep';
import dayjs from 'dayjs';
import CompleteStep from './completeStep';
import { CAMPAIGN } from 'constant/campaignConstant';

const CreateProduct = () => {
  const steps = ['Campaign Setting', 'Adgroup Setting', 'Complete'];

  const [activeStep, setActiveStep] = useState(1);
  const [stepsArray, setStepArray] = useState(steps);
  const [searchableData, setSearchableData] = useState([]);
  const [dateType, setDateType] = useState('select');
  const [selectedData, setSelectedData] = useState([]);
  const [selectedKeywordData, setSelectedKeywordData] = useState([]);
  const [campaignNameManually, setCampaignNameManually] = useState(true);
  const [campaign, setCampaign] = useState({
    budget_type: 'daily',
    campaign_type: 'sponsoredProducts',
    targeting_type: CAMPAIGN.TARGETING_TYPE.BOTH,
    bidding_strategy: CAMPAIGN.BIDDING_STRATEGY.FIXED,
    rollover: true,
    is_search_ingrid: true,
    is_others: true,
    is_item_buybox: true,
    is_home_page: true,
    is_stock_up: true,
    is_search_carousel: false,
    is_item_carousel: false,
    placement_bid_search_ingrid: null,
    placement_bid_item_buybox: null,
    placement_bid_home_page: null,
    placement_bid_stock_up: null,
    platform_bid_desktop: null,
    platform_bid_app: null,
    platform_bid_mobile: null,
    name: '',
    advertiser: '',
    daily_budget: '',
    total_budget: '',
    start_date: dayjs(new Date()).format('YYYY-MM-DD'),
    end_date: ''
  });
  const [autoCampaign, setAutoCampaign] = useState({
    budget_type: 'daily',
    campaign_type: 'sponsoredProducts',
    targeting_type: CAMPAIGN.TARGETING_TYPE.AUTO,
    bidding_strategy: CAMPAIGN.BIDDING_STRATEGY.FIXED,
    rollover: true,
    is_search_ingrid: true,
    is_others: true,
    is_item_buybox: true,
    is_home_page: true,
    is_stock_up: true,
    is_search_carousel: false,
    is_item_carousel: false,
    placement_bid_search_ingrid: null,
    placement_bid_item_buybox: null,
    placement_bid_home_page: null,
    placement_bid_stock_up: null,
    platform_bid_desktop: null,
    platform_bid_app: null,
    platform_bid_mobile: null,
    name: '',
    advertiser: '',
    daily_budget: '',
    total_budget: '',
    start_date: dayjs(new Date()).format('YYYY-MM-DD'),
    end_date: ''
  });
  const [manualCampaign, setManualCampaign] = useState({
    budget_type: 'daily',
    campaign_type: 'sponsoredProducts',
    targeting_type: CAMPAIGN.TARGETING_TYPE.MANUAL,
    bidding_strategy: CAMPAIGN.BIDDING_STRATEGY.FIXED,
    rollover: true,
    is_search_ingrid: true,
    is_others: true,
    is_item_buybox: true,
    is_home_page: true,
    is_stock_up: true,
    is_search_carousel: false,
    is_item_carousel: false,
    placement_bid_search_ingrid: null,
    placement_bid_item_buybox: null,
    placement_bid_home_page: null,
    placement_bid_stock_up: null,
    platform_bid_desktop: null,
    platform_bid_app: null,
    platform_bid_mobile: null,
    name: '',
    advertiser: '',
    daily_budget: '',
    total_budget: '',
    start_date: dayjs(new Date()).format('YYYY-MM-DD'),
    end_date: ''
  });
  const [campaignData, setCampaignData] = useState({
    campaign: {},
    autoCampaign: {},
    manualCampaign: {},
    adgroup: {},
    autoAdgroup: {},
    manualAdgroup: {},
    aditem: {},
    autoAditem: {},
    manualAditem: {}
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const project = () => {
    switch (activeStep) {
      case 1:
        return <CampaignSettingStep />;
      case 2:
        return <ProductAdGroupSetting />;
      case 3:
        if (
          campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL ||
          campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.BOTH
        ) {
          return <TargetAdGroupSetting />;
        } else {
          return <CompleteStep />;
        }
      case 4:
        return <CompleteStep />;
      default:
        return <h1>No project match</h1>;
    }
  };

  const handleForwordButtonClick = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBackwordButtonClick = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    if (campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL || campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.BOTH) {
      setStepArray(['Campaign Setting', 'Adgroup Setting', 'Target Setting', 'Complete']);
    } else {
      setStepArray(['Campaign Setting', 'Adgroup Setting', 'Complete']);
    }
  }, [campaign?.targeting_type]);

  // Memoize the context value to avoid re-creating the object on every render
  const contextValue = useMemo(
    () => ({
      campaign,
      setCampaign,
      autoCampaign,
      setAutoCampaign,
      manualCampaign,
      setManualCampaign,
      campaignData,
      setCampaignData,
      handleForwordButtonClick,
      handleBackwordButtonClick,
      searchableData,
      setSearchableData,
      dateType,
      setDateType,
      selectedData,
      setSelectedData,
      selectedKeywordData,
      setSelectedKeywordData,
      setActiveStep,
      campaignNameManually,
      setCampaignNameManually
    }),
    [
      campaign,
      setCampaign,
      autoCampaign,
      setAutoCampaign,
      manualCampaign,
      setManualCampaign,
      campaignData,
      setCampaignData,
      handleForwordButtonClick,
      handleBackwordButtonClick,
      searchableData,
      setSearchableData,
      dateType,
      setDateType,
      selectedData,
      setSelectedData,
      selectedKeywordData,
      setSelectedKeywordData,
      setActiveStep,
      campaignNameManually,
      setCampaignNameManually
    ]
  );

  return (
    // <MainCard title="Campaign List">
    <CampaignContext.Provider value={contextValue}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} alternativeLabel connector={<StepConnector sx={{ marginTop: 0.7 }} />}>
          {stepsArray.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepperCustomDot}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box>
        <Box sx={{ height: 'auto', width: '100%' }}>{project()}</Box>
      </Box>
    </CampaignContext.Provider>
    // </MainCard>
  );
};

export default CreateProduct;
