import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  FormHelperText,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  Link,
  Tooltip,
  Checkbox,
  Menu,
  MenuItem
} from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import CheckIcon from '@mui/icons-material/Check';
import SetBid from 'ui-component/bids/modal/setBid';
import { useRef } from 'react';
import { Box } from '@mui/system';
import React from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
import SearchTable from './searchTable';
import SelectFromTable from './selectFromTable';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { getAdgorupCampaignList, postMultipleAditem } from 'store/thunk/userThunk';
import EnterKeywordTable from './enterItemTable';
import { CAMPAIGN } from 'constant/campaignConstant';
import { PageValue } from 'store/constant';
import SuggestFromTable from '../suggestedTable';
import { MIN_ITEM_BID_LIMIT, MAX_ITEM_BID_LIMIT } from 'store/constant';

const ProductAdGroupSetting = (props) => {
  const { itemModal, onClose, GetApi } = props;
  const { selectdItemData, adgroupList } = useAppSelector((state) => state?.userDetailsSlice);
  const [value, setValue] = useState('2');
  const [loading, setLoading] = useState(false);
  const [searchableData, setSearchableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedAdgroup, setSelectedAdgroup] = useState([]);
  const [keyword, setkeyword] = useState('');
  const [error, setError] = useState({ adGroup: false, adItem: false });

  const [isModalSetBid, setIsModalSetBid] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectMode, setSelectMode] = useState('all');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isFirstRender = useRef(true);

  const dispatch = useAppDispatch();

  //When selecting Select All, Select Current mode
  useEffect(() => {
    // Skip first render because to prevent automatically select all selected option at first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleSelectAll('switchMode'); //For perform checkbox selection
  }, [selectMode]);

  //When selecting single Checkbox
  const handleRowSelection = (index) => {
    if (selectedArray.includes(index)) {
      setSelectedArray(selectedArray.filter((i) => i !== index));
    } else {
      setSelectedArray([...selectedArray, index]);
    }
  };

  //When click on Select All, Select Current or Select All checkbox
  const handleSelectAll = (mode = '') => {
    if (selectMode === 'all') {
      if (isAllSelected() && mode === 'globalCheck') {
        setSelectedArray([]); // Unselect all
      } else {
        setSelectedArray(selectedData.map((_, index) => index)); // Select all rows
      }
    } else {
      if (isAllSelected() && mode === '') {
        setSelectedArray([]); // Unselect all
      } else {
        const startIndex = currentPage * rowsPerPage;
        // setSelectedArray([...Array(rowsPerPage).keys()].map((i) => i + startIndex)); // Select only current page rows

        // Ensure selected indexes do not exceed selectedData.length
        const endIndex = Math.min(startIndex + rowsPerPage, selectedData.length);
        setSelectedArray([...Array(endIndex - startIndex).keys()].map((i) => i + startIndex));
      }
    }
  };

  // Function to check if all rows (or current page rows) are selected
  const isAllSelected = () => {
    if (selectMode === 'all') {
      return selectedArray.length === selectedData.length;
    } else {
      const startIndex = currentPage * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, selectedData.length);
      // Restrict indexes to prevent out-of-bounds selection
      const currentPageIndexes = [...Array(endIndex - startIndex).keys()]?.map((i) => i + startIndex);
      return currentPageIndexes?.every((index) => selectedArray?.includes(index));
    }
  };

  //On click of Arrow Click
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //To close open Menu Items
  const handleClose = () => {
    setAnchorEl(null);
  };

  //While selecting any menu items options
  const handleSelect = (mode) => {
    setSelectMode(mode);
    handleClose(); // Close the menu after selection
  };

  //Toggle Set Bid Modal
  const handleToggleSetBid = () => {
    setIsModalSetBid(!isModalSetBid);
  };

  //Apply Bid
  const handleApplyBid = (bidValue) => {
    const updatedKeywordData = selectedData.map((item, index) =>
      selectedArray.includes(index) ? { ...item, bid_amount: bidValue } : item
    );
    setSelectedData(updatedKeywordData);
    setSelectedArray([]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedAdgroup?.length > 0 && error.adGroup == true) {
      setError({ ...error, adGroup: false });
    }
  }, [selectedAdgroup]);

  useEffect(() => {
    if (selectedData?.length > 0 && error.adItem == true) {
      setError({ ...error, adItem: false });
    }
  }, [selectedData]);

  useEffect(() => {
    const id = itemModal.id;
    if (id) {
      dispatch(getAdgorupCampaignList({ id }));
    }
  }, [itemModal]);

  //Auto selected Adgroup Name
  useEffect(() => {
    if (adgroupList?.length && adgroupList?.length === 1) {
      setSelectedAdgroup(adgroupList);
    } else {
      //Select Particular Adgroup if we have more adgroups and we have particular adgroup_id
      if (itemModal?.adgroup_id) {
        const getAdgroup = adgroupList?.filter((l) => l?.adgroup_id === itemModal?.adgroup_id);
        setSelectedAdgroup(getAdgroup);
      }
    }
  }, [adgroupList]);

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const onButtonClick = (event) => {
    event.preventDefault();

    if (selectedAdgroup?.length > 0 && selectedData?.length > 0) {
      setLoading(true);
      const temp = selectedData?.map((item) => {
        return { item_id: item?.item_id, bid_amount: item.bid_amount ? item.bid_amount : MIN_ITEM_BID_LIMIT };
      });
      var adgroupIds = [];
      selectedAdgroup?.map((item) => {
        adgroupIds.push(item?.id);
      });

      const _request = {
        adgroup_id: adgroupIds,
        campaign_id: itemModal.id,
        aditems: temp
      };

      dispatch(postMultipleAditem({ _request })).then(() => {
        setLoading(false);
        onClose();
        GetApi();
      });
    } else {
      setError({ adGroup: selectedAdgroup?.length > 0 ? false : true, adItem: selectedData?.length > 0 ? false : true });
    }
  };

  const onAddAllClick = () => {
    var tempArray = [...selectedData];
    if (searchableData?.length >= 1) {
      searchableData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: MIN_ITEM_BID_LIMIT,
            suggested_bid: item?.suggested_bid
          });
        }
      });
    } else {
      selectdItemData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: MIN_ITEM_BID_LIMIT,
            suggested_bid: item?.suggested_bid
          });
        }
      });
    }
    setSelectedData(tempArray);
  };

  const selectedColumns = [
    ...(itemModal?.targeting_type !== CAMPAIGN.TARGETING_TYPE.MANUAL
      ? [
          {
            name: 'select',
            label: (
              <Checkbox
                checked={isAllSelected()} // Fix: Properly checks/unchecks
                onChange={handleSelectAll}
                indeterminate={selectedArray.length > 0 && !isAllSelected()} // Shows partial selection state
              />
            ),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta) => {
                const rowIndex = tableMeta.rowIndex;
                return <Checkbox checked={selectedArray.includes(rowIndex)} onChange={() => handleRowSelection(rowIndex)} />;
              },
              customHeadRender: () => (
                <th style={{ borderBottom: '1px solid #e0e0e0', borderRight: '1px solid #E8E8E8' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', ml: '17px' }}>
                    <Checkbox
                      checked={selectedArray.length > 0}
                      // onChange={handleSelectAll}
                      onChange={() => handleSelectAll(selectMode === 'current' ? '' : 'globalCheck')}
                    />
                    <Tooltip title="Select Mode">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        // sx={{ ml: 2 }}
                        aria-controls={open ? 'select-mode-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Tooltip>

                    <Menu
                      anchorEl={anchorEl}
                      id="select-mode-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem onClick={() => handleSelect('all')}>
                        {selectMode === 'all' && <CheckIcon fontSize="small" sx={{ mr: 1 }} />}
                        <Typography>Select All</Typography>
                      </MenuItem>
                      <MenuItem onClick={() => handleSelect('current')}>
                        {selectMode === 'current' && <CheckIcon fontSize="small" sx={{ mr: 1 }} />}
                        <Typography>Select Current</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </th>
              )
            }
          }
        ]
      : []),
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {data?.product_name?.length > 20 ? (
                    <>
                      <Tooltip
                        placement="top"
                        sx={{ fontSize: '10px' }}
                        title={
                          <Typography variant="body1" sx={{ fontSize: 16 }}>
                            {data?.product_name}
                          </Typography>
                        }
                        arrow
                      >
                        {data?.product_name?.substring(0, 25)}...
                      </Tooltip>
                    </>
                  ) : (
                    data?.product_name
                  )}

                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                  </Link>
                </Box>
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bid_amount',
      label: 'bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let data = selectedData[tableMeta?.rowIndex];
          return (
            <>
              <TextField
                size="small"
                value={data?.bid_amount}
                type="number"
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault(); // Prevent form submission
                  }
                }}
                onBlur={(e) => {
                  const value = Number(e.target.value);
                  const newPrice =
                    value < Number(MIN_ITEM_BID_LIMIT)
                      ? MIN_ITEM_BID_LIMIT
                      : value > Number(MAX_ITEM_BID_LIMIT)
                      ? MAX_ITEM_BID_LIMIT
                      : value;

                  if (newPrice !== value) {
                    const updatedData = [...selectedData];
                    updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                    setSelectedData(updatedData);
                  }
                }}
                onChange={(e) => {
                  const newPrice = Number(e.target.value == 0) ? null : Number(e.target.value);
                  const updatedData = [...selectedData];
                  updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                  setSelectedData(updatedData);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                inputProps={{
                  step: '0.01'
                }}
              />
            </>
          );
        }
      }
    },
    {
      name: 'item_id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <IconButton
              onClick={() => {
                var tempData = [...selectedData];
                tempData = tempData?.filter((item) => item?.item_id != value);
                setSelectedData(tempData);
                //Adjust selected array index
                const removedIndex = tableMeta?.rowIndex;
                if (selectedArray.includes(removedIndex)) {
                  const updatedArray = selectedArray
                    .filter((item) => item !== removedIndex)
                    .map((item) => (item > removedIndex ? item - 1 : item));
                  setSelectedArray(updatedArray);
                } else {
                  const updatedArray = selectedArray.map((item) => (item > removedIndex ? item - 1 : item));
                  setSelectedArray(updatedArray);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={!selectedData?.length}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                setSelectedData([]);
                setSelectedArray([]);
              }}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    viewColumns: false,
    serverSide: false,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    rowsPerPage: rowsPerPage,
    toolbar: false,
    search: false,
    onChangePage: (newPage) => {
      setCurrentPage(newPage);
    },
    onChangeRowsPerPage: (row) => {
      setRowsPerPage(row);
      setSelectedArray([]); // Unselect all
    }
  };

  return (
    <>
      <form noValidate onSubmit={onButtonClick}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item sm={12}>
              <Typography variant="h4">Campaign: {itemModal?.name}</Typography>
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Adgroup Name', true)}

              <>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  name="adgroup_name"
                  options={adgroupList}
                  getOptionLabel={(option) => option.name}
                  value={selectedAdgroup}
                  onChange={(e, value) => {
                    setSelectedAdgroup(value);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField error={error.adGroup} color="secondary" {...params} placeholder="Adgroup Name" />
                  )}
                />
                {error.adGroup && (
                  <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                    Please select adgroup
                  </FormHelperText>
                )}
              </>
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Item', true)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Search" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Select from" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="3" />
                        <Tab label="Enter Items" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SearchTable
                        selectedData={selectedData}
                        advertiserId={setSelectedData}
                        advertiser_id={itemModal?.advertiser_id}
                      />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <SelectFromTable
                        setSearchableData={setSearchableData}
                        advertiserId={itemModal?.advertiser_id}
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        onAddAllClick={onAddAllClick}
                      />
                    </TabPanel>
                    <TabPanel value="3">
                      <SuggestFromTable
                        setSearchableData={setSearchableData}
                        advertiserId={itemModal?.advertiser_id}
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        onAddAllClick={onAddAllClick}
                      />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: 0 }}>
                      <EnterKeywordTable
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        keyword={keyword}
                        setkeyword={setkeyword}
                        advertiserId={itemModal?.advertiser_id}
                      />
                    </TabPanel>
                  </Box>
                </TabContext>

                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      padding: '20px 30px 20px 30px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box display={'flex'} alignItems="center" gap={3}>
                      {itemModal?.targeting_type !== CAMPAIGN.TARGETING_TYPE.MANUAL && (
                        <Button
                          variant="outlined"
                          disabled={selectedArray?.length > 0 ? false : true}
                          onClick={handleToggleSetBid}
                        >
                          Set Bid
                        </Button>
                      )}
                      <Typography variant="h5">{selectedData?.length} Item(s) Added</Typography>
                      {selectedArray?.length > 0 && (
                        <Typography variant="h5">{selectedArray?.length} Item(s) Selected</Typography>
                      )}
                    </Box>
                    <Typography variant="h6" sx={{ color: colors.grey500 }}>
                      Item bid is only valid for Auto Campaigns
                    </Typography>
                  </Box>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      sx={{ padding: 0 }}
                      data={selectedData}
                      // columns={selectedColumns}
                      columns={
                        itemModal?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL
                          ? selectedColumns?.filter((item) => item?.name != 'bid_amount' && item?.name != 'suggested_bid')
                          : selectedColumns
                      }
                      options={selectedOptions}
                      className="pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>
              {error.adItem && (
                <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                  Please select at lease one items
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={onClose}>
                CANCEL
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit" disabled={loading}>
                {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
                Ok
              </Button>
            </>
          </Box>
        </Box>
      </form>
      {isModalSetBid && <SetBid isOpen={isModalSetBid} onClose={handleToggleSetBid} onSave={handleApplyBid} bidType={'ITEM'} />}
    </>
  );
};

export default ProductAdGroupSetting;
