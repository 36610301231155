import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  FormHelperText,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Tooltip,
  Checkbox,
  Menu,
  MenuItem
} from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import CheckIcon from '@mui/icons-material/Check';
import SetBid from 'ui-component/bids/modal/setBid';
import { Box } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
import SearchTable from './searchTable';
import SelectFromTable from './selectFromTable';
import MUIDataTable from 'mui-datatables';
import { useState, useContext, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import CampaignContext from 'context';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector, useAppDispatch } from 'store';
import { postCreateCampaign, updateCampaign, postCreateAdgroup, updateAdgroup, postCreateAditems } from 'store/thunk/userThunk';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EnterKeywordTable from './enterItemsTable';

import { CAMPAIGN } from 'constant/campaignConstant';
import { PageValue } from 'store/constant';
import SelectFromTableAdGroupSuggested from './suggestedTableAddCampaign';
import { useRef } from 'react';
import { MIN_ITEM_BID_LIMIT, MAX_ITEM_BID_LIMIT } from 'store/constant';

const ProductAdGroupSetting = () => {
  const {
    campaign,
    autoCampaign,
    manualCampaign,
    handleBackwordButtonClick,
    campaignData,
    setCampaignData,
    searchableData,
    selectedData,
    setSelectedData,
    handleForwordButtonClick
  } = useContext(CampaignContext);

  const navigate = useNavigate();

  const {
    selectdItemData,
    existingCampaignId,
    existingAutoCampaignId,
    existingManualCampaignId,
    existingAdgroupId,
    existingAutoAdgroupId,
    existingManualAdgroupId
  } = useAppSelector((state) => state?.userDetailsSlice);
  const [processStatus, setProcessStatus] = useState('created');
  const [processStatusFailed, setProcessStatusFailed] = useState('creation');
  const [value, setValue] = useState('2');
  const [error, setError] = useState(false);
  const [keyword, setkeyword] = useState('');
  const [list, setList] = useState({});
  const [autoList, setAutoList] = useState({});
  const [manualList, setManualList] = useState({});
  const [loading, setLoading] = useState({
    campaign: '',
    adgroup: '',
    item: '',
    autoCampaign: '',
    manualCampaign: '',
    autoAdgroup: '',
    manualAdgroup: '',
    autoItem: '',
    manualItem: ''
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const [isModalSetBid, setIsModalSetBid] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectMode, setSelectMode] = useState('all');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isFirstRender = useRef(true);

  const dispatch = useAppDispatch();

  //When selecting Select All, Select Current mode
  useEffect(() => {
    // Skip first render because to prevent automatically select all selected option at first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleSelectAll('switchMode'); //For perform checkbox selection
  }, [selectMode]);

  //When selecting single Checkbox
  const handleRowSelection = (index) => {
    if (selectedArray.includes(index)) {
      setSelectedArray(selectedArray.filter((i) => i !== index));
    } else {
      setSelectedArray([...selectedArray, index]);
    }
  };

  //When click on Select All, Select Current or Select All checkbox
  const handleSelectAll = (mode = '') => {
    if (selectMode === 'all') {
      if (isAllSelected() && mode === 'globalCheck') {
        setSelectedArray([]); // Unselect all
      } else {
        setSelectedArray(selectedData.map((_, index) => index)); // Select all rows
      }
    } else {
      if (isAllSelected() && mode === '') {
        setSelectedArray([]); // Unselect all
      } else {
        const startIndex = currentPage * rowsPerPage;
        // setSelectedArray([...Array(rowsPerPage).keys()].map((i) => i + startIndex)); // Select only current page rows

        // Ensure selected indexes do not exceed selectedData.length
        const endIndex = Math.min(startIndex + rowsPerPage, selectedData.length);
        setSelectedArray([...Array(endIndex - startIndex).keys()].map((i) => i + startIndex));
      }
    }
  };

  // Function to check if all rows (or current page rows) are selected
  const isAllSelected = () => {
    if (selectMode === 'all') {
      return selectedArray.length === selectedData.length;
    } else {
      const startIndex = currentPage * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, selectedData.length);
      // Restrict indexes to prevent out-of-bounds selection
      const currentPageIndexes = [...Array(endIndex - startIndex).keys()]?.map((i) => i + startIndex);
      return currentPageIndexes?.every((index) => selectedArray?.includes(index));
    }
  };

  //On click of Arrow Click
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //To close open Menu Items
  const handleClose = () => {
    setAnchorEl(null);
  };

  //While selecting any menu items options
  const handleSelect = (mode) => {
    setSelectMode(mode);
    handleClose(); // Close the menu after selection
  };

  //Toggle Set Bid Modal
  const handleToggleSetBid = () => {
    setIsModalSetBid(!isModalSetBid);
  };

  //Apply Bid
  const handleApplyBid = (bidValue) => {
    const updatedKeywordData = selectedData.map((item, index) =>
      selectedArray.includes(index) ? { ...item, bid_amount: bidValue } : item
    );
    setSelectedData(updatedKeywordData);
    setSelectedArray([]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Reset Error When user select item
  useEffect(() => {
    if (selectedData?.length) {
      setError(false);
    }
  }, [selectedData]);

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const formSchema = Yup.object().shape({
    adgroup_name: Yup.string().required('Adgroup name is required')
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
    defaultValues: {
      adgroup_name: campaignData?.adgroup ? campaignData?.adgroup?.name : ''
    }
  });

  const adgorupNameValue = watch('adgroup_name');

  const onButtonClick = async (event) => {
    let bothCampaigns = [];

    //User must need to select at least one product to proceed
    if (!selectedData?.length) {
      setError(true);
      return;
    }

    if (campaign?.targeting_type === 'both') {
      setCampaignData((prevState) => ({
        ...prevState,
        adgroup: { name: event?.adgroup_name }
      }));

      bothCampaigns.push(autoCampaign);
      bothCampaigns.push(manualCampaign);
    } else {
      bothCampaigns.push(campaignData?.campaign);
    }

    setList({});
    setAutoList({});
    setManualList({});

    const temp = selectedData?.map((item) => {
      return { item_id: item?.item_id, bid_amount: item.bid_amount ? item.bid_amount : MIN_ITEM_BID_LIMIT };
    });

    if (bothCampaigns?.length > 0) {
      for (let campaignObj of bothCampaigns) {
        let adgroupName = '';

        if (campaign?.targeting_type === 'both') {
          if (campaignObj?.targeting_type === 'auto') {
            adgroupName = `${event?.adgroup_name}-A`;

            setCampaignData((prevState) => ({
              ...prevState,
              autoAdgroup: { name: adgroupName }
            }));

            setLoading((prevState) => ({
              ...prevState,
              autoCampaign: 'process'
            }));
          } else if (campaignObj?.targeting_type === 'manual') {
            adgroupName = `${event?.adgroup_name}-M`;
            setCampaignData((prevState) => ({ ...prevState, manualAdgroup: { name: adgroupName } }));
            setLoading((prevState) => ({
              ...prevState,
              manualCampaign: 'process'
            }));
          }
        } else {
          adgroupName = event?.adgroup_name;
          setCampaignData((prevState) => ({ ...prevState, adgroup: { name: adgroupName } }));
          setLoading((prevState) => ({
            ...prevState,
            campaign: 'process' // Same for other API calls
          }));
        }

        setDialogOpen(true);
        let _request = campaignObj;
        if (campaign?.targeting_type === 'both') {
          _request = { ..._request, type: campaignObj?.targeting_type };
        }
        let res = null;
        if (campaign?.targeting_type === 'both') {
          if (existingAutoCampaignId || existingManualCampaignId) {
            setProcessStatus('updated');
            setProcessStatusFailed('updation');
          }
          if (campaignObj?.targeting_type === 'auto') {
            res = await dispatch(
              existingAutoCampaignId
                ? updateCampaign({ _request, existingCampaignId: existingAutoCampaignId })
                : postCreateCampaign({ _request })
            );
          } else if (campaignObj?.targeting_type === 'manual') {
            res = await dispatch(
              existingManualCampaignId
                ? updateCampaign({ _request, existingCampaignId: existingManualCampaignId })
                : postCreateCampaign({ _request })
            );
          }
        } else {
          if (existingCampaignId) {
            setProcessStatus('updated');
            setProcessStatusFailed('updation');
          }
          res = await dispatch(
            existingCampaignId ? updateCampaign({ _request, existingCampaignId }) : postCreateCampaign({ _request })
          );
        }

        if (res?.payload?.status == 201 || res?.payload?.status == 200) {
          if (campaign?.targeting_type === 'both') {
            if (campaignObj?.targeting_type === 'auto') {
              setLoading((prevState) => ({
                ...prevState,
                autoCampaign: 'done',
                autoAdgroup: 'process'
              }));
            } else if (campaignObj?.targeting_type === 'manual') {
              setLoading((prevState) => ({
                ...prevState,
                manualCampaign: 'done',
                manualAdgroup: 'process'
              }));
            }
          } else {
            setLoading((prevState) => ({
              ...prevState,
              campaign: 'done',
              adgroup: 'process'
            }));
          }

          let _adgroupRequest = {
            name: adgroupName,
            status: 'enabled',
            campaign: res?.payload?.data?.data?.id
          };
          if (campaign?.targeting_type === 'both') {
            _adgroupRequest = { ..._adgroupRequest, type: campaignObj?.targeting_type };
          }
          let resAdgroup = null;
          if (campaign?.targeting_type === 'both') {
            if (campaignObj?.targeting_type === 'auto') {
              resAdgroup = await dispatch(
                existingAutoAdgroupId
                  ? updateAdgroup({ _adgroupRequest, existingAdgroupId: existingAutoAdgroupId })
                  : postCreateAdgroup({ _adgroupRequest })
              );
            } else if (campaignObj?.targeting_type === 'manual') {
              resAdgroup = await dispatch(
                existingManualAdgroupId
                  ? updateAdgroup({ _adgroupRequest, existingAdgroupId: existingManualAdgroupId })
                  : postCreateAdgroup({ _adgroupRequest })
              );
            }
          } else {
            resAdgroup = await dispatch(
              existingAdgroupId ? updateAdgroup({ _adgroupRequest, existingAdgroupId }) : postCreateAdgroup({ _adgroupRequest })
            );
          }

          if (resAdgroup?.payload?.status == 201 || resAdgroup?.payload?.status == 200) {
            if (campaign?.targeting_type === 'both') {
              if (campaignObj?.targeting_type === 'auto') {
                setLoading((prevState) => ({
                  ...prevState,
                  autoAdgroup: 'done',
                  autoItem: 'process'
                }));
              } else if (campaignObj?.targeting_type === 'manual') {
                setLoading((prevState) => ({
                  ...prevState,
                  manualAdgroup: 'done',
                  manualItem: 'process'
                }));
              }
            } else {
              setLoading((prevState) => ({
                ...prevState,
                campaign: 'done',
                adgroup: 'done',
                item: 'process'
              }));
            }

            const _aditemRequest = {
              campaign: resAdgroup?.payload?.data?.data?.campaign,
              adgroup: resAdgroup?.payload?.data?.data?.id,
              add_items: temp
            };
            const resAdItem = await dispatch(postCreateAditems({ _aditemRequest }));
            if (resAdItem?.payload?.status == 201) {
              if (campaign?.targeting_type === 'both') {
                if (campaignObj?.targeting_type === 'auto') {
                  setAutoList(resAdItem?.payload?.data);
                  setLoading((prevState) => ({
                    ...prevState,
                    autoItem: 'done'
                  }));
                } else if (campaignObj?.targeting_type === 'manual') {
                  setManualList(resAdItem?.payload?.data);
                  setLoading((prevState) => ({
                    ...prevState,
                    manualItem: 'done'
                  }));
                }
              } else {
                setList(resAdItem?.payload?.data);
                setLoading((prevState) => ({
                  ...prevState,
                  campaign: 'done',
                  adgroup: 'done',
                  item: 'done'
                }));
              }
            } else {
              if (campaign?.targeting_type === 'both') {
                if (campaignObj?.targeting_type === 'auto') {
                  setLoading((prevState) => ({
                    ...prevState,
                    autoItem: 'error'
                  }));
                } else if (campaignObj?.targeting_type === 'manual') {
                  setLoading((prevState) => ({
                    ...prevState,
                    manualItem: 'error'
                  }));
                }
              } else {
                setLoading((prevState) => ({
                  ...prevState,
                  campaign: 'done',
                  adgroup: 'done',
                  item: 'error'
                }));
              }
            }
          } else {
            if (campaign?.targeting_type === 'both') {
              if (campaignObj?.targeting_type === 'auto') {
                setLoading((prevState) => ({
                  ...prevState,
                  autoAdgroup: 'error',
                  autoItem: 'error'
                }));
              } else if (campaignObj?.targeting_type === 'manual') {
                setLoading((prevState) => ({
                  ...prevState,
                  manualAdgroup: 'error',
                  manualItem: 'error'
                }));
              }
            } else {
              setLoading((prevState) => ({
                ...prevState,
                campaign: 'done',
                adgroup: 'error',
                item: 'error'
              }));
            }
          }
        } else {
          // Handle campaign creation failure
          if (campaign?.targeting_type === 'both') {
            if (campaignObj?.targeting_type === 'auto') {
              setLoading((prevState) => ({
                ...prevState,
                autoCampaign: 'error',
                autoAdgroup: 'error',
                autoItem: 'error'
              }));
            } else if (campaignObj?.targeting_type === 'manual') {
              setLoading((prevState) => ({
                ...prevState,
                manualCampaign: 'error',
                manualAdgroup: 'error',
                manualItem: 'error'
              }));
            }
          } else {
            setLoading((prevState) => ({
              ...prevState,
              campaign: 'error',
              adgroup: 'error',
              item: 'error'
            }));
          }
        }
      }
    }
  };

  const handlePreviousButton = () => {
    handleBackwordButtonClick();
    setCampaignData((prevState) => ({ ...prevState, adgroup: { name: adgorupNameValue } }));
    setLoading({ campaign: '', adgroup: '', item: '' });
  };

  const onAddAllClick = () => {
    var tempArray = [...selectedData];
    if (searchableData?.length >= 1) {
      searchableData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: MIN_ITEM_BID_LIMIT,
            suggested_bid: item?.suggested_bid
          });
        }
      });
    } else {
      selectdItemData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: MIN_ITEM_BID_LIMIT,
            suggested_bid: item?.suggested_bid
          });
        }
      });
    }
    setSelectedData(tempArray);
  };

  const selectedColumns = [
    ...(campaignData?.campaign?.targeting_type !== CAMPAIGN.TARGETING_TYPE.MANUAL
      ? [
          {
            name: 'select',
            label: (
              <Checkbox
                checked={isAllSelected()} // Fix: Properly checks/unchecks
                onChange={handleSelectAll}
                indeterminate={selectedArray.length > 0 && !isAllSelected()} // Shows partial selection state
              />
            ),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta) => {
                const rowIndex = tableMeta.rowIndex;
                return <Checkbox checked={selectedArray.includes(rowIndex)} onChange={() => handleRowSelection(rowIndex)} />;
              },
              customHeadRender: () => (
                <th style={{ borderBottom: '1px solid #e0e0e0', borderRight: '1px solid #E8E8E8' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', ml: '17px' }}>
                    <Checkbox
                      checked={selectedArray.length > 0}
                      // onChange={handleSelectAll}
                      onChange={() => handleSelectAll(selectMode === 'current' ? '' : 'globalCheck')}
                    />
                    <Tooltip title="Select Mode">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        // sx={{ ml: 2 }}
                        aria-controls={open ? 'select-mode-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Tooltip>

                    <Menu
                      anchorEl={anchorEl}
                      id="select-mode-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem onClick={() => handleSelect('all')}>
                        {selectMode === 'all' && <CheckIcon fontSize="small" sx={{ mr: 1 }} />}
                        <Typography>Select All</Typography>
                      </MenuItem>
                      <MenuItem onClick={() => handleSelect('current')}>
                        {selectMode === 'current' && <CheckIcon fontSize="small" sx={{ mr: 1 }} />}
                        <Typography>Select Current</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </th>
              )
            }
          }
        ]
      : []),
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedData[tableMeta?.rowIndex];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* <Typography sx={{}}>{data?.product_name}</Typography> */}

                {data?.product_name?.length > 20 ? (
                  <Tooltip
                    placement="top"
                    sx={{ fontSize: '10px' }}
                    title={
                      <Typography variant="body1" sx={{ fontSize: 16 }}>
                        {data?.product_name}
                      </Typography>
                    }
                    arrow
                  >
                    {data?.product_name?.substring(0, 25)}...
                  </Tooltip>
                ) : (
                  data?.product_name
                )}

                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                >
                  <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                </Link>
              </Box>
            </Box>
          );
        }
      }
    },
    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bid_amount',
      label: 'bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let data = selectedData[tableMeta?.rowIndex];
          return (
            <TextField
              size="small"
              value={data?.bid_amount}
              type="number"
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent form submission
                }
              }}
              onBlur={(e) => {
                const value = Number(e.target.value);
                const newPrice =
                  value < Number(MIN_ITEM_BID_LIMIT)
                    ? MIN_ITEM_BID_LIMIT
                    : value > Number(MAX_ITEM_BID_LIMIT)
                    ? MAX_ITEM_BID_LIMIT
                    : value;

                if (newPrice !== value) {
                  const updatedData = [...selectedData];
                  updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                  setSelectedData(updatedData);
                }
              }}
              onChange={(e) => {
                const newPrice = Number(e.target.value == 0) ? null : Number(e.target.value);
                const updatedData = [...selectedData];
                updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                setSelectedData(updatedData);
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              inputProps={{
                step: '0.01'
              }}
            />
          );
        }
      }
    },
    {
      name: 'item_id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <IconButton
              onClick={() => {
                var tempData = [...selectedData];
                tempData = tempData?.filter((item) => item?.item_id != value);
                setSelectedData(tempData);
                //Adjust selected array index
                const removedIndex = tableMeta?.rowIndex;
                if (selectedArray.includes(removedIndex)) {
                  const updatedArray = selectedArray
                    .filter((item) => item !== removedIndex)
                    .map((item) => (item > removedIndex ? item - 1 : item));
                  setSelectedArray(updatedArray);
                } else {
                  const updatedArray = selectedArray.map((item) => (item > removedIndex ? item - 1 : item));
                  setSelectedArray(updatedArray);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={!selectedData?.length}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                setSelectedData([]);
                setSelectedArray([]);
              }}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    viewColumns: false,
    serverSide: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    toolbar: false,
    search: false,
    onChangePage: (newPage) => {
      setCurrentPage(newPage);
    },
    onChangeRowsPerPage: (row) => {
      setRowsPerPage(row);
      setSelectedArray([]); // Unselect all
    }
  };

  const handleOkButtonClick = () => {
    if (campaign?.targeting_type === 'both') {
      if (
        loading.autoCampaign == 'done' &&
        loading.autoAdgroup == 'done' &&
        loading.autoItem == 'done' &&
        loading.manualCampaign == 'done' &&
        loading.manualAdgroup == 'done' &&
        loading.manualItem == 'done' &&
        !list?.errorMessages?.length
      ) {
        handleForwordButtonClick();
      } else {
        const array = selectedData.filter((item) => !list?.successlist?.find((list) => list?.item_id == item?.item_id));
        setSelectedData(array);
      }
    } else {
      if (loading.campaign == 'done' && loading.adgroup == 'done' && loading.item == 'done' && !list?.errorMessages?.length) {
        handleForwordButtonClick();
      } else {
        const array = selectedData.filter((item) => !list?.successlist?.find((list) => list?.item_id == item?.item_id));
        setSelectedData(array);
      }
    }

    setLoading({
      campaign: '',
      adgroup: '',
      item: '',
      autoCampaign: '',
      manualCampaign: '',
      autoAdgroup: '',
      manualAdgroup: '',
      autoItem: '',
      manualItem: ''
    });
    setDialogOpen(false);
  };

  const getDisabledOkButton = () => {
    const loadingStatus =
      campaign?.targeting_type === 'both'
        ? ['autoCampaign', 'autoAdgroup', 'autoItem', 'manualCampaign', 'manualAdgroup', 'manualItem']
        : ['campaign', 'adgroup', 'item'];

    const isLoadingDone = loadingStatus.every((key) => loading[key] === 'done' || loading[key] === 'error');
    // const hasNoErrors = !list?.errorMessages?.length;
    return !isLoadingDone;
  };

  return (
    <MainCard content={false} boxShadow sx={{ p: 2, mt: 2 }}>
      <form noValidate onSubmit={handleSubmit(onButtonClick)}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item sm={12}>
              <Controller
                name={'adgroup_name'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...register('adgroup_name')}
                    {...field}
                    name={'adgroup_name'}
                    fullWidth
                    type="text"
                    label="Adgroup Name"
                    variant="outlined"
                    error={errors?.adgroup_name}
                    helperText={errors?.adgroup_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Item', true)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Search" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Select from" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="3" />
                        <Tab label="Enter Items" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SearchTable />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <SelectFromTable onAddAllClick={onAddAllClick} />
                    </TabPanel>
                    <TabPanel value="3">
                      {' '}
                      <SelectFromTableAdGroupSuggested onAddAllClick={onAddAllClick} />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: 0 }}>
                      <EnterKeywordTable keyword={keyword} setkeyword={setkeyword} />
                    </TabPanel>
                  </Box>
                </TabContext>

                {error && (
                  <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                    {'Please select at least one item to proceed.'}
                  </FormHelperText>
                )}
                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      padding: '20px 30px 20px 30px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box display={'flex'} alignItems="center" gap={3}>
                      {campaignData?.campaign?.targeting_type !== CAMPAIGN.TARGETING_TYPE.MANUAL && (
                        <Button
                          variant="outlined"
                          disabled={selectedArray?.length > 0 ? false : true}
                          onClick={handleToggleSetBid}
                        >
                          Set Bid
                        </Button>
                      )}
                      <Typography variant="h5">{selectedData?.length} Item(s) Added</Typography>
                      {selectedArray?.length > 0 && (
                        <Typography variant="h5">{selectedArray?.length} Item(s) Selected</Typography>
                      )}
                    </Box>
                    <Typography variant="h6" sx={{ color: colors.grey500 }}>
                      Item bid is only valid for Auto Campaigns
                    </Typography>
                  </Box>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      sx={{ padding: 0 }}
                      data={selectedData}
                      columns={
                        campaignData?.campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL
                          ? selectedColumns?.filter((item) => item?.name != 'bid_amount' && item?.name != 'suggested_bid')
                          : selectedColumns
                      }
                      options={selectedOptions}
                      className="pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={() => navigate('/campaign')}>
                CANCEL
              </Button>
              <Button variant="outlined" color="secondary" sx={{ ml: 1 }} onClick={handlePreviousButton}>
                PREVIOUS
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit">
                NEXT
              </Button>
            </>
          </Box>
        </Box>
      </form>
      <Dialog open={dialogOpen} aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant="div"> Message</Typography>{' '}
        </DialogTitle>
        <DialogContent dividers sx={{ minWidth: '600px', minHeight: '200px' }}>
          {campaign?.targeting_type === 'both' ? (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                  {loading.autoCampaign == 'process' && <CircularProgress size={20} />}
                  {loading.autoCampaign == 'done' && <TaskAltRoundedIcon color="success" />}
                  {loading.autoCampaign == 'error' && <HighlightOffIcon color="error" />}
                </Box>
                <Typography sx={{ padding: 1.5 }}>
                  {/* {`Campaign (${campaignData?.autoCampaign?.name}) ${processStatus} successfully`} */}
                  {`Campaign (${campaignData?.autoCampaign?.name}) ${
                    loading.autoCampaign == 'error' ? processStatusFailed + ' failed.' : ''
                  }  ${loading.autoCampaign == 'done' ? processStatus + ' successfully.' : ''}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                  {loading.autoAdgroup == 'process' && <CircularProgress size={20} />}
                  {loading.autoAdgroup == 'done' && <TaskAltRoundedIcon color="success" />}
                  {loading.autoAdgroup == 'error' && <HighlightOffIcon color="error" />}
                </Box>
                <Typography sx={{ padding: 1.5 }}>
                  {/* {`Adgroup (${campaignData?.autoAdgroup?.name}) ${processStatus} successfully`} */}
                  {`Adgroup (${campaignData?.autoAdgroup?.name}) ${
                    loading.autoAdgroup == 'error' ? processStatusFailed + ' failed.' : ''
                  }  ${loading.autoAdgroup == 'done' ? processStatus + ' successfully.' : ''}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                  {loading.autoItem == 'process' && <CircularProgress size={20} />}
                  {loading.autoItem == 'done' && <TaskAltRoundedIcon color="success" />}
                  {loading.autoItem == 'error' && <HighlightOffIcon color="error" />}
                </Box>
                <Typography sx={{ padding: 1.5 }}>
                  {`${
                    autoList?.data?.successcount > 0
                      ? autoList?.data?.successcount + ' items have been added successfully'
                      : 'No items added'
                  } ${
                    autoList?.data?.errorlist?.length > 0
                      ? ', ' + autoList?.data?.errorlist?.length + ' items failed to add.'
                      : '.'
                  } `}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                  {loading.manualCampaign == 'process' && <CircularProgress size={20} />}
                  {loading.manualCampaign == 'done' && <TaskAltRoundedIcon color="success" />}
                  {loading.manualCampaign == 'error' && <HighlightOffIcon color="error" />}
                </Box>
                <Typography sx={{ padding: 1.5 }}>
                  {/* {`Campaign (${campaignData?.manualCampaign?.name}) ${processStatus} successfully`} */}
                  {`Campaign (${campaignData?.manualCampaign?.name}) ${
                    loading.manualCampaign == 'error' ? processStatusFailed + ' failed.' : ''
                  }  ${loading.manualCampaign == 'done' ? processStatus + ' successfully.' : ''}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                  {loading.manualAdgroup == 'process' && <CircularProgress size={20} />}
                  {loading.manualAdgroup == 'done' && <TaskAltRoundedIcon color="success" />}
                  {loading.manualAdgroup == 'error' && <HighlightOffIcon color="error" />}
                </Box>
                <Typography sx={{ padding: 1.5 }}>
                  {/* {`Adgroup (${campaignData?.manualAdgroup?.name}) ${processStatus} successfully`} */}
                  {`Adgroup (${campaignData?.manualAdgroup?.name}) ${
                    loading.manualAdgroup == 'error' ? processStatusFailed + ' failed.' : ''
                  }  ${loading.manualAdgroup == 'done' ? processStatus + ' successfully.' : ''}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                  {loading.manualItem == 'process' && <CircularProgress size={20} />}
                  {loading.manualItem == 'done' && <TaskAltRoundedIcon color="success" />}
                  {loading.manualItem == 'error' && <HighlightOffIcon color="error" />}
                </Box>
                <Typography sx={{ padding: 1.5 }}>
                  {`${
                    manualList?.data?.successcount > 0
                      ? manualList?.data?.successcount + ' items have been added successfully'
                      : 'No items added'
                  } ${
                    manualList?.data?.errorlist?.length > 0
                      ? ', ' + manualList?.data?.errorlist?.length + ' items failed to add.'
                      : '.'
                  } `}
                </Typography>
              </Box>
              <Box>
                {autoList?.errorMessages?.map((item, index) => (
                  <Box key={index} sx={{ display: 'flex' }}>
                    {' '}
                    <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                      {autoList?.errorMessages?.length && <HighlightOffIcon color="error" />}
                    </Box>
                    <Typography sx={{ padding: 1.5 }}>{item}</Typography>
                  </Box>
                ))}
              </Box>
              <Box>
                {manualList?.errorMessages?.map((item, index) => (
                  <Box key={index} sx={{ display: 'flex' }}>
                    {' '}
                    <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                      {manualList?.errorMessages?.length && <HighlightOffIcon color="error" />}
                    </Box>
                    <Typography sx={{ padding: 1.5 }}>{item}</Typography>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                  {loading.campaign == 'process' && <CircularProgress size={20} />}
                  {loading.campaign == 'done' && <TaskAltRoundedIcon color="success" />}
                  {loading.campaign == 'error' && <HighlightOffIcon color="error" />}
                </Box>
                <Typography sx={{ padding: 1.5 }}>
                  {/* {`Campaign (${campaignData?.campaign?.name}) ${processStatus} successfully`} */}
                  {`Campaign (${campaignData?.campaign?.name}) ${
                    loading.campaign == 'error' ? processStatusFailed + ' failed.' : ''
                  }  ${loading.campaign == 'done' ? processStatus + ' successfully.' : ''}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                  {loading.adgroup == 'process' && <CircularProgress size={20} />}
                  {loading.adgroup == 'done' && <TaskAltRoundedIcon color="success" />}
                  {loading.adgroup == 'error' && <HighlightOffIcon color="error" />}
                </Box>
                <Typography sx={{ padding: 1.5 }}>
                  {/* {`Adgroup (${campaignData?.adgroup?.name}) ${processStatus} successfully`} */}
                  {`Adgroup (${campaignData?.adgroup?.name}) ${
                    loading.adgroup == 'error' ? processStatusFailed + ' failed.' : ''
                  }  ${loading.adgroup == 'done' ? processStatus + ' successfully.' : ''}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                  {loading.item == 'process' && <CircularProgress size={20} />}
                  {loading.item == 'done' && <TaskAltRoundedIcon color="success" />}
                  {loading.item == 'error' && <HighlightOffIcon color="error" />}
                </Box>
                <Typography sx={{ padding: 1.5 }}>
                  {`${
                    list?.data?.successcount > 0
                      ? list?.data?.successcount + ' items have been added successfully'
                      : 'No items added'
                  } ${list?.data?.errorlist?.length > 0 ? ', ' + list?.data?.errorlist?.length + ' items failed to add.' : '.'} `}
                </Typography>
              </Box>
              <Box>
                {list?.errorMessages?.map((item, index) => (
                  <Box key={index} sx={{ display: 'flex' }}>
                    {' '}
                    <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                      {list?.errorMessages?.length && <HighlightOffIcon color="error" />}
                    </Box>
                    <Typography sx={{ padding: 1.5 }}>{item}</Typography>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ margin: 1 }}
            onClick={handleOkButtonClick}
            disabled={getDisabledOkButton()}
            color="secondary"
            variant="contained"
            autoFocus
            size="small"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {isModalSetBid && <SetBid isOpen={isModalSetBid} onClose={handleToggleSetBid} onSave={handleApplyBid} bidType={'ITEM'} />}
    </MainCard>
  );
};

export default ProductAdGroupSetting;
