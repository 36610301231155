//react
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from 'store';
import { VerifyShareDashboardLink } from 'store/thunk/userThunk';
import { useTheme, styled } from '@mui/material/styles';

//material ui
import { Box, Grid, Toolbar, Typography, AppBar } from '@mui/material';

//project files
import TopProductDashboard from './TopProduct';
import PerformanceComparisonDashboard from './PerformanceComparison';
import AdPerformance from './AdPerformance';
import Engagement from './Engagement';
import MainCard from 'ui-component/cards/MainCard';
import SharedDashboardHeader from 'layout/MainLayout/Header/shared-dashboard-header';

//import custom css
// import '../../assets/scss/public-styles.scss';
import DashboardSkeleton from 'ui-component/skeleton/DashboardSkeleton';
import { setIsDashboardCompareDates } from 'store/slices/userDetailsSlice';

const Main = styled('main')(({ theme }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,

  [theme.breakpoints.down('sm')]: {
    padding: '16px',
    minWidth: 'calc(100% + 20px)'
  }
}));

const DashboardOverview = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const theme = useTheme();
  const [isValidLink, setIsValidLink] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (params?.token) {
      setIsLoading(true);
      dispatch(VerifyShareDashboardLink({ secretKey: params?.token }))
        .then((res) => {
          setIsLoading(false);
          setIsValidLink(res?.payload?.data?.data);
          dispatch(setIsDashboardCompareDates(true));
        })
        .catch((e) => {
          console.log('eror=>>>> ', e);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const getDashboardSections = () => {
    return (
      <>
        {/* <Typography variant="h2" className="Overview-title">
          Overview
        </Typography> */}
        <Grid container>
          <Grid item xs={6} xl={params?.token ? 4 : 6}>
            <Box display="flex" justifyContent="center">
              <AdPerformance />
            </Box>
          </Grid>
          <Grid item xs={6} xl={params?.token ? 4 : 6}>
            <Box display="flex" justifyContent="center">
              <Engagement />
            </Box>
          </Grid>
          <Grid item xs={6} xl={params?.token ? 4 : 6}>
            <Box display="flex" justifyContent="center">
              <PerformanceComparisonDashboard />
            </Box>
          </Grid>
          <Grid item xs={6} xl={params?.token ? 4 : 6}>
            <Box display="flex" justifyContent="center">
              <TopProductDashboard />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <DashboardSkeleton />
      ) : (
        <>
          {params?.token ? (
            <>
              {isValidLink && isValidLink?.is_valid === true ? (
                <Main sx={{ padding: params?.token ? '20px' : '0px', marginTop: params?.token ? '88px' : '0px' }}>
                  <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                      bgcolor: theme.palette.background.default,
                      transition: theme.transitions.create('width')
                    }}
                  >
                    <Toolbar style={{ height: 86 }}>
                      <SharedDashboardHeader secretKey={params?.token} />
                    </Toolbar>
                  </AppBar>
                  {getDashboardSections()}
                </Main>
              ) : (
                <MainCard className="invalid-link-main-card">
                  <MainCard className="invalid-link-main-card">
                    <Box className="invalid-link-content">
                      <img src="/broken-link.svg" alt="broken-link" />
                      <Typography className="link-title">Link Invalid !</Typography>
                      <Typography className="link-description">{`The link is invalid or expired, You can't continue to view the data.`}</Typography>
                    </Box>
                  </MainCard>
                </MainCard>
              )}
            </>
          ) : (
            <>{getDashboardSections()}</>
          )}
        </>
      )}
    </>
  );
};

export default DashboardOverview;
