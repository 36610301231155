//react
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@mui/material/styles';
//mui
import { Box, FormControl, MenuItem, Select, Typography, Tooltip, Button, CircularProgress } from '@mui/material';
import MUIDataTable from 'mui-datatables';
//project
import { useAppDispatch, useAppSelector } from 'store';
import { GetPerformanceComparisonData } from 'store/thunk/userThunk';
import { formateValue } from 'helper/commonHelper';
import CompareValueWithColor, { CompareValueWithColorForExcelDownload } from 'ui-component/compareWithValue';
import downloadIcon from 'assets/images/dashna/download.svg';
import * as XLSX from 'xlsx-js-style';

const PerformanceComparisonDashboard = () => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { selectedDashboardAdvertiser, commonAdvertiser, PerformanceComparisonData } = useAppSelector(
    (state) => state?.userDetailsSlice
  );

  //Prevent Un-necessary loading when no profile changed
  const previousAdvertiser = useRef(commonAdvertiser);
  const previousSelectedAdvertiser = useRef(selectedDashboardAdvertiser);
  const isFirstRender = useRef(true);

  const [dropdownValue, setDropdownValue] = useState('week');
  const previousDropdownValue = useRef(dropdownValue);
  const [newData, setNewData] = useState([]);
  const [newDataColumn, setNewDataColumn] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setDropdownValue(event.target.value);
  };

  useEffect(() => {
    if (PerformanceComparisonData?.total_data?.length > 0) {
      const tempData = JSON.parse(
        JSON.stringify(PerformanceComparisonData.total_data.filter((obj) => obj.matrix != 'advertised_sku_sales_14days'))
      );
      const index = tempData.findIndex((obj) => obj.matrix == 'attributed_sales_14days');
      if (index !== -1) {
        let attributedSalesData = tempData.splice(index, 1)[0];
        tempData.unshift(attributedSalesData);
      }
      setNewData(tempData);
      setIsLoading(false);
    }
    if (PerformanceComparisonData?.dates) {
      setNewDataColumn(PerformanceComparisonData.dates);
    }
  }, [PerformanceComparisonData]);

  const getPerformanceComparisionData = () => {
    if (params?.token) {
      if (selectedDashboardAdvertiser?.length > 0) {
        setIsLoading(true);
        getPerformanceComparisionList(selectedDashboardAdvertiser, dropdownValue);
      }
    } else {
      const advertiserFilter = commonAdvertiser.length > 0 ? commonAdvertiser : '';
      if (advertiserFilter.length > 0) {
        setIsLoading(true);
        getPerformanceComparisionList(advertiserFilter, dropdownValue);
      }
    }
  };

  //Download Performance Comparison CSV
  const handleDownloadCsv = () => {
    //Make Duplicate Date Column, one for Value and one for Percentage value
    const newDataColumnDuplicate = newDataColumn.flatMap((date) => [date, date]);

    //Prepare Header with Dates Column
    let header = ['Metric'];
    header = [...header, ...newDataColumnDuplicate];

    //Create New Excel Tab
    const wb = XLSX.utils.book_new();

    const globalArr = [];
    const row = [];

    //Prepare Header Row
    header.map((l) => {
      const obj = { v: l, t: 's', s: { font: { bold: true } } };
      row.push(obj);
    });
    globalArr.push(row);

    //Loop Over Performance Comparison data of all Metric
    newData.map((d) => {
      const array = [];
      //Loop Over Header Dates
      header.map((h, headerIdx) => {
        if (headerIdx === 0) {
          const obj = { v: d?.display_field, t: 's', s: { font: { bold: true } } };
          array.push(obj);
        } else {
          if (headerIdx % 2 !== 0) {
            //If It is Value
            let rowVal = parseFloat(d?.[h]);
            const newValue = formateValue(d?.matrix, rowVal);
            const obj = { v: newValue, t: 's', s: {} };
            array.push(obj);
          } else {
            //If it is Percentage
            const args = {
              Percentage: parseFloat(`${d[`percentage_${h}`]}`),
              UpDown: typeof `${d[`is_higher_${h}`]}` != 'undefined' && `${d[`is_higher_${h}`]}` == 'true' ? true : false,
              keys: `${d[`matrix`]}`,
              theme
            };
            const { color, PercentageValue } = CompareValueWithColorForExcelDownload(args);
            const obj = { v: `${PercentageValue}`, t: 's', s: { font: { color: { rgb: color } } } };
            array.push(obj);
          }
        }
      });
      globalArr.push(array);
    });

    const excelTab = dropdownValue === 'month' ? 'MoM' : 'WoW';
    const ws = XLSX.utils.aoa_to_sheet(globalArr);

    ws['!cols'] = header.map(() => ({ wch: 12 })); // Set each column

    XLSX.utils.book_append_sheet(wb, ws, excelTab);
    XLSX.writeFile(wb, 'Performance Comparison.xlsx');
  };

  useEffect(() => {
    if (JSON.stringify(previousDropdownValue.current) !== JSON.stringify(dropdownValue)) {
      getPerformanceComparisionData();
      previousDropdownValue.current = dropdownValue;
    }
  }, [dropdownValue]);

  //For shared dashboard page to prevent unnecessary loading when no profile changed
  useEffect(() => {
    if (JSON.stringify(previousSelectedAdvertiser.current) !== JSON.stringify(selectedDashboardAdvertiser)) {
      getPerformanceComparisionData();
      previousSelectedAdvertiser.current = selectedDashboardAdvertiser;
    }
  }, [selectedDashboardAdvertiser]);

  //For dashboard page to prevent unnecessary loading when no profile changed
  useEffect(() => {
    if (JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) || isFirstRender?.current) {
      getPerformanceComparisionData();
      previousAdvertiser.current = commonAdvertiser;
      isFirstRender.current = false;
    }
  }, [commonAdvertiser]);

  const getPerformanceComparisionList = (advertiserFilter, dropdownValue) => {
    let request = {
      advertiserFilter,
      dropdownValue
    };
    if (params?.token) {
      request = { ...request, secretKey: params.token };
    }
    dispatch(GetPerformanceComparisonData(request));
  };

  const options = {
    filter: false,
    sort: false,
    print: false,
    viewColumns: false,
    responsive: false,
    align: 'center',
    search: false,
    count: newData?.length || 0,
    page: 1,
    jumpToPage: false,
    download: false,
    pagination: false,
    serverSide: false,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: (
          <Box p={2} display="flex" justifyContent="center">
            Sorry, no matching records found
          </Box>
        )
      }
    }
  };

  const columnLabels = newDataColumn;

  const generateColumns = (labels) => {
    const commonOptions = {
      filter: true,
      sort: true,
      setCellProps: () => ({
        className: 'table-align-right'
      })
    };

    const columns = [
      {
        label: 'Metric',
        name: 'display_field',
        options: {
          filter: true,
          sort: false,
          setCellProps: () => ({
            style: {
              textAlign: 'left'
            }
          })
        }
      },
      ...labels.map((label) => ({
        label: label,
        name: label,
        options: {
          ...commonOptions,
          customBodyRender: (value, rowData) => {
            const data = newData[rowData?.rowIndex];
            const newValue = formateValue(data?.matrix, value);
            return (
              <Box>
                <Typography className="font-size-overview">{newValue?.length > 0 ? newValue : '-'}</Typography>

                <CompareValueWithColor
                  value={parseFloat(`${data[`comparison_${label}`]}`)}
                  Percentage={parseFloat(`${data[`percentage_${label}`]}`)}
                  UpDown={
                    typeof `${data[`is_higher_${label}`]}` != 'undefined' && `${data[`is_higher_${label}`]}` == 'true'
                      ? true
                      : false
                  }
                  keys={`${data[`matrix`]}`}
                  currency={true}
                  dashboardOverview={true}
                  tooltipApply={false}
                />
              </Box>
            );
          }
        }
      }))
    ];

    return columns;
  };

  const columns = generateColumns(columnLabels);

  return (
    <Box
      className={params?.token ? 'white-paper-card-dashboard chart-card' : 'white-paper-card-dashboard table-card'}
      sx={{
        ...((isLoading || commonAdvertiser?.length <= 0 || selectedDashboardAdvertiser?.length <= 0) && {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        })
      }}
    >
      {commonAdvertiser?.length <= 0 && selectedDashboardAdvertiser?.length <= 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography className="no-advertiser">Please select atleast one advertiser.</Typography>
        </Box>
      ) : isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={20} color="inherit" />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box mb="12px">
              <Typography className="dashboard-card-title">Performance Comparison</Typography>
              <Typography className="dashboard-card-subtitle">Last 6 {dropdownValue == 'month' ? 'months' : 'weeks'}</Typography>
            </Box>
            <Box display={'flex'}>
              <Box mr={1}>
                <Button sx={{ p: '0px !important', minWidth: '45px' }} onClick={handleDownloadCsv}>
                  <Tooltip arrow title="Download">
                    <img src={downloadIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />
                  </Tooltip>
                </Button>
              </Box>
              <Box>
                <FormControl className="filter-select" sx={{ minWidth: '100px' }}>
                  <Select
                    value={dropdownValue}
                    onChange={handleChange}
                    size="small"
                    className="ad-performance-select"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem className="top-product-value-dropdown" value="month">
                      MoM
                    </MenuItem>
                    <MenuItem className="top-product-value-dropdown" value="week">
                      WoW
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box mt={1}>
            <MUIDataTable
              className="performance-comparison-table pnl-by-item"
              actions={[
                {
                  icon: 'save'
                }
              ]}
              data={newData}
              columns={columns}
              options={options}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default PerformanceComparisonDashboard;
