import { Autocomplete, Button, Checkbox, TextField } from '@mui/material';
import { Box } from '@mui/system';
import CampaignBrandContext from 'context/brand';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import MainCard from 'ui-component/cards/MainCard';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { MIN_KEYWORD_BID_LIMIT_CAMP_SB, MAX_KEYWORD_CHAR_LIMIT, MAX_KEYWORD_LIMIT } from 'store/constant';
import { useEffect } from 'react';

const EnterKeywordTable = () => {
  const { setSelectedKeywordData } = useContext(CampaignBrandContext);

  const [adGroupName, setAdGroupName] = useState('');
  const [selectType, setSelectType] = useState([]);
  const [count, setCount] = useState(0);
  const [exceedCount, setExceedCount] = useState(0);

  const matchTypeData = [
    { id: 1, name: 'exact' },
    { id: 2, name: 'phrase' },
    { id: 3, name: 'broad' }
  ];
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //For track total keyword count
  useEffect(() => {
    if (adGroupName?.trim() === '') {
      setCount(0);
      setExceedCount(0);
    } else {
      const lines = adGroupName
        ?.split(/\n+/)
        ?.map((line) => line.trim())
        ?.filter((line) => line.length > 0);

      setCount(lines?.length);

      // Count how many keywords exceed 80 characters
      const longKeywords = lines?.filter((line) => line?.length > MAX_KEYWORD_CHAR_LIMIT)?.length;
      setExceedCount(longKeywords); // Update state for exceeding count
    }
  }, [adGroupName]);

  const handleAddButton = () => {
    const values = adGroupName
      .trim()
      .split('\n')
      .filter((item) => item.length <= MAX_KEYWORD_CHAR_LIMIT && Boolean(item));
    var array = [];
    values.map((item) => {
      selectType?.length > 0
        ? selectType?.map((type) => {
            array.push({
              keywordtext: item,
              matchtype: type?.name,
              bid_amount: MIN_KEYWORD_BID_LIMIT_CAMP_SB,
              suggested_bid: null
            });
          })
        : matchTypeData?.map((type) => {
            array.push({
              keywordtext: item,
              matchtype: type?.name,
              bid_amount: MIN_KEYWORD_BID_LIMIT_CAMP_SB,
              suggested_bid: null
            });
          });
    });
    setSelectedKeywordData(array);
  };

  const handleSetAdgroupName = (e) => {
    const inputVal = e.target.value;
    const words = inputVal.trim().split('\n');
    if (words.length <= MAX_KEYWORD_LIMIT) {
      setAdGroupName(inputVal);
    } else {
      setAdGroupName(inputVal);
    }
  };

  return (
    <>
      <Box>
        <MainCard sx={{ padding: 0 }}>
          <Autocomplete
            multiple={true}
            id="tags-outlined"
            name="adgroup_name"
            value={selectType}
            size={'small'}
            sx={{ width: '30%', mb: 2 }}
            options={matchTypeData}
            // value={filterValue.status}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => {
              console.log('e ', e.target.value, value);
              setSelectType(value);
            }}
            renderOption={(props, option, { selected }) => {
              console.log('selected ', selected);
              return (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </li>
              );
            }}
            renderInput={(params) => <TextField color="secondary" {...params} placeholder="Select match type" />}
          />
          <TextField
            name={'adgroup_name'}
            fullWidth
            type="text"
            multiline
            color="secondary"
            placeholder="Please enter keyword, one keyword per line"
            rows={4}
            onChange={handleSetAdgroupName}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box className={count > MAX_KEYWORD_LIMIT ? 'error-text' : ''}>
              You can add up to {MAX_KEYWORD_LIMIT} keywords, with each keyword limited to {MAX_KEYWORD_CHAR_LIMIT} characters.
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={2}>
              {exceedCount > 0 && (
                <Box className="error-text">{`${exceedCount} of the keywords exceeds ${MAX_KEYWORD_CHAR_LIMIT} characters`}</Box>
              )}
              <Box display={'flex'}>
                <Box className={count > MAX_KEYWORD_LIMIT ? 'error-text' : ''}>{count}</Box>
                <Box>/ {MAX_KEYWORD_LIMIT}</Box>
              </Box>
              <Button
                disabled={count > MAX_KEYWORD_LIMIT ? true : false}
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleAddButton}
              >
                Add
              </Button>
            </Box>
          </Box>
        </MainCard>
      </Box>
    </>
  );
};

export default EnterKeywordTable;
