import { Box, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { allValuesTrueByValue, formateValue } from 'helper/commonHelper';
import colorValue from 'assets/scss/_themes-vars.module.scss';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

//When Download Performance Comparison Report in Excel
export const CompareValueWithColorForExcelDownload = (props) => {
  const { Percentage, UpDown, keys, theme } = props;

  const grayColor = theme.palette.grey[500];
  const errorMain = colorValue.errorMain;
  const successDark = colorValue.successDark;

  if (typeof Percentage !== 'undefined') {
    const color =
      Percentage?.toFixed(2) == '0.00'
        ? grayColor
        : allValuesTrueByValue(keys, UpDown) == 'gray' || allValuesTrueByValue(keys, UpDown) == 'gray1'
        ? grayColor
        : allValuesTrueByValue(keys, UpDown) == 'is_up'
        ? successDark
        : errorMain;

    const PercentageValue = `${Percentage?.toFixed(2)}%`;

    return {
      color: color.replace('#', ''),
      PercentageValue
    };
  } else {
    return {
      color: '',
      PercentageValue: Percentage
    };
  }
};

const CompareValueWithColor = (props) => {
  const theme = useTheme();
  const {
    value,
    Percentage,
    UpDown,
    keys,
    currency = false,
    dashboard,
    dashboardOverview,
    isTotalRow,
    tooltipApply = true
  } = props;
  const grayColor = theme.palette.grey[500];
  const errorMain = colorValue.errorMain;
  const successDark = colorValue.successDark;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fontSizeData, setFontSizeData] = useState('10px');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTooltipOpen = () => {
    if (tooltipApply) {
      setIsTooltipOpen(true);
    }
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  useEffect(() => {
    if (windowWidth > 1919) {
      setFontSizeData('12px');
    } else if (windowWidth > 2159) {
      setFontSizeData('14px');
    }
  }, [windowWidth]);

  return dashboard ? (
    <Typography display="flex" className="ffInter">
      {typeof Percentage !== 'undefined' && (
        <Typography display="flex" sx={{ alignItems: 'center', flexDirection: 'row' }}>
          <Typography marginRight={1} variant="body1" sx={{ fontSize: 16, textAlign: 'right' }}>
            {currency && <span>$</span>}
            {formateValue(keys, value?.toFixed(2))}
          </Typography>
          <Typography
            sx={{
              color:
                Percentage?.toFixed(2) == '0.00'
                  ? grayColor
                  : allValuesTrueByValue(keys, UpDown) == 'gray' || allValuesTrueByValue(keys, UpDown) == 'gray1'
                  ? grayColor
                  : allValuesTrueByValue(keys, UpDown) == 'is_up'
                  ? successDark
                  : errorMain,
              fontSize: '16px'
            }}
          >
            {Percentage?.toFixed(2)}%
          </Typography>
          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            {Percentage?.toFixed(2) == '0.00' ? (
              <ArrowForwardIcon fontSize="small" className="info-forward" />
            ) : UpDown ? (
              <ArrowUpwardIcon
                fontSize="small"
                sx={{
                  color:
                    allValuesTrueByValue(keys, UpDown) == 'gray' || allValuesTrueByValue(keys, UpDown) == 'gray1'
                      ? grayColor
                      : allValuesTrueByValue(keys, UpDown) == 'is_up'
                      ? successDark
                      : errorMain
                }}
              />
            ) : (
              <ArrowDownwardIcon
                fontSize="small"
                sx={{
                  color:
                    allValuesTrueByValue(keys, UpDown) == 'gray' || allValuesTrueByValue(keys, UpDown) == 'gray1'
                      ? grayColor
                      : allValuesTrueByValue(keys, UpDown) == 'is_up'
                      ? successDark
                      : errorMain
                }}
              />
            )}
          </Typography>
        </Typography>
      )}
    </Typography>
  ) : (
    <Typography display="flex" className="ffInter">
      <Tooltip
        placement="top"
        sx={{ fontSize: '10px' }}
        componentsProps={{
          tooltip: {
            sx: {
              background: '#fff',
              boxShadow: '0px 2px 4px 2px rgba(0,0,0,0.2)',
              color: '#000',
              padding: '6px 10px'
            }
          }
        }}
        open={isTooltipOpen}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        title={
          <Box className="custom-tooltip">
            <Typography variant="body1" sx={{ fontSize: 14, color: grayColor, textAlign: 'right' }}>
              Previous
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 16, textAlign: 'right' }}>
              {currency && <span>$</span>}
              {isTotalRow ? (value ? value : 0) : value ? value?.toFixed(2) : '0'}
            </Typography>
          </Box>
        }
        // arrow
      >
        {typeof Percentage !== 'undefined' && (
          <Typography display="flex" sx={{ alignItems: 'center', flexDirection: 'row' }}>
            <Typography
              sx={{
                color:
                  Percentage?.toFixed(2) == '0.00'
                    ? grayColor
                    : allValuesTrueByValue(keys, UpDown) == 'gray' || allValuesTrueByValue(keys, UpDown) == 'gray1'
                    ? grayColor
                    : allValuesTrueByValue(keys, UpDown) == 'is_up'
                    ? successDark
                    : errorMain,
                fontSize: dashboardOverview ? fontSizeData : ''
              }}
            >
              {Percentage?.toFixed(2)}%
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              {' '}
              {Percentage?.toFixed(2) == '0.00' ? (
                <ArrowForwardIcon
                  sx={{ fontSize: dashboardOverview ? fontSizeData : '' }}
                  fontSize="small"
                  className="info-forward"
                />
              ) : UpDown ? (
                <ArrowUpwardIcon
                  fontSize="small"
                  sx={{
                    color:
                      allValuesTrueByValue(keys, UpDown) == 'gray' || allValuesTrueByValue(keys, UpDown) == 'gray1'
                        ? grayColor
                        : allValuesTrueByValue(keys, UpDown) == 'is_up'
                        ? successDark
                        : errorMain,
                    fontSize: dashboardOverview ? fontSizeData : ''
                  }}
                />
              ) : (
                <ArrowDownwardIcon
                  fontSize="small"
                  sx={{
                    color:
                      allValuesTrueByValue(keys, UpDown) == 'gray' || allValuesTrueByValue(keys, UpDown) == 'gray1'
                        ? grayColor
                        : allValuesTrueByValue(keys, UpDown) == 'is_up'
                        ? successDark
                        : errorMain,
                    fontSize: dashboardOverview ? fontSizeData : ''
                  }}
                />
              )}
            </Typography>
          </Typography>
        )}
      </Tooltip>
    </Typography>
  );
};

export default CompareValueWithColor;
