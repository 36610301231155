import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function BudgetTrackerIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ fill: 'none' }}>
      <path
        d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.5 16.5L15 15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.5001 13C8.76733 13 8.9846 12.7829 8.9846 12.5159V11.8338L9.06403 11.8244C9.57746 11.764 10.0215 11.596 10.3484 11.3386C10.6825 11.0754 10.8899 10.731 10.9646 10.3145V10.3142C11.091 9.62446 10.8795 9.09755 10.318 8.70313C9.82762 8.35855 9.16305 8.17729 8.62913 8.03153L8.36423 7.95875C7.73686 7.78412 7.38445 7.63801 7.18605 7.46984C6.94919 7.26904 6.95387 7.04046 6.98981 6.84343C7.0466 6.53237 7.2937 6.30863 7.70452 6.19639C8.04237 6.10406 8.48159 6.0908 8.94129 6.1591C9.17958 6.19442 9.47916 6.27187 9.85745 6.39612C10.1116 6.4793 10.3865 6.3409 10.4703 6.08775C10.5109 5.96494 10.501 5.83371 10.4426 5.71825C10.3842 5.60279 10.2841 5.51691 10.1608 5.47657C9.7259 5.33351 9.38391 5.24602 9.08433 5.20137C9.08344 5.20137 9.08218 5.20137 9.08092 5.2012C9.07481 5.20084 9.06852 5.20048 9.06205 5.19958L8.98478 5.18865V4.48406C8.98478 4.21711 8.76733 4 8.49992 4C8.23251 4 8.01434 4.21711 8.01434 4.48406V5.16462L7.93491 5.17394C7.42147 5.23382 6.97705 5.40127 6.64962 5.65783C6.31643 5.91869 6.10977 6.25896 6.03537 6.66934C5.91101 7.35169 6.1139 7.87789 6.65555 8.27823C7.11309 8.61618 7.70649 8.78201 8.09916 8.89173L8.10132 8.89227L8.37106 8.96596C9.12243 9.17016 9.50468 9.31179 9.73471 9.47082C9.98307 9.64257 10.065 9.84785 10.0088 10.1566C9.95216 10.4673 9.70506 10.6907 9.29424 10.8029C8.95638 10.895 8.51609 10.9085 8.05441 10.8404C7.68187 10.7871 7.24014 10.636 6.85035 10.5026L6.64171 10.4316C6.59049 10.4144 6.53837 10.4061 6.48716 10.4061C6.28498 10.4061 6.09557 10.5338 6.02692 10.7357C5.98541 10.8577 5.99421 10.9888 6.05172 11.1048C6.10923 11.2208 6.20843 11.3076 6.33099 11.3488L6.52939 11.4164C6.96123 11.5643 7.45041 11.7319 7.91388 11.7984H7.91622C7.92304 11.7988 7.92987 11.7992 7.93706 11.8002L8.01398 11.8114V12.5158C8.01398 12.7827 8.23197 12.9998 8.49992 12.9998L8.5001 13Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default BudgetTrackerIcon;
