//react
import { useState, useEffect, useRef } from 'react';

//store
import { useAppDispatch, useAppSelector } from 'store';
import { GetEngagementDetails } from 'store/thunk/userThunk';
import { useParams } from 'react-router';

//mui and styling
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  CircularProgress
} from '@mui/material';
import { Close } from '@mui/icons-material';
import DashboardMetricsChart from '../dashboardMetricsChart';
import { formateValueWithThousand, formatThousandNumber, formateValue } from 'helper/commonHelper';
import ManageDashboardMetrics from '../../../ui-component/metricsPopover/manageDashboardMetrics';
import CompareValueWithColorForOverView from 'ui-component/over-view-compare';
import { useTheme } from '@mui/material/styles';
import colors from 'assets/scss/_themes-vars.module.scss';

const Engagement = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {
    selectedDashboardAdvertiser,
    dashboardDateRange,
    isDashboardCompareDates,
    commonAdvertiser,
    EngagementData,
    commonDateRange,
    isComparingDates
  } = useAppSelector((state) => state?.userDetailsSlice);

  // const { status } = useAppSelector((state) => state.notification);
  const [chartData, setChartData] = useState({});
  const [cardsData, setcardsData] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedCharts, setselectedCharts] = useState([]);
  const [chartType, setChartType] = useState('daily');
  const [openAdPerformanceChart, setOpenAdPerformanceChart] = useState(false);
  const [allChartMetrics, setAllChartMetrics] = useState([]);
  const [selectedCardsFullScreen, setSelectedCardsFullScreen] = useState([]);
  const [allChartMetricsFullScreen, setAllChartMetricsFullScreen] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoDataFound, setIsNoDataFound] = useState(false);

  const defaultColors = {
    0: colors?.metricD,
    1: colors?.metricE,
    2: colors?.metricF
  };
  const grayColor = theme.palette.grey[200];

  //Prevent Un-necessary loading when no profile changed
  const previousAdvertiser = useRef(commonAdvertiser);
  const previousDateRange = useRef(commonDateRange);
  const previousDashboardDateRange = useRef(dashboardDateRange);
  const previouschartType = useRef(chartType);
  const previousSelectedAdvertiser = useRef(selectedDashboardAdvertiser);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (EngagementData) {
      if (EngagementData?.table_data) {
        setChartData(EngagementData?.table_data);
      }

      if (EngagementData?.total_data) {
        setcardsData(EngagementData?.total_data);
        setSelectedCards(EngagementData?.total_data.slice(0, 5));
        setSelectedCardsFullScreen(EngagementData?.total_data);
        const selectedChartsData = EngagementData?.total_data.slice(0, 3)?.map((obj) => obj.name);
        setselectedCharts(selectedChartsData);
      }
      setIsLoading(false);
      setIsNoDataFound(false);
    }
  }, [EngagementData]);

  const setDefaultColors = (getChartMetrics) => {
    const allChartMetricsList = getChartMetrics?.map((l, i) => {
      return {
        ...l,
        color: defaultColors[i]
      };
    });
    return allChartMetricsList;
  };

  //Prepare first 3 Chart Metrics initially
  useEffect(() => {
    if (!selectedCards.length) return;

    const getChartMetrics = selectedCards?.slice(0, 3);
    const allChartMetricsList = setDefaultColors(getChartMetrics);
    setAllChartMetrics(allChartMetricsList);
    setAllChartMetricsFullScreen(allChartMetricsList);
  }, [selectedCards]);

  //When Fullscreen chart modal open then copy selected tiles (any 3) to full screen modal to display same selected tiles and chart
  useEffect(() => {
    if (openAdPerformanceChart) {
      setAllChartMetricsFullScreen(allChartMetrics);
    }
  }, [openAdPerformanceChart]);

  const handleCloseDialog = () => {
    setOpenAdPerformanceChart(false);
  };

  const getEngagementDetailsAPI = (advertiserFilter, commonDateRange = { startDate: '', endDate: '' }, isComparingDates) => {
    let request = {
      advertiserFilter,
      commonDateRange,
      isComparingDates,
      chartType
    };

    if (params?.token) {
      request = { ...request, secretKey: params.token };
    }

    dispatch(GetEngagementDetails(request));
  };

  const getEngagementDetail = () => {
    if (params?.token) {
      if (dashboardDateRange && Object.keys(dashboardDateRange).length && selectedDashboardAdvertiser.length) {
        setIsLoading(true);
        setIsNoDataFound(false);
        getEngagementDetailsAPI(selectedDashboardAdvertiser, dashboardDateRange, isDashboardCompareDates);
      } else {
        setIsNoDataFound(true);
      }
    } else {
      const advertiserFilter = commonAdvertiser.length > 0 ? commonAdvertiser : '';
      if (advertiserFilter.length > 0) {
        setIsLoading(true);
        setIsNoDataFound(false);
        getEngagementDetailsAPI(advertiserFilter, commonDateRange, isComparingDates);
      } else {
        setIsNoDataFound(true);
      }
    }
  };

  useEffect(() => {
    if (JSON.stringify(previousDateRange.current) !== JSON.stringify(commonDateRange)) {
      getEngagementDetail();
      previousDateRange.current = commonDateRange;
    }
  }, [commonDateRange]);

  useEffect(() => {
    if (JSON.stringify(previousDashboardDateRange.current) !== JSON.stringify(dashboardDateRange)) {
      getEngagementDetail();
      previousDashboardDateRange.current = dashboardDateRange;
    }
  }, [dashboardDateRange]);

  useEffect(() => {
    if (JSON.stringify(previouschartType.current) !== JSON.stringify(chartType)) {
      getEngagementDetail();
      previouschartType.current = chartType;
    }
  }, [chartType]);

  //For shared dashboard page to prevent unnecessary loading when no profile changed
  useEffect(() => {
    if (JSON.stringify(previousSelectedAdvertiser.current) !== JSON.stringify(selectedDashboardAdvertiser)) {
      getEngagementDetail();
      previousSelectedAdvertiser.current = selectedDashboardAdvertiser;
    }
  }, [selectedDashboardAdvertiser]);

  //For dashboard page to prevent unnecessary loading when no profile changed
  useEffect(() => {
    if (JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) || isFirstRender?.current) {
      getEngagementDetail();
      previousAdvertiser.current = commonAdvertiser;
      isFirstRender.current = false;
    }
  }, [commonAdvertiser]);

  //When clicked on Metrics Card
  const handleAddToChartMatrics = (keyDisplay) => {
    //openAdPerformanceChart is true when we click on full view of chart
    const chartMetrics = openAdPerformanceChart ? allChartMetricsFullScreen : allChartMetrics;
    const selectedTiles = openAdPerformanceChart ? selectedCardsFullScreen : selectedCards;
    const isMatricsExistAlready = chartMetrics.filter((l) => l?.name === keyDisplay);
    if (!isMatricsExistAlready.length) {
      let allChartMetricsList = [...chartMetrics];
      const getMatrics = selectedTiles.find((l) => l?.name === keyDisplay);
      let firstMetrics = allChartMetricsList[0];
      allChartMetricsList.splice(0, 1);
      allChartMetricsList.push({
        color: firstMetrics?.color,
        name: getMatrics?.name,
        display_field: getMatrics?.display_field,
        value: getMatrics?.value
      });
      openAdPerformanceChart ? setAllChartMetricsFullScreen(allChartMetricsList) : setAllChartMetrics(allChartMetricsList);
    }
  };

  const MetricCard = ({ keyDisplay, title, value, percentage, amountChange, is_higher }) => {
    const chartMetrics = openAdPerformanceChart ? allChartMetricsFullScreen : allChartMetrics;
    const isRecordExist = chartMetrics?.findIndex((l) => l?.name === keyDisplay);
    const borderTopColor = isRecordExist < 0 ? grayColor : chartMetrics[isRecordExist]?.color;
    return (
      <Card
        className="custom-card"
        style={{ borderTopColor: borderTopColor }}
        onClick={() => handleAddToChartMatrics(keyDisplay)}
      >
        <CardContent className="metric-card-content">
          <Typography className="metric-title">{title}</Typography>
          <Typography className="metric-value">{value}</Typography>
          {((isComparingDates && !params?.token) || (isDashboardCompareDates && params?.token)) && (
            <CompareValueWithColorForOverView
              value={value}
              Percentage={percentage}
              UpDown={is_higher}
              keys={keyDisplay}
              amountChange={amountChange}
            />
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Box
        className="white-paper-card-dashboard chart-card"
        sx={{
          ...((isLoading || isNoDataFound) && {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          })
        }}
      >
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={20} color="inherit" />
          </Box>
        ) : isNoDataFound ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography className="no-advertiser">Please select atleast one advertiser.</Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} marginBottom="20px">
              <Box>
                <Typography className="dashboard-card-title">Engagement</Typography>
              </Box>
              {cardsData.length > 5 && (
                <ManageDashboardMetrics
                  title={'+Add Metrics'}
                  cardsData={cardsData}
                  selectedCards={selectedCards}
                  setSelectedCards={setSelectedCards}
                />
              )}
            </Box>
            <Box className="flex-container" mb={1.75}>
              {selectedCards?.length > 0 &&
                selectedCards.map((card, key) => (
                  <>
                    <MetricCard
                      key={card?.name}
                      keyDisplay={card?.name}
                      title={card?.display_field}
                      value={card?.value ? formateValueWithThousand(card?.name, formatThousandNumber(card?.value)) : 0}
                      percentage={card?.percentage || 0}
                      amountChange={
                        card?.value ? formateValueWithThousand(card?.name, formatThousandNumber(card?.comparison)) : 0
                      }
                      is_higher={card?.is_higher}
                    />
                    {key !== selectedCards.length - 1 && <Divider className="vertical-divider" orientation="vertical" flexItem />}
                  </>
                ))}
            </Box>
            <DashboardMetricsChart
              allChartMetrics={allChartMetrics}
              cardData={cardsData}
              chartData={chartData}
              selectedCharts={selectedCharts}
              chartType={chartType}
              setChartType={setChartType}
              openAdPerformanceChart={openAdPerformanceChart}
              setOpenAdPerformanceChart={setOpenAdPerformanceChart}
              showToolbar={true}
              downloadClass="engagement-download"
            />
          </>
        )}
      </Box>
      <Box>
        <Dialog fullWidth maxWidth="md" onClose={handleCloseDialog} open={openAdPerformanceChart}>
          <DialogTitle id="customized-dialog-title">Engagement</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
          <DialogContent dividers>
            <Box sx={{ height: 'auto !important' }} className="white-paper-card-dashboard">
              <Box className="flex-container" mb={1}>
                {selectedCardsFullScreen?.length > 0 &&
                  selectedCardsFullScreen.map((card, key) => (
                    <>
                      <MetricCard
                        key={card?.name}
                        keyDisplay={card?.name}
                        title={card?.display_field}
                        value={card?.value ? formateValue(card?.name, card?.value) : 0}
                        percentage={card?.percentage || 0}
                        amountChange={card?.comparison ? formateValue(card?.name, card?.comparison) : 0}
                        is_higher={card?.is_higher}
                      />
                      {key !== selectedCardsFullScreen.length - 1 && (
                        <Divider className="vertical-divider" orientation="vertical" flexItem />
                      )}
                    </>
                  ))}
              </Box>
              <DashboardMetricsChart
                allChartMetrics={allChartMetricsFullScreen}
                cardData={cardsData}
                chartData={chartData}
                selectedCharts={selectedCharts}
                chartType={chartType}
                setChartType={setChartType}
                setOpenAdPerformanceChart={setOpenAdPerformanceChart}
                showToolbar={false}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus variant="contained" onClick={handleCloseDialog}>
              close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default Engagement;
