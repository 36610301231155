import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ShareofVoiceIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ fill: 'none' }}>
      <path
        d="M13.74 8.99989C15.69 8.99989 16.5 8.24989 15.78 5.78989C15.2925 4.13239 13.8675 2.70739 12.21 2.21989C9.75 1.49989 9 2.30989 9 4.25989V6.41989C9 8.24989 9.75 8.99989 11.25 8.99989H13.74Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0001 11.0251C14.3026 14.4976 10.9726 17.0176 7.18507 16.4026C4.34257 15.9451 2.05507 13.6576 1.59007 10.8151C0.98257 7.04257 3.48757 3.71257 6.94507 3.00757"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default ShareofVoiceIcon;
