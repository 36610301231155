import * as Yup from 'yup';
import React, { useState, useContext, useEffect } from 'react';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Button,
  FormHelperText,
  Autocomplete,
  Chip,
  Box,
  Container,
  Card,
  Divider
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import FormHelpText from 'rsuite/esm/FormHelpText';
import { useAppDispatch, useAppSelector } from 'store';
import { getCampaignNameExist } from 'store/thunk/userThunk';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import CampaignContext from 'context';
import { CAMPAIGN } from 'constant/campaignConstant';
import { CustomSwitch } from 'ui-component/customSwitch';

const CampaignSettingStep = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    campaign,
    campaignData,
    setCampaignData,
    setCampaign,
    autoCampaign,
    setAutoCampaign,
    manualCampaign,
    setManualCampaign,
    handleForwordButtonClick,
    dateType,
    campaignNameManually
    // setCampaignNameManually
  } = useContext(CampaignContext);
  const { allUserDetails, existingCampaignId, existingAutoCampaignId, existingManualCampaignId } = useAppSelector(
    (state) => state?.userDetailsSlice
  );
  const [budgetType, setBudgetType] = useState('both');
  const [dailyBudget, setDailyBudget] = useState(null);
  const [advertiserType, setAdvertiserType] = useState('');
  const [nameValid, setNameValid] = useState(false);

  const [autoNameValid, setAutoNameValid] = useState(false);
  const [manualNameValid, setManualNameValid] = useState(false);

  const selectedProfiles = localStorage.getItem('selectedProfiles');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (campaign?.advertiser) {
      const advertiseObjArray = allUserDetails.filter((obj) => obj.id == campaign?.advertiser);
      if (advertiseObjArray?.length) {
        const advertiseObj = advertiseObjArray[0];
        setAdvertiserType(advertiseObj?.advertiser_type || '');
      }
    }
  }, []);

  const handledChangeData = (e) => {
    setCampaign({ ...campaign, [e.target.name]: e.target.value });
  };

  const handledToggleChange = (e) => {
    setCampaign({ ...campaign, [e.target.name]: e.target.checked });
  };
  const handledAutoToggleChange = (e) => {
    setAutoCampaign({ ...autoCampaign, [e.target.name]: e.target.checked });
  };
  const handledManualToggleChange = (e) => {
    setManualCampaign({ ...manualCampaign, [e.target.name]: e.target.checked });
  };

  const handledPercentageChange = (e) => {
    const inputValue = Number(e.target.value);
    if (inputValue >= 0 && inputValue <= 900) {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        [e.target.name]: e.target.value
      }));
    }
  };

  const handledAutoPercentageChange = (e) => {
    const inputValue = Number(e.target.value);
    if (inputValue >= 0 && inputValue <= 900) {
      setAutoCampaign((prevCampaign) => ({
        ...prevCampaign,
        [e.target.name]: e.target.value
      }));
    }
  };

  const handledManualPercentageChange = (e) => {
    const inputValue = Number(e.target.value);
    if (inputValue >= 0 && inputValue <= 900) {
      setManualCampaign((prevCampaign) => ({
        ...prevCampaign,
        [e.target.name]: e.target.value
      }));
    }
  };

  const dailyBudgetValid = advertiserType == '3p' ? 'For 3P, Minimum daily budget is 10' : 'For 1P, Minimum daily budget is 50';
  const totalBudgetValid = advertiserType == '3p' ? 'For 3P, Minimum total budget is 50' : 'For 1P, Minimum total budget is 100';

  const getNameValidation = (isValid) => {
    if (isValid) {
      return Yup.string()
        .required('Name is required')
        .test('name', 'Campaign name already exists', () => false);
    }
    return Yup.string().required('Name is required');
  };

  const getAutoNameValidation = (isValid) => {
    if (isValid) {
      return Yup.string()
        .required('Auto campaign name is required')
        .test('auto_name', 'Auto campaign name already exists', () => false);
    }
    return Yup.string()
      .required('Auto campaign name is required')
      .test('auto_name', 'Auto and Manual campaign names must be different', (value, context) => {
        const manualName = context.parent.manual_name;
        return value !== manualName;
      });
  };

  const getManualNameValidation = (isValid) => {
    if (isValid) {
      return Yup.string()
        .required('Manual campaign name is required')
        .test('manual_name', 'Manual campaign name already exists', () => false);
    }
    return Yup.string()
      .required('Manual campaign name is required')
      .test('manual_name', 'Auto and Manual campaign names must be different', (value, context) => {
        const autoName = context.parent.auto_name;
        return value !== autoName;
      });
  };

  const getTotalBudgetValidation = () => {
    if (budgetType === 'both') {
      return Yup.string()
        .required('Total Budget is required')
        .test('total_budget', 'Total budget is greter than daily', function (value) {
          return Number(value) >= Number(dailyBudget);
        })
        .test('total_budget', totalBudgetValid, function (value) {
          return advertiserType == '3p' ? Number(value) >= 50 : Number(value) >= 100;
        });
    }

    if (budgetType === 'total') {
      return Yup.string()
        .required('Total Budget is required')
        .test('total_budget', totalBudgetValid, function (value) {
          return advertiserType == '3p' ? Number(value) >= 50 : Number(value) >= 100;
        });
    }

    return '';
  };

  const formSchema = Yup.object().shape({
    name: campaign?.targeting_type !== 'both' ? getNameValidation(nameValid) : '',
    both_name: campaign?.targeting_type === 'both' && !campaignNameManually ? getNameValidation(nameValid) : '',
    auto_name: campaign?.targeting_type === 'both' && campaignNameManually ? getAutoNameValidation(autoNameValid) : '',
    manual_name: campaign?.targeting_type === 'both' && campaignNameManually ? getManualNameValidation(manualNameValid) : '',
    advertiser: Yup.string().required('Advertiser is required'),
    // budget_type: Yup.string().required('Budget Type is required'),
    daily_budget:
      budgetType == 'daily' || budgetType == 'both'
        ? Yup.string()
            .required('Daily Budget is required')
            .test('daily_budget', dailyBudgetValid, function (value) {
              return advertiserType == '3p' ? Number(value) >= 10 : Number(value) >= 50;
            })
        : '',
    total_budget: getTotalBudgetValidation(),
    start_date: dateType == 'select' ? Yup.string().required('Date is required').nullable() : null,
    end_date: Yup.string().when('start_date', (start_date, schema) => {
      if (start_date) {
        return schema.test('is-after-start', 'End date must be greater than start date', function (end_date) {
          if (!end_date) {
            return true;
          } else {
            return dayjs(end_date).isAfter(dayjs(start_date));
          }
        });
      }
      return schema;
    })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    clearErrors
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: campaign.name,
      auto_name: autoCampaign?.name,
      manual_name: manualCampaign?.name,
      advertiser: campaign.advertiser,
      // budget_type: campaign.budget_type,
      daily_budget: campaign.daily_budget,
      total_budget: campaign.total_budget,
      start_date: campaign.start_date,
      end_date: campaign.end_date
    }
  });

  useEffect(() => {
    const budget_type_value = campaign?.budget_type;
    if (budget_type_value) {
      setBudgetType(budget_type_value);
    }
  }, [campaign?.budget_type]);

  const startDateValue = watch('start_date');

  useEffect(() => {
    const dailyBudgetValue = watch('daily_budget');
    if (dailyBudgetValue) {
      setDailyBudget(dailyBudgetValue);
    }
  }, [watch('daily_budget')]);

  useEffect(() => {
    const advertiserTypeValue = watch('advertiser');
    if (advertiserTypeValue) {
      const advertiseObjArray = allUserDetails.filter((obj) => obj.id === Number(advertiserTypeValue));
      if (advertiseObjArray?.length) {
        const advertiseObj = advertiseObjArray[0];
        setAdvertiserType(advertiseObj?.advertiser_type || '');
      }
    }
  }, [watch('advertiser')]);

  useEffect(() => {
    const name = watch('name');
    const autoName = watch('auto_name');
    const manualName = watch('manual_name');

    const validateName = async (campaign_name, setValidFn) => {
      if (campaign_name) {
        const res = await dispatch(getCampaignNameExist({ campaign_name }));
        setValidFn(res?.payload?.data?.error || false);
      }
    };
    if (!existingCampaignId) validateName(name, setNameValid);
    if (!existingAutoCampaignId) validateName(autoName, setAutoNameValid);
    if (!existingManualCampaignId) validateName(manualName, setManualNameValid);
  }, [watch('name'), watch('auto_name'), watch('manual_name')]);

  //Auto select First Profile while page load and we have allUserDetails (create again preserve selected profile as it is)
  useEffect(() => {
    if (allUserDetails?.length && !campaign?.advertiser) {
      if (selectedProfiles) {
        const selectedProfileFirstId = selectedProfiles?.split(',')?.pop();
        setValue('advertiser', selectedProfileFirstId, { shouldValidate: true });
      } else {
        setValue('advertiser', null);
      }
    }
  }, [allUserDetails, selectedProfiles]);

  const onButtonClick = (event) => {
    try {
      let _request = {};
      let _auto_request = {};
      let _manual_request = {};

      _request = {
        ...campaign,
        ...event,
        advertiser: Number(event.advertiser),
        daily_budget: Number(event.daily_budget),
        total_budget: Number(event.total_budget)
      };

      if (campaign?.targeting_type === 'both') {
        _auto_request = {
          ...autoCampaign,
          ...event,
          name: `${campaignNameManually ? event?.auto_name : event?.both_name + '-A'}`,
          advertiser: Number(event.advertiser),
          daily_budget: Number(event.daily_budget),
          total_budget: Number(event.total_budget),
          budget_type: campaign?.budget_type
        };

        _manual_request = {
          ...manualCampaign,
          ...event,
          name: `${campaignNameManually ? event?.manual_name : event?.both_name + '-M'}`,
          advertiser: Number(event.advertiser),
          daily_budget: Number(event.daily_budget),
          total_budget: Number(event.total_budget),
          budget_type: campaign?.budget_type
        };
      }

      if (event.start_date && dateType == 'select') {
        if (!event.end_date) {
          delete _request['end_date'];
          delete _auto_request['end_date'];
          delete _manual_request['end_date'];
        }
      } else {
        delete _request['start_date'];
        delete _request['end_date'];
        delete _auto_request['start_date'];
        delete _auto_request['end_date'];
        delete _manual_request['start_date'];
        delete _manual_request['end_date'];
      }
      if (campaign?.budget_type == 'total') {
        delete _request['daily_budget'];
        delete _auto_request['daily_budget'];
        delete _manual_request['daily_budget'];
      } else if (campaign?.budget_type == 'daily') {
        delete _request['total_budget'];
        delete _auto_request['total_budget'];
        delete _manual_request['total_budget'];
      }
      if (campaign?.targeting_type != CAMPAIGN.TARGETING_TYPE.AUTO && campaign?.targeting_type != CAMPAIGN.TARGETING_TYPE.BOTH) {
        delete _request['placement_bid_home_page'];
        delete _request['placement_bid_stock_up'];
        delete _auto_request['placement_bid_home_page'];
        delete _auto_request['placement_bid_stock_up'];
        delete _manual_request['placement_bid_home_page'];
        delete _manual_request['placement_bid_stock_up'];
      }
      delete _auto_request['manual_name'];
      delete _auto_request['auto_name'];
      delete _manual_request['auto_name'];
      delete _manual_request['manual_name'];
      setCampaign(_request);

      if (campaign?.targeting_type === 'both') {
        setAutoCampaign(_auto_request);
        setManualCampaign(_manual_request);
      }
      setCampaignData({ ...campaignData, campaign: _request, autoCampaign: _auto_request, manualCampaign: _manual_request });
      handleForwordButtonClick();
    } catch (error) {
      console.log('error ++++++', error);
    }
  };

  // const handledNameManually = (e) => {
  //   setCampaignNameManually(e.target.checked);
  // };

  return (
    <form noValidate onSubmit={handleSubmit(onButtonClick)}>
      <Container maxWidth="lg">
        <Card className="campaign-step-card">
          <Grid container spacing={2}>
            <Grid item sm={12} className="campaign-card-title">
              <Typography variant="h4">Campaign</Typography>
            </Grid>
            <Grid item sm={12}>
              <Controller
                name="advertiser"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    className="campaignAutocomplete"
                    {...register('advertiser')}
                    {...field}
                    value={allUserDetails.find((obj) => obj.id == field.value) || null}
                    onChange={(e, value) => (value ? field.onChange(value?.id) : field.onChange(''))}
                    options={allUserDetails || []}
                    getOptionLabel={(option) => {
                      return option?.name || '';
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Profile"
                        error={Boolean(errors.advertiser)}
                        helperText={errors.advertiser?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h5" fontWeight="700" sx={{ mb: '12px' }}>
                Campaign Type
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="targeting_type"
                onChange={handledChangeData}
                value={campaign?.targeting_type}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '16px',
                  flexDirection: { xs: 'column', sm: 'row' }
                }}
                className="radio-group"
              >
                <FormControlLabel
                  sx={{ flex: 1 }}
                  className="radio-item"
                  value={CAMPAIGN.TARGETING_TYPE.AUTO}
                  control={<Radio />}
                  label={CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO}
                />
                <FormControlLabel
                  sx={{ flex: 1 }}
                  className="radio-item"
                  value={CAMPAIGN.TARGETING_TYPE.MANUAL}
                  control={<Radio />}
                  label={CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL}
                />
                <FormControlLabel
                  sx={{ flex: 1 }}
                  className="radio-item"
                  value={CAMPAIGN.TARGETING_TYPE.BOTH}
                  control={<Radio />}
                  label={CAMPAIGN.CAMPAIGN_TYPE.SP_BOTH}
                />
              </RadioGroup>
            </Grid>
            {/*<Grid item sm={12}>
              {campaign?.targeting_type === 'both' && (
                <FormControlLabel
                  label="Set campaign name manually"
                  name="campaign_name_manually"
                  className="CustomSwitchToggle"
                  checked={campaignNameManually}
                  onChange={handledNameManually}
                  control={<CustomSwitch label="Set campaign name manually" />}
                />
              )}
            </Grid>*/}
            <Grid item sm={12}>
              {(() => {
                if (campaign?.targeting_type === 'both') {
                  if (campaignNameManually) {
                    return (
                      <Grid container spacing={2}>
                        <Grid item sm={12}>
                          <Controller
                            name={'auto_name'}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...register('auto_name')}
                                {...field}
                                fullWidth
                                name={'auto_name'}
                                variant="outlined"
                                label="Auto campaign name"
                                type="text"
                                disabled={existingAutoCampaignId}
                                error={errors?.auto_name}
                                helperText={errors?.auto_name?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={12}>
                          <Controller
                            name={'manual_name'}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...register('manual_name')}
                                {...field}
                                fullWidth
                                name={'manual_name'}
                                variant="outlined"
                                label="Manual campaign name"
                                type="text"
                                disabled={existingManualCampaignId}
                                error={errors?.manual_name}
                                helperText={errors?.manual_name?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item sm={12}>
                        <Controller
                          name={'both_name'}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...register('both_name')}
                              {...field}
                              fullWidth
                              name={'both_name'}
                              variant="outlined"
                              label="Campaign Name"
                              type="text"
                              disabled={existingCampaignId}
                              error={errors?.both_name}
                              helperText={errors?.both_name?.message}
                            />
                          )}
                        />
                      </Grid>
                    );
                  }
                } else {
                  return (
                    <Controller
                      name={'name'}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...register('name')}
                          {...field}
                          fullWidth
                          name={'name'}
                          variant="outlined"
                          label="Campaign Name"
                          type="text"
                          disabled={existingCampaignId}
                          error={errors?.name}
                          helperText={errors?.name?.message}
                        />
                      )}
                    />
                  );
                }
              })()}
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h5" fontWeight="700" sx={{ mb: '12px' }}>
                Date Range
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Controller
                    name={'start_date'}
                    sx={{
                      background: 'red',
                      width: '100%'
                    }}
                    control={control}
                    render={() => (
                      <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register('start_date')}
                          error={errors?.start_date}
                          minDate={dayjs(new Date())}
                          defaultValue={campaign.start_date ? dayjs(campaign.start_date) : dayjs(new Date())}
                          closeOnSelect={true}
                          onChange={(value) => {
                            const dayjsDate = dayjs(value.$d);
                            const formattedDate = dayjsDate.format('YYYY-MM-DD');
                            setValue('start_date', formattedDate);
                          }}
                          sx={{ width: '100%' }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors?.start_date && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      {errors?.start_date?.message}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={6}>
                  <Controller
                    name={'end_date'}
                    control={control}
                    render={() => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register('end_date')}
                          error={errors?.end_date}
                          defaultValue={campaign.end_date ? dayjs(campaign.end_date) : null}
                          minDate={startDateValue ? dayjs(startDateValue).add(1, 'day') : dayjs(new Date()).add(1, 'day')}
                          onChange={(value) => {
                            const dayjsDate = dayjs(value.$d);
                            const formattedDate = dayjsDate.format('YYYY-MM-DD');
                            setValue('end_date', formattedDate);
                          }}
                          sx={{ width: '100%' }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors?.end_date && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      {errors?.end_date?.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h5" fontWeight="700" sx={{ mb: '12px' }}>
                Bidding Strategy
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="bidding_strategy"
                onChange={handledChangeData}
                value={campaign?.bidding_strategy}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '16px',
                  flexDirection: { xs: 'column', sm: 'row' }
                }}
                className="radio-group"
              >
                <FormControlLabel
                  sx={{ flex: 1 }}
                  className="radio-item"
                  value={CAMPAIGN.BIDDING_STRATEGY.FIXED}
                  control={<Radio />}
                  label={'Fixed'}
                />
                <FormControlLabel
                  sx={{ flex: 1 }}
                  className="radio-item"
                  value={CAMPAIGN.BIDDING_STRATEGY.DYNAMIC}
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center">
                      {'Dynamic'}
                      <Chip label="Beta" size="small" color="primary" sx={{ marginLeft: 1 }} />
                    </Box>
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Card>
        <Card className="campaign-step-card">
          <Grid container spacing={2}>
            <Grid item sm={12} className="campaign-card-title">
              <Typography variant="h4">Budget</Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h5" fontWeight="700" sx={{ mb: '12px' }}>
                Budget
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="budget_type"
                onChange={handledChangeData}
                value={campaign?.budget_type}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '16px',
                  flexDirection: { xs: 'column', sm: 'row' }
                }}
                className="radio-group"
              >
                <FormControlLabel
                  sx={{ flex: 1 }}
                  className="radio-item"
                  value={'daily'}
                  control={<Radio />}
                  label={'Daily Budget'}
                />
                <FormControlLabel sx={{ flex: 1 }} className="radio-item" value={'both'} control={<Radio />} label={'Both'} />
              </RadioGroup>
              <Box sx={{ padding: 0.5 }}>
                <FormHelpText>Once a campaign becomes live, budget type cannot be changed</FormHelpText>
              </Box>
            </Grid>
            <>
              {(budgetType == 'daily' || budgetType == 'both') && (
                <Grid item sm={12}>
                  <Controller
                    name={'daily_budget'}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        {...register('daily_budget')}
                        fullWidth
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="daily_budget"
                        label="Daily Budget"
                        variant="outlined"
                        error={errors?.daily_budget}
                        onChange={(e) => {
                          setValue('daily_budget', e.target.value, { shouldValidate: true });
                          clearErrors('daily_budget');
                        }}
                        helperText={errors?.daily_budget?.message}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    )}
                  />
                  <Box sx={{ padding: 0.5 }}>
                    <FormHelpText>For 3P, Minimum Daily Budget is 10; for 1P, Minimum Daily Budget is 50.</FormHelpText>
                  </Box>
                </Grid>
              )}
              {(budgetType == 'total' || budgetType == 'both') && (
                <Grid item sm={12}>
                  <Controller
                    name={'total_budget'}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        {...field}
                        {...register('total_budget')}
                        type="number"
                        label="Total Budget"
                        variant="outlined"
                        onWheel={(e) => e.target.blur()}
                        name="total_budget"
                        error={errors?.total_budget}
                        onChange={(e) => {
                          setValue('total_budget', e.target.value, { shouldValidate: true });
                          clearErrors('total_budget');
                        }}
                        helperText={errors?.total_budget?.message}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    )}
                  />
                  <Box sx={{ padding: 0.5 }}>
                    <FormHelpText>For 3P, Minimum Daily Budget is 50; for 1P, Minimum Daily Budget is 100.</FormHelpText>
                  </Box>
                </Grid>
              )}
            </>
          </Grid>
        </Card>
        <Card className="campaign-step-card">
          <Grid container>
            <Grid item sm={12} className="campaign-card-title">
              <Typography variant="h4">Bid Multiplier</Typography>
            </Grid>
            {campaign?.targeting_type !== 'both' ? (
              <Grid item sx={12}>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                  <Grid item sm={12} container spacing={2}>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Search Ingrid"
                        name="is_search_ingrid"
                        className="CustomSwitchToggle"
                        checked={campaign?.is_search_ingrid}
                        onChange={handledToggleChange}
                        disabled
                        control={<CustomSwitch label="Search Ingrid" />}
                      />
                    </Grid>
                    {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL && (
                      <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                        <FormControlLabel
                          label="Search Carousel"
                          checked={campaign?.is_search_carousel}
                          name="is_search_carousel"
                          className="CustomSwitchToggle"
                          onChange={handledToggleChange}
                          control={<CustomSwitch label="Search Carousel" />}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Item Buybox"
                        name="is_item_buybox"
                        className="CustomSwitchToggle"
                        checked={campaign?.is_item_buybox}
                        onChange={handledToggleChange}
                        disabled={campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO}
                        control={<CustomSwitch label="Item Buybox" />}
                      />
                    </Grid>

                    {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL && (
                      <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                        <FormControlLabel
                          label="Item Carousel"
                          name="is_item_carousel"
                          className="CustomSwitchToggle"
                          checked={campaign?.is_item_carousel}
                          onChange={handledToggleChange}
                          control={<CustomSwitch label="Item Carousel" />}
                        />
                      </Grid>
                    )}

                    {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
                      <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                        <FormControlLabel
                          label="Home Page"
                          name="is_home_page"
                          className="CustomSwitchToggle"
                          checked={campaign?.is_home_page}
                          onChange={handledToggleChange}
                          disabled={campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO}
                          control={<CustomSwitch label="Home Page" />}
                        />
                      </Grid>
                    )}

                    {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
                      <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                        <FormControlLabel
                          label="Stock Up"
                          name="is_stock_up"
                          className="CustomSwitchToggle"
                          checked={campaign?.is_stock_up}
                          onChange={handledToggleChange}
                          disabled={campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO}
                          control={<CustomSwitch label="Stock Up" />}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Others"
                        checked={campaign?.is_others}
                        onChange={handledToggleChange}
                        name="is_others"
                        className="CustomSwitchToggle"
                        disabled
                        control={<CustomSwitch label="Others" />}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="h5" fontWeight="700" sx={{ mb: 0 }}>
                      {' '}
                      Placement Bid Multiplier
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Search Ingrid"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0-900"
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="placement_bid_search_ingrid"
                      value={campaign?.placement_bid_search_ingrid}
                      onChange={handledPercentageChange}
                    />
                  </Grid>
                  {campaign?.is_item_buybox && (
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Item Buybox"
                        variant="outlined"
                        onWheel={(e) => e.target.blur()}
                        inputProps={{
                          min: 0,
                          max: 900
                        }}
                        placeholder="0-900"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        name="placement_bid_item_buybox"
                        value={campaign?.placement_bid_item_buybox}
                        onChange={handledPercentageChange}
                      />
                    </Grid>
                  )}
                  {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Home Page"
                        variant="outlined"
                        onWheel={(e) => e.target.blur()}
                        inputProps={{
                          min: 0,
                          max: 900
                        }}
                        placeholder="0-900"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        name="placement_bid_home_page"
                        value={campaign?.placement_bid_home_page}
                        onChange={handledPercentageChange}
                      />
                    </Grid>
                  )}

                  {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Stock Up"
                        variant="outlined"
                        placeholder="0-900"
                        onWheel={(e) => e.target.blur()}
                        inputProps={{
                          min: 0,
                          max: 900
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        name="placement_bid_stock_up"
                        value={campaign?.placement_bid_stock_up}
                        onChange={handledPercentageChange}
                      />
                    </Grid>
                  )}

                  <Grid item sm={12}>
                    <Typography variant="h5" fontWeight="700" sx={{ mb: 0 }}>
                      {' '}
                      Platform Bid Multiplier
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Desktop"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      placeholder="0-900"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="platform_bid_desktop"
                      value={campaign?.platform_bid_desktop}
                      onChange={handledPercentageChange}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="App"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0-900"
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="platform_bid_app"
                      value={campaign?.platform_bid_app}
                      onChange={handledPercentageChange}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Mobile"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0-900"
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="platform_bid_mobile"
                      value={campaign?.platform_bid_mobile}
                      onChange={handledPercentageChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item sx={12}>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                  <Grid item sm={12}>
                    <Typography variant="h4">SP-Auto</Typography>
                  </Grid>
                  <Grid item sm={12} container spacing={2}>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Search Ingrid"
                        name="is_search_ingrid"
                        className="CustomSwitchToggle"
                        checked={autoCampaign?.is_search_ingrid}
                        onChange={handledAutoToggleChange}
                        disabled
                        control={<CustomSwitch label="Search Ingrid" />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Item Buybox"
                        name="is_item_buybox"
                        className="CustomSwitchToggle"
                        checked={autoCampaign?.is_item_buybox}
                        onChange={handledAutoToggleChange}
                        disabled
                        control={<CustomSwitch label="Item Buybox" />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Home Page"
                        name="is_home_page"
                        className="CustomSwitchToggle"
                        checked={autoCampaign?.is_home_page}
                        onChange={handledAutoToggleChange}
                        disabled
                        control={<CustomSwitch label="Home Page" />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Stock Up"
                        name="is_stock_up"
                        className="CustomSwitchToggle"
                        checked={autoCampaign?.is_stock_up}
                        onChange={handledAutoToggleChange}
                        disabled
                        control={<CustomSwitch label="Stock Up" />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Others"
                        checked={autoCampaign?.is_others}
                        onChange={handledAutoToggleChange}
                        name="is_others"
                        className="CustomSwitchToggle"
                        disabled
                        control={<CustomSwitch label="Others" />}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="h5" fontWeight="700" sx={{ mb: 0 }}>
                      Placement Bid Multiplier
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Search Ingrid"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0-900"
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="placement_bid_search_ingrid"
                      value={autoCampaign?.placement_bid_search_ingrid}
                      onChange={handledAutoPercentageChange}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Item Buybox"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      placeholder="0-900"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="placement_bid_item_buybox"
                      value={autoCampaign?.placement_bid_item_buybox}
                      onChange={handledAutoPercentageChange}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Home Page"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      placeholder="0-900"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="placement_bid_home_page"
                      value={autoCampaign?.placement_bid_home_page}
                      onChange={handledAutoPercentageChange}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Stock Up"
                      variant="outlined"
                      placeholder="0-900"
                      onWheel={(e) => e.target.blur()}
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="placement_bid_stock_up"
                      value={autoCampaign?.placement_bid_stock_up}
                      onChange={handledAutoPercentageChange}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="h5" fontWeight="700" sx={{ mb: 0 }}>
                      {' '}
                      Platform Bid Multiplier
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Desktop"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      placeholder="0-900"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="platform_bid_desktop"
                      value={autoCampaign?.platform_bid_desktop}
                      onChange={handledAutoPercentageChange}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="App"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0-900"
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="platform_bid_app"
                      value={autoCampaign?.platform_bid_app}
                      onChange={handledAutoPercentageChange}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Mobile"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0-900"
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="platform_bid_mobile"
                      value={autoCampaign?.platform_bid_mobile}
                      onChange={handledAutoPercentageChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                  <Grid item sm={12}>
                    <Divider sx={{ my: 2, mb: 3 }} />
                    <Typography variant="h4">SP-Manual</Typography>
                  </Grid>
                  <Grid item sm={12} container spacing={2}>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Search Ingrid"
                        name="is_search_ingrid"
                        className="CustomSwitchToggle"
                        checked={manualCampaign?.is_search_ingrid}
                        onChange={handledManualToggleChange}
                        disabled
                        control={<CustomSwitch label="Search Ingrid" />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Search Carousel"
                        checked={manualCampaign?.is_search_carousel}
                        name="is_search_carousel"
                        className="CustomSwitchToggle"
                        onChange={handledManualToggleChange}
                        control={<CustomSwitch label="Search Carousel" />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Item Buybox"
                        name="is_item_buybox"
                        className="CustomSwitchToggle"
                        checked={manualCampaign?.is_item_buybox}
                        onChange={handledManualToggleChange}
                        control={<CustomSwitch label="Item Buybox" />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Item Carousel"
                        name="is_item_carousel"
                        className="CustomSwitchToggle"
                        checked={manualCampaign?.is_item_carousel}
                        onChange={handledManualToggleChange}
                        control={<CustomSwitch label="Item Carousel" />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                      <FormControlLabel
                        label="Others"
                        checked={manualCampaign?.is_others}
                        onChange={handledManualToggleChange}
                        name="is_others"
                        className="CustomSwitchToggle"
                        disabled
                        control={<CustomSwitch label="Others" />}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="h5" fontWeight="700" sx={{ mb: 0 }}>
                      {' '}
                      Placement Bid Multiplier
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Search Ingrid"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0-900"
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="placement_bid_search_ingrid"
                      value={manualCampaign?.placement_bid_search_ingrid}
                      onChange={handledManualPercentageChange}
                    />
                  </Grid>
                  {manualCampaign?.is_item_buybox && (
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Item Buybox"
                        variant="outlined"
                        onWheel={(e) => e.target.blur()}
                        inputProps={{
                          min: 0,
                          max: 900
                        }}
                        placeholder="0-900"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        name="placement_bid_item_buybox"
                        value={manualCampaign?.placement_bid_item_buybox}
                        onChange={handledManualPercentageChange}
                      />
                    </Grid>
                  )}
                  <Grid item sm={12}>
                    <Typography variant="h5" fontWeight="700" sx={{ mb: 0 }}>
                      {' '}
                      Platform Bid Multiplier
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Desktop"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      placeholder="0-900"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="platform_bid_desktop"
                      value={manualCampaign?.platform_bid_desktop}
                      onChange={handledManualPercentageChange}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="App"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0-900"
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="platform_bid_app"
                      value={manualCampaign?.platform_bid_app}
                      onChange={handledManualPercentageChange}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Mobile"
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0-900"
                      inputProps={{
                        min: 0,
                        max: 900
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      name="platform_bid_mobile"
                      value={manualCampaign?.platform_bid_mobile}
                      onChange={handledManualPercentageChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
          <>
            <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={() => navigate('/campaign')}>
              CANCEL
            </Button>
            <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit">
              NEXT
            </Button>
          </>
        </Box>
      </Container>
    </form>
  );
};

export default CampaignSettingStep;
