import { Box, Button, ButtonGroup, Divider, FormControl, Popover, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from 'store';
import {
  setDashboardDateRange,
  setIsDashboardCompareDates,
  setCommonDateRange,
  setIsCompareDates
} from 'store/slices/userDetailsSlice';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { addDays, startOfDay } from 'date-fns';
import { Stack } from 'rsuite';
import { DateHilightedCustom, sideBarOptions, selectLastYearDates, selectLastMonthDates } from './RangeOptions';
import moment from 'moment';
import DateCompareOptions from './DateCompareOptions';
import { themeColors } from 'store/constant';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const CustomCompareDatePicker = (props) => {
  const { setStartDate, setEndDate, setCompareStartDate, setCompareEndDate, isCompareDate, setIsCompareDate, islast90days } =
    props;
  const dispatch = useAppDispatch();
  const params = useParams();
  let checkLocalStoreageDate = params?.token
    ? JSON.parse(localStorage.getItem('DashboardSelectedDates'))
    : JSON.parse(localStorage.getItem('selectedDates'));

  const initialState = {
    selection: {
      startDate: checkLocalStoreageDate
        ? new Date(checkLocalStoreageDate?.selection?.startDate)
        : moment().subtract(29, 'days').startOf('day').toDate(),
      endDate: checkLocalStoreageDate ? new Date(checkLocalStoreageDate?.selection?.endDate) : moment().endOf('day').toDate(),
      key: 'selection'
    },
    compare: {
      startDate: checkLocalStoreageDate
        ? new Date(checkLocalStoreageDate?.compare?.startDate)
        : moment().subtract(59, 'days').startOf('day').toDate(),
      endDate: checkLocalStoreageDate
        ? new Date(checkLocalStoreageDate?.compare?.endDate)
        : moment().subtract(30, 'days').startOf('day').toDate(),
      key: 'compare'
    }
  };

  const [label, setLabel] = useState('Last 7 days');
  const [dates, setDates] = useState(initialState);
  const [isCompare, setIsCompare] = useState(false);
  const [rangetype, setRangeType] = useState('previous_period');
  const [tempDates, setTempDates] = useState({ ...initialState });
  const [tempDatesCompare, setTempDatesCompare] = useState({ ...initialState?.compare });
  const [anchorEl, setAnchorEl] = useState(null);
  const [mainDates, setMainDates] = useState({
    selection: {
      startDate: moment().subtract(29, 'days').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'selection'
    }
  });

  const sideBar = sideBarOptions();
  const [comparDate, setComparDate] = useState({
    startDate: startOfDay(addDays(new Date(), -1)),
    endDate: startOfDay(addDays(new Date(), -1)),
    key: 'compare'
  });

  const handleOpenPopup = (event) => {
    if (params?.token) {
      setIsCompare(localStorage.getItem('DashboardIsCompareDates') === 'true');
      setAnchorEl(event.currentTarget);
      if (localStorage.getItem('DashboardIsCompareDates') === 'true') {
        setRangeType(localStorage.getItem('DashboardCompareRangeType'));
      }
    } else {
      setIsCompare(localStorage.getItem('isCompareDates') === 'true');
      setAnchorEl(event.currentTarget);
      if (localStorage.getItem('isCompareDates') === 'true') {
        setRangeType(localStorage.getItem('compareRangeType'));
      }
    }
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleApply = () => {
    setTempDates({ ...dates });
    setTempDatesCompare({ ...comparDate });
    handleClosePopup(false);

    if (params?.token) {
      localStorage.setItem('DashboardSelectedDates', JSON.stringify(dates));
      localStorage.setItem('DashboardCompareRangeType', rangetype ? rangetype : 'previous_period');
      localStorage.setItem('DashboardIsCompareDates', isCompare);

      dispatch(
        setDashboardDateRange({
          startDate: dates?.selection?.startDate ? moment(dates?.selection?.startDate).format('YYYY-MM-DD') : '',
          endDate: dates?.selection?.endDate ? moment(dates?.selection?.endDate).format('YYYY-MM-DD') : '',
          compareStartDate: dates?.compare?.startDate && isCompare ? moment(dates?.compare?.startDate).format('YYYY-MM-DD') : '',
          compareEndDate: dates?.compare?.endDate && isCompare ? moment(dates?.compare?.endDate).format('YYYY-MM-DD') : ''
        })
      );

      dispatch(setIsDashboardCompareDates(isCompare));
    } else {
      localStorage.setItem('selectedDates', JSON.stringify(dates));
      localStorage.setItem('compareRangeType', rangetype ? rangetype : 'previous_period');
      localStorage.setItem('isCompareDates', isCompare);

      dispatch(
        setCommonDateRange({
          startDate: dates?.selection?.startDate ? moment(dates?.selection?.startDate).format('YYYY-MM-DD') : '',
          endDate: dates?.selection?.endDate ? moment(dates?.selection?.endDate).format('YYYY-MM-DD') : '',
          compareStartDate: dates?.compare?.startDate && isCompare ? moment(dates?.compare?.startDate).format('YYYY-MM-DD') : '',
          compareEndDate: dates?.compare?.endDate && isCompare ? moment(dates?.compare?.endDate).format('YYYY-MM-DD') : ''
        })
      );

      dispatch(setIsCompareDates(isCompare));
    }

    setIsCompareDate(isCompare);
  };

  const handleCancel = () => {
    handleClosePopup(false);
  };

  useEffect(() => {
    if (dates) {
      setStartDate(dates?.selection?.startDate);
      setEndDate(dates?.selection?.endDate);

      if (dates?.compare && isCompareDate) {
        setCompareStartDate(dates?.compare?.startDate);
        setCompareEndDate(dates?.compare?.endDate);
      }
    }
  }, [dates]);

  useEffect(() => {
    if (params?.token) {
      const Defaultselections = {
        selection: {
          startDate: moment().subtract(30, 'days').startOf('day').toDate(),
          endDate: moment().subtract(1, 'days').startOf('day').toDate(),
          key: 'selection'
        },
        compare: {
          startDate: moment().subtract(60, 'days').startOf('day').toDate(),
          endDate: moment().subtract(31, 'days').startOf('day').toDate(),
          key: 'compare'
        }
      };

      setIsCompare(true);
      setIsCompareDate(true);
      localStorage.setItem('DashboardIsCompareDates', true);

      localStorage.setItem('DashboardSelectedDates', JSON.stringify(Defaultselections));
      setDates(Defaultselections);
      setComparDate(Defaultselections?.compare);

      const compareRangeType = localStorage.getItem('DashboardCompareRangeType');
      localStorage.setItem('DashboardCompareRangeType', compareRangeType ? compareRangeType : 'previous_period');

      if (compareRangeType) setRangeType(compareRangeType);

      dispatch(setIsCompareDates(true));

      dispatch(
        setDashboardDateRange({
          startDate: Defaultselections?.selection?.startDate
            ? moment(Defaultselections?.selection?.startDate).format('YYYY-MM-DD')
            : '',
          endDate: Defaultselections?.selection?.endDate
            ? moment(Defaultselections?.selection?.endDate).format('YYYY-MM-DD')
            : '',
          compareStartDate: Defaultselections?.compare?.startDate
            ? moment(Defaultselections?.compare?.startDate).format('YYYY-MM-DD')
            : '',
          compareEndDate: Defaultselections?.compare?.endDate
            ? moment(Defaultselections?.compare?.endDate).format('YYYY-MM-DD')
            : ''
        })
      );
    } else {
      localStorage.setItem('selectedDates', JSON.stringify(initialState));
      setDates(initialState);
      setComparDate(initialState?.compare);
      const localIsCompare = localStorage.getItem('isCompareDates');
      const compareRangeType = localStorage.getItem('compareRangeType');
      localStorage.setItem('compareRangeType', compareRangeType ? compareRangeType : 'previous_period');
      setIsCompare(localIsCompare === 'true');
      setIsCompareDate(localIsCompare === 'true');
      dispatch(setIsCompareDates(localIsCompare === 'true'));

      if (localIsCompare === 'true') {
        if (compareRangeType) setRangeType(compareRangeType);

        dispatch(
          setCommonDateRange({
            startDate: initialState?.selection?.startDate ? moment(initialState?.selection?.startDate).format('YYYY-MM-DD') : '',
            endDate: initialState?.selection?.endDate ? moment(initialState?.selection?.endDate).format('YYYY-MM-DD') : '',
            compareStartDate:
              initialState?.compare?.startDate && localIsCompare === 'true'
                ? moment(initialState?.compare?.startDate).format('YYYY-MM-DD')
                : '',
            compareEndDate:
              initialState?.compare?.endDate && localIsCompare === 'true'
                ? moment(initialState?.compare?.endDate).format('YYYY-MM-DD')
                : ''
          })
        );
      } else {
        dispatch(
          setCommonDateRange({
            startDate: initialState?.selection?.startDate ? moment(initialState?.selection?.startDate).format('YYYY-MM-DD') : '',
            endDate: initialState?.selection?.endDate ? moment(initialState?.selection?.endDate).format('YYYY-MM-DD') : ''
          })
        );
      }
    }
  }, []);

  const staticRanges = [...createStaticRanges(sideBar)];

  const setSelectedDates = (ranges, demoChildren) => {
    if (ranges?.compare) {
      if (ranges?.compare?.key === 'selection') {
        if (demoChildren && demoChildren?.label !== 'Custom Range') {
          setComparDate(demoChildren?.compareRange());
          setDates({ selection: ranges?.compare, compare: demoChildren?.compareRange() });
          setMainDates(null);
        } else {
          setRangeType('custom_range');
        }
      } else {
        setComparDate(ranges?.compare);
      }
    } else {
      if (demoChildren && demoChildren?.label !== 'Custom Range') {
        if (demoChildren?.label === 'Last Month') {
          const compareData = selectLastMonthDates(dates);
          setComparDate(compareData);
          setDates({ ...dates, compare: compareData });
        } else {
          const data = DateHilightedCustom(dates);
          setComparDate(data);
          setDates({ ...dates, compare: data });
        }
        setMainDates(null);
      } else {
        if (rangetype !== 'custom_range') {
          const data = DateHilightedCustom(ranges);
          if (ranges) {
            setComparDate(data);
            setDates({ ...ranges, compare: data });
          }
          setMainDates(null);
        }
      }
    }
  };

  const handleSelect = async (ranges) => {
    if (rangetype !== 'last_year') {
      setDates({ ...dates, ...ranges });
      setMainDates(ranges);
    } else {
      if (!ranges?.compare) {
        setDates({ ...dates, ...ranges });
        setMainDates(ranges);
      } else {
        await setRangeType('custom_range');
        setDates({ ...dates, selection: ranges.compare });
      }
    }
  };

  const setLastYearDates = (ranges) => {
    const compareDates = selectLastYearDates(ranges);
    if (compareDates) {
      setComparDate(selectLastYearDates(ranges));
      setDates({ selection: ranges?.selection, compare: selectLastYearDates(ranges) });
      setMainDates(null);
    }
  };

  const getPreviousDateComparerange = () => {
    let elements = document.getElementsByClassName('rdrStaticRangeSelected');
    if (elements.length === 0) {
      setLabel(null);
    } else {
      let requiredElement = elements[0];
      let demoChildren = sideBar?.find((menu) => menu.label === requiredElement?.innerText);
      setLabel(demoChildren?.label);
      if (mainDates || demoChildren?.label !== label) {
        setSelectedDates(mainDates, demoChildren);
      }
    }
  };

  useEffect(() => {
    if (isCompare && dates?.selection) {
      const selectedDate = {
        selection: dates.selection
      };

      if (rangetype === 'last_year') {
        setLastYearDates(selectedDate);
      } else if (rangetype === 'previous_period') {
        if (label === 'Last Month') {
          const compareData = selectLastMonthDates(selectedDate);
          setComparDate(compareData);
          setDates({ ...dates, compare: compareData });
        } else {
          const data = DateHilightedCustom(selectedDate);
          setComparDate(data);
          setDates({ ...dates, compare: data });
        }
      }
    }
  }, [rangetype]);

  useEffect(() => {
    if (rangetype === 'last_year') {
      setLastYearDates(mainDates);
    } else if (rangetype === 'previous_period') {
      getPreviousDateComparerange();
    }
  }, [dates, label, mainDates]);

  const Custom = {
    label: 'Custom Range',
    range: () => ({
      startDate: dates?.selection.startDate,
      endDate: dates?.selection.endDate
    }),
    isSelected() {
      return label ? false : true;
    }
  };

  return (
    <Stack direction="column" spacing={8} alignItems="flex-start" style={{ position: 'relative' }}>
      <FormControl component="fieldset">
        <ButtonGroup
          className="date-picker-wrapper"
          variant="outlined"
          aria-describedby={id}
          onClick={handleOpenPopup}
          aria-label="outlined button group"
        >
          <Button>
            <img src="/calendar-icon.svg" alt="" />
            <Typography ml={1} variant="h5">
              {tempDates?.selection &&
                ` ${moment(tempDates?.selection?.startDate)?.format('MM/DD/YY')} - ${moment(
                  tempDates?.selection?.endDate
                )?.format('MM/DD/YY')}`}
            </Typography>
          </Button>
          {isCompareDate && (
            <Button>
              <img src="/compare.svg" alt="" />
              <Typography ml={1} variant="h5">
                {tempDatesCompare &&
                  `${moment(tempDatesCompare?.startDate)?.format('MM/DD/YY')} - ${moment(tempDatesCompare?.endDate)?.format(
                    'MM/DD/YY'
                  )}`}
              </Typography>
            </Button>
          )}
        </ButtonGroup>
      </FormControl>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box className="compare-date-picker-wrapper">
          <DateCompareOptions
            setMainDates={setMainDates}
            setComparDate={setComparDate}
            setDates={setDates}
            dates={dates?.selection}
            comparDate={comparDate}
            isCompareDate={isCompareDate}
            isCompare={isCompare}
            setIsCompare={setIsCompare}
            rangetype={rangetype}
            setRangeType={setRangeType}
          />
          <Divider sx={{ margin: '10px 0px' }} />
          {isCompare ? (
            <Box>
              <DateRangePicker
                className="dateCalenderx"
                ranges={[dates?.selection, dates?.compare]}
                showDateDisplay={false}
                onChange={handleSelect}
                months={2}
                // minDate={addDays(new Date(), -900)}
                minDate={islast90days ? addDays(new Date(), -90) : undefined}
                maxDate={new Date()}
                direction="horizontal"
                //scroll={{ enabled: true }}
                staticRanges={[...staticRanges, Custom]}
                inputRanges={[]}
                rangeColors={[themeColors?.primaryMain, themeColors?.secondaryMain]}
              />
              <Box sx={{ width: '95%', margin: 2, justifyContent: 'flex-end', display: 'flex' }}>
                <Button variant="outlined" onClick={handleCancel} sx={{ mr: 2 }}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleApply}>
                  Apply
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <DateRangePicker
                className="dateCalenderx"
                showDateDisplay={false}
                ranges={[dates?.selection]}
                onChange={handleSelect}
                months={2}
                // minDate={addDays(new Date(), -900)}
                minDate={islast90days ? addDays(new Date(), -90) : undefined}
                maxDate={new Date()}
                direction="horizontal"
                staticRanges={[...staticRanges, Custom]}
                inputRanges={[]}
                rangeColors={[themeColors?.primaryMain]}
              />
              <Box sx={{ width: '95%', margin: 2, justifyContent: 'flex-end', display: 'flex' }}>
                <Button variant="outlined" onClick={handleCancel} sx={{ mr: 2 }}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleApply}>
                  Apply
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Popover>
    </Stack>
  );
};

export default CustomCompareDatePicker;
