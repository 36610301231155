import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ListingQualityIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ fill: 'none' }}>
      <SvgIcon {...props} viewBox="0 0 18 18" style={{ fill: 'none' }}>
        <path
          d="M16.5 9.48092V6.46873C16.5 3.93749 15 2.25 12.75 2.25H5.25C3 2.25 1.5 3.93749 1.5 6.46873V11.5313C1.5 14.0625 3 15.75 5.25 15.75H10.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
        />
        <path d="M12.75 13.875L13.875 15L16.875 12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.75 6H12.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.5 6H4.5375" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.75 9H11.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.5 9H4.5375" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.75 12H10.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.5 12H4.5375" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      </SvgIcon>
    </SvgIcon>
  );
}

export default ListingQualityIcon;
