import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, IconButton } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import { useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from 'store';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { setCommonAdvertiser, setSelectedStoresList } from 'store/slices/userDetailsSlice';
import { getAlldvertiserList, getAdvertiserStoreList } from 'store/thunk/userThunk';
import CustomCompareDatePicker from 'ui-component/compare-date-range-picker/CustomCompareDatePicker';
import CustomAutocomplete from '../../../ui-component/multi-select/CustomAutocomplete';
import colors from 'assets/scss/_themes-vars.module.scss';
import { getRouteName } from 'helper/commonHelper';
import expandLess from 'assets/images/dashna/expandLess.svg';
import expandMore from 'assets/images/dashna/expandMore.svg';

//Hide Profile and Date Comparision filters for following routes
const hideHeaderFiltersForRoutes = ['/store'];

const Header = ({ handleLeftDrawerToggle }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { allUserDetails, advertiserStoreList } = useAppSelector((state) => state?.userDetailsSlice);
  const defaultStartDate = moment().subtract(30, 'days').startOf('day').toDate();
  const defaultEndDate = moment().endOf('day').toDate();
  const userData = useSelector((state) => state.authorization.userData);
  const location = useLocation();
  const route = location?.pathname;
  const theme = useTheme();
  const superAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [compareStartDate, setCompareStartDate] = useState(defaultStartDate);
  const [compareEndDate, setCompareEndDate] = useState(defaultEndDate);
  const [isCompareDate, setIsCompareDate] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState({ profile: null, store: null });

  //For Select All Checkbox
  const [isSelectAll, setIsSelectAll] = useState({
    profile: [],
    store: []
  });
  const [value, setValue] = useState([]);
  const [storeValue, setStoreValue] = useState([]);

  /* ================================ Autocomplete Filters Start ================================*/

  const handleStoreOnChange = (newValue, filterKey) => {
    let baseArray = [];

    if (filterKey === 'store') {
      baseArray = [...advertiserStoreList];
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      setStoreValue(newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns);
    } else {
      setStoreValue(newValue);
    }
  };

  //Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'profile') {
      paramKey = 'id';
    }

    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];

    if (filterKey === 'profile') {
      baseArray = [...allUserDetails];
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      if (value.length === allIdsCam.length) {
        localStorage.removeItem('selectedProfiles');
      } else {
        localStorage.setItem('selectedProfiles', allIdsCam.join(','));
      }

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setValue(newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns);
    } else {
      localStorage.setItem('selectedProfiles', idString);
      setValue(newValue);
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];
    let checkValue = [];

    if (filterKey === 'profile') {
      baseArray = [...allUserDetails];
      checkValue = [...value];
    }
    if (filterKey === 'store') {
      baseArray = [...advertiserStoreList];
      checkValue = [...storeValue];
    }

    const status =
      checkValue?.length && isSelectAll?.[filterKey]?.length
        ? !!(checkValue?.length == baseArray?.length) || !!(checkValue?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  /* ================================ Autocomplete Filters End ================================*/

  useEffect(() => {
    dispatch(getAlldvertiserList({}));
  }, []);

  useEffect(() => {
    if (route && route == '/listing-quality') {
      dispatch(getAdvertiserStoreList({}));
    }
  }, [route]);

  useEffect(() => {
    if (advertiserStoreList?.length > 0) {
      setStoreValue([advertiserStoreList[0]]);
    }
  }, [advertiserStoreList]);

  useEffect(() => {
    if (allUserDetails.length > 0 && params?.selectedProfile) {
      const allSelectedProfiles = params?.selectedProfile.split(',');
      const valueData = [...value];

      allSelectedProfiles.length > 0 &&
        allSelectedProfiles.map((val) => {
          valueData.push(allUserDetails.find((data) => data.id == val));
        });

      setValue(valueData);
    } else {
      if (allUserDetails.length > 0 && localStorage.getItem('selectedProfiles')) {
        const allSelectedProfiles = localStorage.getItem('selectedProfiles').split(',');
        const valueData = [];

        allSelectedProfiles.length > 0 &&
          allSelectedProfiles.map((val) => {
            if (valueData.findIndex((advert) => advert?.id === val) === -1) {
              valueData.push(allUserDetails.find((data) => data?.id == val));
            }
          });

        setValue(valueData);
      } else if (allUserDetails.length > 0 && !localStorage.getItem('selectedProfiles')) {
        //When User Login Initially then set the Profile Advertiser as he last selected
        const valueData = [];
        const lastAccessedAdvertiser = userData?.last_accessed_advertiser || [];
        lastAccessedAdvertiser.length &&
          lastAccessedAdvertiser.map((val) => {
            if (valueData.findIndex((advert) => advert?.id === val) === -1) {
              valueData.push(allUserDetails.find((data) => data?.id == val));
            }
          });
        setValue(valueData);
        localStorage.setItem('selectedProfiles', lastAccessedAdvertiser.join(','));
      }
    }
  }, [allUserDetails]);

  useEffect(() => {
    if (!isDropdownOpen) {
      dispatch(setCommonAdvertiser(value.map((item) => item?.id)));

      const profiles = localStorage.getItem('selectedProfiles');

      if (value.length === 0 && profiles?.length === 0) {
        localStorage.removeItem('selectedProfiles');
      }
    }
  }, [value, isDropdownOpen]);

  useEffect(() => {
    if (!isDropdownOpen) {
      dispatch(setSelectedStoresList(storeValue.map((item) => item?.id)));
    }
  }, [storeValue, isDropdownOpen]);

  const leftDrawerOpened = useSelector((state) => state.customization.opened);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: leftDrawerOpened ? 228 : 50,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
      </Box>
      <IconButton onClick={handleLeftDrawerToggle}>
        {leftDrawerOpened ? <img src={expandLess} alt="icon" /> : <img src={expandMore} alt="icon" />}
      </IconButton>
      {/* header search */}
      <Box sx={{ flexGrow: 0.02 }} />
      <Box mx={2}>
        <Typography className="custom-dashna-page-title" color={colors?.darkLevel1}>
          {getRouteName(route)}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 0.1 }} />
      {!superAdmin && !hideHeaderFiltersForRoutes.includes(route) ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {route != '/listing-quality' && (
            <Box className="custom-compare-date-picker" sx={{ width: '100%', mr: 2 }}>
              <CustomCompareDatePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
                islast90days={route == '/share-of-voice'}
                setCompareStartDate={setCompareStartDate}
                setCompareEndDate={setCompareEndDate}
                compareStartDate={compareStartDate}
                compareEndDate={compareEndDate}
                isCompareDate={isCompareDate}
                setIsCompareDate={setIsCompareDate}
                style={{ width: '250px' }}
              />
            </Box>
          )}
          {route != '/share-of-voice' && route != '/listing-quality' && (
            <Box sx={{ maxWidth: '250px', minWidth: '250px', width: '100%', mr: 2 }}>
              <CustomAutocomplete
                multiple
                id="profile_filter"
                name="Profile"
                color="secondary"
                label="Profile"
                options={allUserDetails || []}
                value={value || []}
                onChange={(e, value) => {
                  handleAutoCompleteOnChange(value, 'profile');
                }}
                optionLabelkey="name"
                optionIdkey="id"
                inputValue={isDropdownOpen ? searchText?.profile : ''}
                onInputChange={(event, value, reason) => {
                  if (reason !== 'reset') {
                    setSearchText({ ...searchText, profile: value });
                  }
                }}
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => setIsDropdownOpen(false)}
                islast90days={route == '/share-of-voice'}
                selectAllCheckboxCondiion={handleAutoCompleteSelectAll('profile')}
                placeholderCondition={value?.length > 0}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                limitTags={2}
                extraClass={'profileDrp'}
              />
            </Box>
          )}
          {route == '/listing-quality' && (
            <Box sx={{ maxWidth: '250px', minWidth: '250px', width: '100%', mr: 2 }}>
              <CustomAutocomplete
                multiple
                id="store_filter"
                name="store"
                color="secondary"
                label="Store"
                options={advertiserStoreList || []}
                value={storeValue || []}
                onChange={(e, value) => {
                  handleStoreOnChange(value, 'store');
                }}
                optionLabelkey="name"
                optionIdkey="id"
                inputValue={isDropdownOpen ? searchText?.store : ''}
                onInputChange={(event, value, reason) => {
                  if (reason !== 'reset') {
                    setSearchText({ ...searchText, store: value });
                  }
                }}
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => setIsDropdownOpen(false)}
                // islast90days={route == '/share-of-voice'}
                selectAllCheckboxCondiion={handleAutoCompleteSelectAll('store')}
                placeholderCondition={value?.length > 0}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                limitTags={2}
                extraClass={'profileDrp'}
              />
            </Box>
          )}
        </Box>
      ) : (
        <></>
      )}
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
