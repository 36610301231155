// import MainCard from 'ui-component/cards/MainCard';
import { Box, Card, CardContent, Typography, Link } from '@mui/material';
import colors from 'assets/scss/_themes-vars.module.scss';
import Report from 'assets/images/dashna/Report.svg';
import ArrowGo from 'assets/images/dashna/ArrowGo.svg';
import { useNavigate } from 'react-router-dom';

function DefaultReport() {
  const grey300 = colors?.grey300;
  const navigate = useNavigate();
  const reports = {
    default: [
      {
        title: 'Item-Keyword Report',
        desc: 'Item Keyword Reports help sellers track keyword performance and conversions to optimize product visibility and increase sales.',
        link: '/item-keyword-report'
      }
    ],
    advanced: [
      // {
      //   title: 'Advanced Insights Report',
      //   desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
      //   link: '/item-keyword-report'
      // }
    ]
  };

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3}>
      {reports?.default?.length > 0 && (
        <Box className="BI-dashboard-card" sx={{ padding: '25px 20px', height: 'auto !important' }}>
          <Box sx={{ padding: '8px 20px', paddingBottom: '10px' }}>
            <Typography variant="h2" className="Overview-title" sx={{ marginLeft: '0px !important' }}>
              Default Reports
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
              {reports?.default?.map((report, index) => (
                <Card
                  key={index}
                  sx={{
                    borderRadius: '10px',
                    border: `1px solid ${grey300}`,
                    display: 'flex',
                    alignItems: 'center',
                    width: '560px',
                    p: 2,
                    justifyContent: 'space-between'
                  }}
                >
                  <Box sx={{ padding: '20px 24px', borderRadius: '10px', background: 'rgba(95, 96, 255, 0.05)' }}>
                    <Link sx={{ cursor: 'pointer' }} onClick={() => handleClick(report.link)}>
                      <img src={Report} alt="Report Logo" />
                    </Link>
                  </Box>
                  <CardContent
                    sx={{
                      padding: '0px 24px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '14px',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography fontWeight="bold" className={'clr2 fs16 ffInter fw700'}>
                      {report.title}
                    </Typography>
                    <Typography color={'#353D49'} className={'fs14 ffInter fw400'}>
                      {report.desc}
                    </Typography>
                  </CardContent>
                  <Box sx={{ padding: '16px', borderRadius: '10px', background: '#FFF' }}>
                    <Link sx={{ cursor: 'pointer' }} onClick={() => handleClick(report.link)}>
                      <img src={ArrowGo} alt="Click Logo" />
                    </Link>
                  </Box>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      {reports?.advanced?.length > 0 && (
        <Box className="BI-dashboard-card" sx={{ padding: '25px 20px', height: 'auto !important' }}>
          <Box sx={{ padding: '8px 20px', paddingBottom: '10px' }}>
            <Typography variant="h2" className="Overview-title" sx={{ marginLeft: '0px !important' }}>
              Advanced Reports
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
              {reports?.advanced?.map((report, index) => (
                <Card
                  key={index}
                  sx={{
                    borderRadius: '10px',
                    border: `1px solid ${grey300}`,
                    display: 'flex',
                    alignItems: 'center',
                    width: '560px',
                    p: 2,
                    justifyContent: 'space-between'
                  }}
                >
                  <Box sx={{ padding: '20px 24px', borderRadius: '10px', background: 'rgba(95, 96, 255, 0.05)' }}>
                    <Link sx={{ cursor: 'pointer' }} onClick={() => handleClick(report.link)}>
                      <img src={Report} alt="Report Logo" />
                    </Link>
                  </Box>
                  <CardContent
                    sx={{
                      padding: '0px 24px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '14px',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography fontWeight="bold" className={'clr2 fs16 ffInter fw700'}>
                      {report.title}
                    </Typography>
                    <Typography color={'#353D49'} className={'fs14 ffInter fw400'}>
                      {report.desc}
                    </Typography>
                  </CardContent>
                  <Box sx={{ padding: '16px', borderRadius: '10px', background: '#FFF' }}>
                    <Link sx={{ cursor: 'pointer' }} onClick={() => handleClick(report.link)}>
                      <img src={ArrowGo} alt="Click Logo" />
                    </Link>
                  </Box>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default DefaultReport;
